import AddOns from "../../../../../Pages/Desktop/HI/ProposalPage/GoDigit/AddOns/AddOns";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

const AddOnsContainer = () => {
  const isMobile = useIsMobile();

  return <>{isMobile ? <></> : <AddOns />}</>;
};

export default AddOnsContainer;
