import PropertyDetails from "../../../../../Pages/Desktop/HI/ProposalPage/ICICI/PropertyDetails/PropertyDetails";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

const PropertyDetailsContainer = () => {
  const isMobile = useIsMobile();

  return <>{isMobile ? <></> : <PropertyDetails />}</>;
};

export default PropertyDetailsContainer;
