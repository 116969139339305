export const HI_PATH = {
  Proposal: "/proposal",
  ProposalIcici: "/proposalIcici",
  HOUSE_HOLD_BREAKUP: "/house-hold-breakup",
  VERIFY_CKYC: "/ckyc",
  IN_BUILT_COVERAGES: "/coverages",
  ADD_ONS: "/add-ons",
  OWNER_DETAILS: "/owner-details",
  PROPERTY_DETAILS: "/property-details",
  ADDRESS_DETAILS: "/address-details",
  REVIEW_DETAILS: "/review-details",
  MANUAL_KYC: "/manual-kyc",
  PageNotFound: "/pageNotFound",
};

export const HI_PATH_POLICY_CONFIRM = "/policy-confirm";
