import { Box, FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Footer from "../../../../../../Components/Desktop/ProposalComponents/Footer/Footer";
import { HI_PATH } from "../../../../../../Path/HI/HIPath";
import { AddressDetails_DTO } from "../../../../../../Services/DTO/ProposalDTO";
import { PROPOSAL_SERVICES } from "../../../../../../Services/ProposalService";
import { QUOTES_SERVICES } from "../../../../../../Services/QuotesService";
import { useAppSelector } from "../../../../../../State/Hooks";
import { kycResponseSlice } from "../../../../../../State/Slices/KycResponse/KycResponseSlice";
import { proposalSlice } from "../../../../../../State/Slices/Proposal/ProposalSlice";
import { TKycResponse } from "../../../../../../State/Types/TKycResponse";
import { TAddressDetails } from "../../../../../../State/Types/TProposal";
import RKTextField from "../../../../../../SupportingFiles/FieldTypes/RKTextField/RKTextField";
import { setAddressDetails as allAddressDetails } from "../../../../../../State/Slices/Proposal/ProposalSlice";
import {
  isEmpty,
  validatePincode,
} from "../../../../../../SupportingFiles/HelpingFunction";
import { pageSlice } from "../../../../../../State/Slices/PageSlice/PageSlice";

const AddressDetails: React.FC = () => {
  const navigate = useNavigate();
  const { PAGE_STATUS } = useAppSelector((state) => state.pageSlice);
  const address_details: TAddressDetails = useAppSelector(
    (state) => state.proposalInformation.addressDetails
  );
  const [addressDetails, setAddressDetails] =
    useState<TAddressDetails>(address_details);
  const { proposalInformation, quote } = useAppSelector((state) => state);

  const kycRespState = useAppSelector((state) => state.kycResponseSlice);
  const [kycResp, setKycResp] = useState<TKycResponse>(kycRespState);
  const [load, setLoad] = useState<boolean>(false);
  const dispatch = useDispatch();

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[1] !== "kycResp") {
      setAddressDetails((prev: any) => ({
        ...prev,
        [attrName[1]]: {
          ...prev[attrName[1]],
          [attrName[0]]: {
            ...prev[attrName[1]][attrName[0]],
            value: value,
            warning:
              attrName[0] === "pincode"
                ? !validatePincode(value)
                : isEmpty(value),
          },
        },
      }));

      // debugger;
      // dispatch(
      //   allAddressDetails({
      //     ...addressDetails,

      //     propertyAddress: {
      //       address: {
      //         value: addressDetails.propertyAddress.address.value,
      //         warning: addressDetails.propertyAddress.address.warning,
      //       },
      //       address2: {
      //         value: addressDetails.propertyAddress.address2.value,
      //         warning: addressDetails.propertyAddress.address2.warning,
      //       },
      //       city: {
      //         value: addressDetails.propertyAddress.city.value,
      //         warning: addressDetails.propertyAddress.city.warning,
      //       },
      //       state: {
      //         value: addressDetails.propertyAddress.state.value,
      //         warning: addressDetails.propertyAddress.state.warning,
      //       },
      //       pincode: {
      //         value: addressDetails.propertyAddress.pincode.value,
      //         warning: addressDetails.propertyAddress.pincode.warning,
      //       },
      //     },
      //     // isSame:a
      //   })
      // );
      // debugger;
    } else {
      setKycResp((prev) => ({ ...prev, [attrName[0]]: value }));
    }
  };

  const get_city_state_by_pincode = (
    pincode: string,
    type: "PROPERTY" | "PERMANENT" | "KYC"
  ) => {
    const onSuccess = (data: any) => {
      const response = data.data.quoteLists;
      const city = response.city_name;
      const state = response.state_name;

      if (type === "PROPERTY") {
        setAddressDetails((prev) => ({
          ...prev,
          propertyAddress: {
            ...prev.propertyAddress,
            city: { ...prev.propertyAddress.city, value: city },
            state: { ...prev.propertyAddress.state, value: state },
          },
        }));
      } else if (type === "PERMANENT") {
        setAddressDetails((prev) => ({
          ...prev,
          communicationAddress: {
            ...prev.communicationAddress,
            city: { ...prev.communicationAddress.city, value: city },
            state: { ...prev.communicationAddress.state, value: state },
          },
        }));
      } else if (type === "KYC") {
        setKycResp((prev) => ({
          ...prev,
          city: city,
          state: state,
        }));
      }
    };
    const onError = () => {};

    PROPOSAL_SERVICES.get_city_state_pincode(onSuccess, onError, pincode);
  };

  useEffect(() => {
    if (validatePincode(addressDetails.propertyAddress.pincode.value)) {
      get_city_state_by_pincode(
        addressDetails.propertyAddress.pincode.value,
        "PROPERTY"
      );
    }
  }, [addressDetails.propertyAddress.pincode.value]);

  useEffect(() => {
    if (validatePincode(addressDetails.communicationAddress.pincode.value)) {
      get_city_state_by_pincode(
        addressDetails.communicationAddress.pincode.value,
        "PERMANENT"
      );
    }
  }, [addressDetails.communicationAddress.pincode.value]);

  useEffect(() => {
    if (validatePincode(kycResp.pincode)) {
      get_city_state_by_pincode(kycResp.pincode, "KYC");
    }
  }, [kycResp.pincode]);

  const updateQuotePremium = (loadingToast: any) => {
    const onSuccess = (res: any) => {
      toast.dismiss(loadingToast);

      // debugger;
      // bisma
      // dispatch(
      //   allAddressDetails({
      //     ...addressDetails,
      //     propertyAddress: {
      //       address: {
      //         value: addressDetails.propertyAddress.address.value,
      //         warning: addressDetails.propertyAddress.address.warning,
      //       },
      //       address2: {
      //         value: addressDetails.propertyAddress.address2.value,
      //         warning: addressDetails.propertyAddress.address2.warning,
      //       },
      //       city: {
      //         value: addressDetails.propertyAddress.city.value,
      //         warning: addressDetails.propertyAddress.city.warning,
      //       },
      //       state: {
      //         value: addressDetails.propertyAddress.state.value,
      //         warning: addressDetails.propertyAddress.state.warning,
      //       },
      //       pincode: {
      //         setFromKycResp:
      //           addressDetails.propertyAddress.pincode.setFromKycResp,
      //         value: addressDetails.propertyAddress.pincode.value,
      //         warning: addressDetails.propertyAddress.pincode.warning,
      //       },
      //     },
      //     // isSame:a
      //   })
      // );
      dispatch(
        proposalSlice.actions.setPolicyInfo({
          ...proposalInformation.policy_information,
          premiumDetails: res.data.quoteLists,
        })
      );
      setLoad(false);
      if (PAGE_STATUS) {
        dispatch(pageSlice.actions.UPDATE_PAGE_STATUS(false));
        navigate(-1);
      } else {
        navigate(HI_PATH.REVIEW_DETAILS);
      }
    };

    const onError = (err: any) => {
      console.log(err);
    };

    QUOTES_SERVICES.update_icici_quote_premium(
      onSuccess,
      onError,
      quote.quote_id
    );
  };

  const saveProposalInfo = (data: any) => {
    setLoad(true);
    const loadingToast = toast.loading("Updating Proposal...");

    const onSuccess = (res: any) => {
      // toast.dismiss(loadingToast);
      // setLoad(false);
      updateQuotePremium(loadingToast);
    };

    const onError = (err: any) => {
      setLoad(false);
      toast.dismiss(loadingToast);
    };

    PROPOSAL_SERVICES.save_proposal_info(
      onSuccess,
      onError,
      data,
      quote.quote_id
    );
  };

  const check_error = () => {
    let address_details: TAddressDetails = addressDetails;
    let kyc_response: TKycResponse = kycResp;
    let hasError: boolean = false;

    address_details = {
      ...address_details,
      propertyAddress: {
        ...address_details.propertyAddress,
        pincode: {
          ...address_details.propertyAddress.pincode,
          warning: !validatePincode(
            address_details.propertyAddress.pincode.value
          ),
        },
        address: {
          ...address_details.propertyAddress.address,
          warning: isEmpty(address_details.propertyAddress.address.value),
        },
        address2: {
          ...address_details.propertyAddress.address2,
          warning: isEmpty(address_details.propertyAddress.address2.value),
        },
      },
    };

    if (
      address_details.propertyAddress.pincode.warning ||
      address_details.propertyAddress.address.warning ||
      // address_details.propertyAddress.address2.warning ||
      isEmpty(kyc_response.pincode)
    ) {
      hasError = true;
    }

    if (!address_details.isSame) {
      address_details = {
        ...address_details,
        communicationAddress: {
          ...address_details.communicationAddress,
          pincode: {
            ...address_details.communicationAddress.pincode,
            warning: !validatePincode(
              address_details.communicationAddress.pincode.value
            ),
          },
          address: {
            ...address_details.communicationAddress.address,
            warning: isEmpty(
              address_details.communicationAddress.address.value
            ),
          },
          address2: {
            ...address_details.communicationAddress.address2,
            warning: isEmpty(
              address_details.communicationAddress.address2.value
            ),
          },
        },
      };

      if (
        address_details.communicationAddress.pincode.warning ||
        address_details.communicationAddress.address.warning ||
        // address_details.communicationAddress.address2.warning ||
        hasError
      ) {
        hasError = true;
      }
    }
    setAddressDetails(address_details);
    setKycResp(kyc_response);
    dispatch(
      proposalSlice.actions.setAddressDetails({
        ...address_details,
      })
    );
    dispatch(
      kycResponseSlice.actions.setKycResonse({
        ...kyc_response,
      })
    );
    return hasError;
  };

  const validate_form = () => {
    const warning = check_error();
    const obj = addressDetails;
    // const obj = proposalInformation.addressDetails;

    let dto: AddressDetails_DTO = {
      isSame: obj.isSame,
      isCommunication: obj.isSame,
      property_details: {
        address: obj.propertyAddress.address.value,
        city: obj.propertyAddress.city.value,
        pincode: obj.propertyAddress.pincode.value,
        state: obj.propertyAddress.state.value,
      },

      communication_address: {
        address:
          kycRespState.address_line_1 + " " + kycRespState.address_line_2,
        city: kycRespState.city,
        // address2: kycRespState.address_line_2,
        pincode: kycRespState.pincode,
        // state: kycRespState.state,
        permanent_address: {
          address:
            kycRespState.address_line_1 + " " + kycRespState.address_line_2,
          city: kycRespState.city,
          pincode: kycRespState.pincode,
        },
      },
    };

    if (!obj.isSame) {
      dto = {
        ...dto,
        communication_address: {
          ...dto.communication_address,
          address: obj.communicationAddress.address.value,
          address2: obj.communicationAddress.address2.value,
          city: obj.communicationAddress.city.value,
          pincode: obj.communicationAddress.pincode.value,
          state: obj.communicationAddress.state.value,
        },
      };
    }

    if (!warning) {
      saveProposalInfo(dto);
    } else {
      toast.error("Please fill all fields");
    }
  };
  const mailHandler = () => {
    setAddressDetails((prev) => ({
      ...prev,
      isSame: !prev.isSame,
      communicationAddress: {
        address: {
          value: "",
          warning: false,
        },
        address2: {
          value: "",
          warning: true,
        },
        city: {
          value: "",
          warning: true,
        },
        state: {
          value: "",
          warning: true,
        },
        pincode: {
          value: "",
          warning: false,
        },
      },
    }));
  };
  return (
    <Box className="proposalAddress">
      <h5 className="sectionTitle">Address Details</h5>
      <Grid container spacing={3} alignItems="center">
        <Grid xs={12}>
          <h6>Property Address (The property you want to get insured)</h6>
        </Grid>
        <Grid xs={4}>
          <RKTextField
            class_name="inputField"
            title={"Pincode"}
            value={addressDetails.propertyAddress.pincode.value}
            attrName={["pincode", "propertyAddress"]}
            max_length={6}
            value_update={updateMasterState}
            warn_status={addressDetails.propertyAddress.pincode.warning}
            disabled={addressDetails.propertyAddress.pincode.setFromKycResp}
            error_message={
              isEmpty(addressDetails.propertyAddress.pincode.value)
                ? "Enter Pincode"
                : "Enter Valid Pincode"
            }
          />
        </Grid>
        <Grid xs={4}>
          <p>
            {addressDetails.propertyAddress.city.value +
              ", " +
              addressDetails.propertyAddress.state.value}
          </p>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <RKTextField
            class_name="inputField"
            title={"Address Line 1"}
            value={addressDetails.propertyAddress.address.value}
            attrName={["address", "propertyAddress"]}
            value_update={updateMasterState}
            warn_status={addressDetails.propertyAddress.address.warning}
            disabled={addressDetails.propertyAddress.address.setFromKycResp}
            error_message="Enter Address Line 1"
          />
        </Grid>
        <Grid xs={12} md={4}>
          <RKTextField
            class_name="inputField"
            title={"Address Line 2"}
            value={addressDetails.propertyAddress.address2.value}
            attrName={["address2", "propertyAddress"]}
            value_update={updateMasterState}
            warn_status={addressDetails.propertyAddress.address2.warning}
            disabled={addressDetails.propertyAddress.address2.setFromKycResp}
            error_message=""
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <h6>Permanent Address</h6>
        </Grid>

        <Grid xs={4}>
          <RKTextField
            class_name="inputField"
            title={"Pincode"}
            value={kycResp.pincode}
            attrName={["pincode", "kycResp"]}
            max_length={6}
            value_update={updateMasterState}
            warn_status={false}
            disabled={!isEmpty(kycRespState.first_name) ? true : false}
          />
        </Grid>
        <Grid xs={4}>
          <p>
            {kycResp.city}, {kycResp.state}
          </p>
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems="center">
        <Grid xs={12} md={4}>
          <RKTextField
            class_name="inputField"
            title={"Address Line 1"}
            value={kycResp.address_line_1}
            disabled={!isEmpty(kycRespState.first_name) ? true : false}
            attrName={["address_line_1", "kycResp"]}
            value_update={updateMasterState}
            warn_status={false}
          />
        </Grid>
        <Grid xs={12} md={4}>
          <RKTextField
            class_name="inputField"
            title={"Address Line 2"}
            value={kycResp.address_line_2}
            disabled={!isEmpty(kycRespState.first_name) ? true : false}
            attrName={["address_line_2", "kycResp"]}
            value_update={updateMasterState}
            warn_status={false}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid xs={12}>
          <FormControlLabel
            className="proposalCheckBoxWrapper"
            control={
              <Checkbox
                checked={addressDetails.isSame}
                // onClick={() => {
                //   setAddressDetails((prev) => ({
                //     ...prev,
                //     isSame: !prev.isSame,
                //   }));
                // }}
                onChange={() => mailHandler()}
              />
            }
            label="Mailing / Correspondence address is same as permanent address."
          />
        </Grid>
      </Grid>

      {/* Mailing Address */}
      {!addressDetails.isSame ? (
        <Box>
          <Grid container spacing={3}>
            <Grid xs={12}>
              <h6>Communication Address</h6>
            </Grid>
            <Grid xs={4}>
              <RKTextField
                class_name="inputField"
                title={"Pincode"}
                max_length={6}
                value={addressDetails.communicationAddress.pincode.value}
                attrName={["pincode", "communicationAddress"]}
                value_update={updateMasterState}
                warn_status={
                  addressDetails.communicationAddress.pincode.warning
                }
                error_message={
                  isEmpty(addressDetails.communicationAddress.pincode.value)
                    ? "Enter Pincode"
                    : "Enter Valid Pincode"
                }
              />
            </Grid>
            <Grid xs={4}>
              <p>
                {addressDetails.communicationAddress.city.value},{" "}
                {addressDetails.communicationAddress.state.value}
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center">
            <Grid xs={6} md={4}>
              <RKTextField
                class_name="inputField"
                title={"Address Line 1"}
                value={addressDetails.communicationAddress.address.value}
                attrName={["address", "communicationAddress"]}
                value_update={updateMasterState}
                warn_status={
                  addressDetails.communicationAddress.address.warning
                }
                error_message="Enter Address Line 1"
              />
            </Grid>
            <Grid xs={6} md={4}>
              <RKTextField
                class_name="inputField"
                title={"Address Line 2"}
                value={addressDetails.communicationAddress.address2.value}
                attrName={["address2", "communicationAddress"]}
                value_update={updateMasterState}
                warn_status={
                  addressDetails.communicationAddress.address2.warning
                }
                error_message=""
              />
            </Grid>
          </Grid>
        </Box>
      ) : null}
      <Footer continue_function={validate_form} loader={load} />
    </Box>
  );
};

export default AddressDetails;
