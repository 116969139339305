import { Box, Checkbox, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TPropertyDetails } from "../../../../../../State/Types/TProposal";
import { useAppSelector } from "../../../../../../State/Hooks";
import { THouseholdDetails } from "../../../../../../State/Types/THouseholdDetails";
import { proposalSlice } from "../../../../../../State/Slices/Proposal/ProposalSlice";
import { PROPOSAL_SERVICES } from "../../../../../../Services/ProposalService";
import SelectDropdown from "../../../../../../SupportingFiles/FieldTypes/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../../../SupportingFiles/FieldTypes/RKTextField/RKTextField";
import Footer from "../../../../../../Components/Desktop/ProposalComponents/Footer/Footer";
import { PropertyDetails_DTO } from "../../../../../../Services/DTO/ProposalDTO";
import {
  isEmpty,
  objectHasWarning,
} from "../../../../../../SupportingFiles/HelpingFunction";
import { HI_PATH } from "../../../../../../Path/HI/HIPath";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { QUOTES_SERVICES } from "../../../../../../Services/QuotesService";
import { pageSlice } from "../../../../../../State/Slices/PageSlice/PageSlice";

const building_data = [
  { key: "Multistorey", value: "Multistorey" },
  { key: "Standalone House", value: "Standalone House" },
];
const bank_data = [
  { key: "HDFC Bank", value: "HDFC Bank" },
  { key: "IDBI Bank", value: "IDBI Bank" },
  { key: "Bank of India", value: "Bank of India" },
];
// Max. no of floors will be 100
const floor_data = [
  { key: "Ground Floor", value: "Ground Floor" },
  { key: "1st Floor", value: "1st Floor" },
  { key: "2nd Floor ", value: "2nd Floor" },
  { key: "3rd Floor ", value: "3rd Floor" },
  { key: "4th Floor ", value: "4th Floor" },
  { key: "5th Floor ", value: "5th Floor" },
  { key: "6th Floor ", value: "6th Floor" },
  { key: "7th Floor ", value: "7th Floor" },
  { key: "8th Floor ", value: "8th Floor" },
  { key: "9th Floor ", value: "9th Floor" },
  { key: "10th Floor ", value: "10th Floor" },
  { key: "Above 10th Floor ", value: "Above 10th Floor" },
];

const PropertyDetails: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { PAGE_STATUS } = useAppSelector((state) => state.pageSlice);
  const [load, setLoad] = useState<boolean>(false);
  const [constYearData, setConstYearData] = useState<
    { key: string; value: string }[]
  >([]);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    let data = [];
    for (let i = currentYear; i >= currentYear - 100; i--) {
      data.push({ key: `${i}`, value: `${i}` });
    }
    setConstYearData(data);
  }, []);
  const propertyState: TPropertyDetails = useAppSelector(
    (state) => state.proposalInformation.propertyDetails
  );

  const householdDetailsState: THouseholdDetails = useAppSelector(
    (state) => state.householdDetails
  );

  const state = useAppSelector((state) => state);
  const [propertyLoan, setPropertyLoan] = useState(false);
  const [bankListOptions, setBankListOptions] = useState([]);

  useEffect(() => {
    getBanksList();
  }, []);

  useEffect(() => {
    const newVal =
      householdDetailsState.youHave === "house"
        ? "Standalone House"
        : "Multistorey";

    // dispatch(
    //   proposalSlice.actions.setPropertyDetails({
    //     ...propertyState,
    //     typeOfBuilding: { warning: false, value: newVal },
    //   })
    // );
  }, [householdDetailsState.youHave]);

  const getBanksList = () => {
    const onSuccess = (res: any) => {
      setBankListOptions(
        res.data.quoteLists.map((item: any) => {
          return {
            key: item.name,
            value: item.name,
          };
        })
      );
    };

    const onError = (err: any) => {
      console.log(err);
    };

    PROPOSAL_SERVICES.get_bank_list(
      onSuccess,
      onError,
      state.proposalInformation.policy_information.productDetails.product_code
    );
  };

  const updateMasterState = (attrName: any, value: any) => {
    const objToUse: any = propertyState[attrName[2] as keyof TPropertyDetails];

    if (attrName[2]) {
      dispatch(
        proposalSlice.actions.setPropertyDetails({
          ...propertyState,
          [attrName[2]]: {
            ...objToUse,
            [attrName[0]]: { warning: false, [attrName[1]]: value },
          },
        })
      );

      if (!value) {
        dispatch(
          proposalSlice.actions.setPropertyDetails({
            ...propertyState,
            [attrName[2]]: {
              ...objToUse,
              [attrName[0]]: { [attrName[1]]: value, warning: true },
            },
          })
        );
      }
    } else {
      dispatch(
        proposalSlice.actions.setPropertyDetails({
          ...propertyState,
          [attrName[0]]: { warning: false, [attrName[1]]: value },
        })
      );

      if (!value) {
        dispatch(
          proposalSlice.actions.setPropertyDetails({
            ...propertyState,
            [attrName[0]]: { [attrName[1]]: value, warning: true },
          })
        );
      }
    }
  };

  const updateDigitQuotePremium = (loadingToast: any) => {
    const onSuccess = (res: any) => {
      toast.dismiss(loadingToast);
      dispatch(
        proposalSlice.actions.setPolicyInfo({
          ...state.proposalInformation.policy_information,
          premiumDetails: res.data.quoteLists,
        })
      );
      setLoad(false);

      if (PAGE_STATUS) {
        dispatch(pageSlice.actions.UPDATE_PAGE_STATUS(false));
        navigate(-1);
      } else {
        navigate(HI_PATH.ADDRESS_DETAILS);
      }
    };

    const onError = (err: any) => {
      console.log(err);
    };

    QUOTES_SERVICES.update_icici_quote_premium(
      onSuccess,
      onError,
      state.quote.quote_id
    );
  };

  const saveProposalInfo = (data: any, nextRoute?: string) => {
    // setWaitForApi(true);
    // setLoad(true);
    const loadingToast = toast.loading("Updating Proposal...");

    const onSuccess = (res: any) => {
      // toast.dismiss(loadingToast);
      // setLoad(false);
      updateDigitQuotePremium(loadingToast);
    };

    const onError = (err: any) => {
      toast.dismiss(loadingToast);
      setLoad(false);
      // setWaitForApi(false);
    };

    PROPOSAL_SERVICES.save_proposal_info(
      onSuccess,
      onError,
      data,
      state.quote.quote_id
    );
  };

  const check_error = () => {
    let data = { ...propertyState };

    data = {
      ...data,
      constructionYear: {
        ...data.constructionYear,
        warning: isEmpty(data.constructionYear.value),
      },
      typeOfBuilding: {
        ...data.typeOfBuilding,
        warning: isEmpty(data.typeOfBuilding.value),
      },
    };

    if (data.typeOfBuilding.value === "Multistorey") {
      data = {
        ...data,
        floorNo: {
          value: `${data.floorNo?.value}`,
          warning: isEmpty(data?.floorNo?.value),
        },
      };
    } else {
      data = {
        ...data,
        floorNo: {
          value: `${data.floorNo?.value}`,
          warning: false,
        },
      };
    }

    if (data.isLoan) {
      data = {
        ...data,
        LoanDetails: {
          ...data.LoanDetails,
          bankName: {
            ...data.LoanDetails.bankName,
            warning: isEmpty(data.LoanDetails.bankName.value),
          },
          branchName: {
            ...data.LoanDetails.branchName,
            warning: isEmpty(data.LoanDetails.branchName.value),
          },
          accNo: {
            ...data.LoanDetails.accNo,
            warning: isEmpty(data.LoanDetails.accNo.value),
          },
        },
      };
    } else {
      data = {
        ...data,
        LoanDetails: {
          ...data.LoanDetails,
          bankName: {
            ...data.LoanDetails.bankName,
            warning: false,
          },
          branchName: {
            ...data.LoanDetails.branchName,
            warning: false,
          },
          accNo: {
            ...data.LoanDetails.accNo,
            warning: false,
          },
        },
      };
    }
    dispatch(
      proposalSlice.actions.setPropertyDetails({
        ...data,
      })
    );
    if (
      data.constructionYear.warning ||
      data.typeOfBuilding.warning ||
      data?.floorNo?.warning ||
      data.LoanDetails.bankName.warning ||
      data.LoanDetails.branchName.warning ||
      data.LoanDetails.accNo.warning
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validate_form = () => {
    setLoad(true);
    let warning = check_error();
    if (warning) {
      toast.error("Please enter valid values", {
        id: "Enter Values Error",
      });
      setLoad(false);

      return;
    }
    const obj = state.proposalInformation.propertyDetails;

    let dto: PropertyDetails_DTO = {
      isLoan: obj.isLoan,
      constructionYear: Number(obj.constructionYear.value),
      floorNo: Number(obj.floorNo?.value),
      typeOfBuilding: obj.typeOfBuilding.value,
    };

    if (obj.isLoan) {
      warning = objectHasWarning(obj.LoanDetails);

      dto = {
        ...dto,
        loan_details: {
          accNo: Number(obj.LoanDetails.accNo.value),
          bankName: obj.LoanDetails.bankName.value,
          branchName: obj.LoanDetails.branchName.value,
        },
      };
    }

    if (!warning) {
      saveProposalInfo(dto);
    }
  };

  return (
    <Box>
      <h5 className="sectionTitle">Property Details</h5>
      <Grid container spacing={3}>
        <Grid xs={4}>
          <SelectDropdown
            class_name="inputField"
            title="Construction Year"
            value={propertyState.constructionYear.value}
            attrName={["constructionYear", "value"]}
            value_update={updateMasterState}
            dropdown_data={constYearData}
            warn_status={propertyState.constructionYear.warning}
            error_message="Select Construction Year"
          />
        </Grid>
        <Grid xs={4}>
          <SelectDropdown
            class_name="inputField"
            title="Type of Building"
            value={propertyState.typeOfBuilding.value}
            attrName={["typeOfBuilding", "value"]}
            value_update={updateMasterState}
            dropdown_data={building_data}
            warn_status={propertyState.typeOfBuilding.warning}
            error_message="Select Type of Building"
          />
        </Grid>
      </Grid>

      {propertyState.typeOfBuilding.value == "Multistorey" ? (
        <Grid container spacing={3}>
          <Grid xs={4}>
            <SelectDropdown
              class_name="inputField"
              title="Floor Number"
              value={
                propertyState.floorNo?.value ? propertyState.floorNo?.value : ""
              }
              attrName={["floorNo", "value"]}
              value_update={updateMasterState}
              dropdown_data={floor_data}
              warn_status={propertyState.floorNo?.warning}
              error_message="Select Floor No."
            />
          </Grid>
        </Grid>
      ) : null}

      <Grid container spacing={3}>
        <Grid xs={12}>
          <h6>Loan Details</h6>
        </Grid>
        <Grid xs={12}>
          <FormControlLabel
            className="proposalCheckBoxWrapper"
            control={
              <Checkbox
                checked={propertyState.isLoan}
                onClick={() => {
                  dispatch(
                    proposalSlice.actions.setPropertyDetails({
                      ...propertyState,
                      isLoan: !propertyState.isLoan,
                    })
                  );
                }}
              />
            }
            label="I have taken a loan against this property"
          />
        </Grid>
      </Grid>

      {propertyState.isLoan ? (
        <Grid container spacing={3}>
          <Grid xs={6}>
            <SelectDropdown
              class_name="inputField"
              title="Name of Bank/NBFC"
              value={propertyState.LoanDetails.bankName.value}
              attrName={["bankName", "value", "LoanDetails"]}
              value_update={updateMasterState}
              dropdown_data={bankListOptions}
              warn_status={propertyState.LoanDetails.bankName.warning}
              error_message="Select Name of Bank/NBFC"
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Branch Name"}
              value={propertyState.LoanDetails.branchName.value}
              attrName={["branchName", "value", "LoanDetails"]}
              value_update={updateMasterState}
              warn_status={propertyState.LoanDetails.branchName.warning}
              error_message="Enter Branch Name"
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Account Number"}
              value={propertyState.LoanDetails.accNo.value}
              attrName={["accNo", "value", "LoanDetails"]}
              value_update={updateMasterState}
              warn_status={propertyState.LoanDetails.accNo.warning}
              error_message="Enter Account Number"
              max_length={14}
              validation_type="NUMBER"
            />
          </Grid>
        </Grid>
      ) : null}
      <Footer continue_function={validate_form} loader={load} />
    </Box>
  );
};

export default PropertyDetails;
