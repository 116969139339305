import React, { useState } from "react";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Modal from "@mui/material/Modal";
import "../../../../../Shared/MModalDetailsPopups.scss";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import RKTextField from "../../../../../SupportingFiles/FieldTypes/RKTextField/RKTextField";
import { formatIndianCurrency } from "../../../../../SupportingFiles/HelpingFunction";

interface Props {
  openHI:any,
  setOpenHI: Function
}
const HouseholdModifyDetails: React.FC<Props> = ({
  openHI,
  setOpenHI
}) => {
  const [updateFields, setUpdateFields] = useState<{
    buildingValue: { value: string; warning: boolean };
    carpetArea: { value: string; warning: boolean };
    constructionCost: { value: string; warning: boolean };
    householdItems: { value: string; warning: boolean };
  }>({
    buildingValue: { value: "", warning: false },
    carpetArea: { value: "", warning: false },
    constructionCost: { value: "", warning: false },
    householdItems: { value: "", warning: false },
  });

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };

  const [youAre, setYouAre] = React.useState("Owner");
  const [youHave, setYouHave] = React.useState("Flat");
  const [sumInsuredValues, setSumInsuredValues] = React.useState("Building");
  const handleYouAre = (
    event: React.MouseEvent<HTMLElement>,
    newYouAre: string
  ) => {
    setYouAre(newYouAre);
  };
  const handleYouHave = (
    event: React.MouseEvent<HTMLElement>,
    newYouHave: string
  ) => {
    setYouHave(newYouHave);
  };
  const handleSumInsured = (
    event: React.MouseEvent<HTMLElement>,
    newSumInsured: string
  ) => {
    setSumInsuredValues(newSumInsured);
  };

  return (
    <Box>
      <Modal open={openHI.HouseholdModifyDetails == true}>
        <Box className="modalDetailPopup">
          <Box className="modalDetailPopup-inner">
            <Box className="header">
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h4>Update Household Details</h4>
                  <Link
                    className="close"
                    onClick={() =>
                      setOpenHI((prev: any) => ({
                        ...prev,
                        HouseholdModifyDetails: false,
                      }))
                    }
                  ></Link>
                </Grid>
              </Grid>
            </Box>
            <Box className="modalContent">
              <Grid container spacing={3} justifyContent="center">
                <Grid xs={12} className="toggleButtons" textAlign="center">
                  <h5>You Are?</h5>
                  <ToggleButtonGroup
                    value={youAre}
                    exclusive
                    onChange={handleYouAre}
                  >
                    <ToggleButton value="Tenant">Tenant</ToggleButton>
                    <ToggleButton value="Owner">Owner</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid xs={12} className="toggleButtons" textAlign="center">
                  <h5>You Have?</h5>
                  <ToggleButtonGroup
                    value={youHave}
                    exclusive
                    onChange={handleYouHave}
                  >
                    <ToggleButton value="Flat">Flat</ToggleButton>
                    <ToggleButton value="House">Independent House</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid xs={12} className="toggleButtons" textAlign="center">
                  <h5>Sum Insured Values</h5>
                  <ToggleButtonGroup
                    value={sumInsuredValues}
                    exclusive
                    onChange={handleSumInsured}
                    sx={{ justifyContent: "center", flexWrap: "wrap" }}
                  >
                    <ToggleButton
                      value="Building"
                      onClick={() => {
                        setSumInsuredValues("Building");
                      }}
                    >
                      Building
                    </ToggleButton>
                    <ToggleButton
                      value="HouseholdItems"
                      onClick={() => {
                        setSumInsuredValues("HouseholdItems");
                      }}
                    >
                      Household Items
                    </ToggleButton>
                    <ToggleButton
                      value="BuildingAndHouseItems"
                      onClick={() => {
                        setSumInsuredValues("BuildingAndHouseItems");
                      }}
                    >
                      Building + Household Items
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                {/* Building Fields */}
                {sumInsuredValues === "Building" ? (
                  <Grid container spacing={2} justifyContent="center">
                    <Grid xs={12} textAlign="center">
                      <h5>Enter Value of Building & Carpet Area</h5>
                    </Grid>
                    <Grid xs={12}>
                      <RKTextField
                        class_name="inputField"
                        validation_type="CURRENCY"
                        title="Value of Building (in Rs.)"
                        value={updateFields.buildingValue.value}
                        attrName={[
                          "buildingValue",
                          "value",
                          updateFields,
                          setUpdateFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={updateFields.buildingValue.warning}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <RKTextField
                        class_name="inputField"
                        validation_type="CURRENCY"
                        title="Carpet Area (Sq. ft)"
                        value={updateFields.carpetArea.value}
                        attrName={[
                          "carpetArea",
                          "value",
                          updateFields,
                          setUpdateFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={updateFields.carpetArea.warning}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <RKTextField
                        class_name="inputField"
                        validation_type="CURRENCY"
                        title="Cost of Construction Area (per Sq. ft)"
                        value={updateFields.constructionCost.value}
                        attrName={[
                          "constructionCost",
                          "value",
                          updateFields,
                          setUpdateFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={updateFields.constructionCost.warning}
                      />
                    </Grid>
                  </Grid>
                ) : null}

                {/* Household Item Fields */}
                {sumInsuredValues === "HouseholdItems" ? (
                  <Grid container spacing={2} justifyContent="center">
                    <Grid xs={12} textAlign="center">
                      <h5>Enter Value of Household Items</h5>
                    </Grid>
                    <Grid xs={12}>
                      <RKTextField
                        class_name="inputField"
                        validation_type="CURRENCY"
                        title="Value of Household Items (in Rs.)"
                        value={updateFields.householdItems.value}
                        attrName={[
                          "householdItems",
                          "value",
                          updateFields,
                          setUpdateFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={updateFields.householdItems.warning}
                      />
                    </Grid>
                  </Grid>
                ) : null}

                {/* Building + Household Fields */}
                {sumInsuredValues === "BuildingAndHouseItems" ? (
                  <Grid container spacing={2} justifyContent="center">
                    <Grid xs={12} textAlign="center">
                      <h5>
                        Enter Value of Building, Household Items & Carpet Area
                      </h5>
                    </Grid>
                    <Grid xs={12}>
                      <RKTextField
                        class_name="inputField"
                        validation_type="CURRENCY"
                        title="Value of Building (in Rs.)"
                        value={updateFields.buildingValue.value}
                        attrName={[
                          "buildingValue",
                          "value",
                          updateFields,
                          setUpdateFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={updateFields.buildingValue.warning}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <RKTextField
                        class_name="inputField"
                        validation_type="CURRENCY"
                        title="Carpet Area (Sq. ft)"
                        value={updateFields.carpetArea.value}
                        attrName={[
                          "carpetArea",
                          "value",
                          updateFields,
                          setUpdateFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={updateFields.carpetArea.warning}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <RKTextField
                        class_name="inputField"
                        validation_type="CURRENCY"
                        title="Cost of Construction Area (per Sq. ft)"
                        value={formatIndianCurrency(
                          Number(updateFields.constructionCost.value)
                        )}
                        attrName={[
                          "constructionCost",
                          "value",
                          updateFields,
                          setUpdateFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={updateFields.constructionCost.warning}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <RKTextField
                        class_name="inputField"
                        validation_type="CURRENCY"
                        title="Value of Household Items (in Rs.)"
                        value={updateFields.householdItems.value}
                        attrName={[
                          "householdItems",
                          "value",
                          updateFields,
                          setUpdateFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={updateFields.householdItems.warning}
                      />
                    </Grid>
                  </Grid>
                ) : null}

                <Grid xs={12} textAlign="center">
                  <Button className="redBtn">Update Details</Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default HouseholdModifyDetails;
