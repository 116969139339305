import { URL_CONSTANTS } from "../APIVar/URLConstants";
import {
  GetAPI,
  PostAPI,
  DeleteAPI,
  PatchAPI,
  PutAPI,
} from "./AxiosApiInstance/API";
import { BuyActionDTO } from "./DTO/PolicyDTO";

const policy_buy_action = (
  onSuccess: Function,
  onError: Function,
  dto: BuyActionDTO
) => {
  PostAPI.call(URL_CONSTANTS.policy_buy_action, dto, onSuccess, {}, onError);
};

const get_policy_pdf = (
  onSuccess: Function,
  onError: Function,
  policyNo: string
) => {
  GetAPI.call(URL_CONSTANTS.get_policy_pdf(policyNo), onSuccess, {}, onError);
};

const get_payment_status = (
  onSuccess: Function,
  onError: Function,
  policyNo: string
) => {
  GetAPI.call(
    URL_CONSTANTS.get_payment_status(policyNo),
    onSuccess,
    {},
    onError
  );
};
const get_plan_details = (
  onSuccess: Function,
  onError: Function,
  productId: string
) =>
  GetAPI.call(
    URL_CONSTANTS.get_plan_details(productId),
    onSuccess,
    {},
    onError
  );

const download_brochure = (
  onSuccess: Function,
  onError: Function,
  productId: string
) => {
  GetAPI.call(
    URL_CONSTANTS.download_brochure(productId),
    onSuccess,
    {},
    onError
  );
};

const icici_policy_pdf = (
  onSuccess: Function,
  onError: Function,
  quoteNo: string,
  policyNo: string
) => {
  GetAPI.call(
    URL_CONSTANTS.icici_policy_pdf(quoteNo, policyNo),
    onSuccess,
    {},
    onError
  );
};

export const POLICY_SERVICES = {
  policy_buy_action,
  get_policy_pdf,
  get_plan_details,
  download_brochure,
  icici_policy_pdf,
  get_payment_status,
};
