const renderUrl = {
  LOCAL: "http://localhost:5000",
  DEV: "https://devhinode.masuraksha.com",
};

export const environment = "DEV";
const finalUrl: any = renderUrl[environment];
const BASE_URL: any = `${finalUrl}/api/v1/`;

export const URL_CONSTANTS = {
  get_quick_quotes_by_quote_id: `${BASE_URL}home/quote`,
  get_household_detail_by_quote_id: (id: string) =>
    `${BASE_URL}home/form/${id}`,
  update_household_details: `${BASE_URL}home/update-form`,
  get_city_state_by_pincode: `${BASE_URL}proposal/pincode`,
  policy_buy_action: `${BASE_URL}home/buyaction`,

  get_document_type_list: (company_code: string, doc_for: string) =>
    `${BASE_URL}home/ckyc/${company_code}/doc/${doc_for}`,

  ckyc_digit: `${BASE_URL}home/kyc/godigit`,
  manual_kyc_digit: `${BASE_URL}home/kyc/godigit/manual`,
  save_proposal_info: (quoteId: string) =>
    `${BASE_URL}proposal/update/${quoteId}`,

  create_quote: `${BASE_URL}home/createQuote`,

  icici_create_quote: `${BASE_URL}home/createQuote/icici`,

  get_policy_pdf: (policyNo: string) => `${BASE_URL}home/pdf/${policyNo}`,
  get_payment_status: (policyNo: string) =>
    `${BASE_URL}home/paymentStatus/${policyNo}`,
  update_digit_quote_premium: (quoteId: string) =>
    `${BASE_URL}home/proposal/${quoteId}`,

  update_icici_quote_premium: (quoteId: string) =>
    `${BASE_URL}home/proposal/icici/${quoteId}`,

  get_company_list: `${BASE_URL}home/company/list`,

  get_nominee_relations: (productId: string) =>
    `${BASE_URL}proposal/nominee/${productId}`,

  get_bank_list: (companyCode: string) =>
    `${BASE_URL}proposal/bank/${companyCode}`,

  get_porposal_page_info: (quoteId: string) =>
    `${BASE_URL}proposal/details/${quoteId}`,

  verify_icici_kyc: `${BASE_URL}home/kyc/icici`,

  get_plan_details: (productId: string) =>
    `${BASE_URL}home/plan/details/${productId}`,

  email_quotes: `${BASE_URL}home/email/quote`,

  download_brochure: (productId: string) =>
    `${BASE_URL}home/brochures/${productId}`,

  icici_payment_verify: (quoteNo: string, redirectUrl: string) =>
    `${BASE_URL}home/quote/icici/verify/${quoteNo}?redirectUrl=${redirectUrl}`,

  icici_policy_pdf: (quoteNo: string, policyNo: string) =>
    `${BASE_URL}home/icici/pdf?quote_no=${quoteNo}&policyNo=${policyNo}`,

  icici_manual_kyc: `${BASE_URL}home/kyc/icici/manual`,

  // icici_manual_kyc: () => {
  //   `${BASE_URL}home/kyc/icici/manual`;
  // },
};
