import * as React from "react";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../Navbar/Navbar.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetProposalState } from "../../../../State/Slices/Proposal/ProposalSlice";
import { resetKYCResponseState } from "../../../../State/Slices/KycResponse/KycResponseSlice";
import { resetHouseHoldState } from "../../../../State/Slices/HouseholdDetails/HouseholdDetailSlice";
import { resetQuoteState } from "../../../../State/Slices/Quotes/QuoteSlice";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const homeHandler = () => {
    dispatch(resetProposalState({}));
    dispatch(resetKYCResponseState({}));
    // dispatch(resetHouseHoldState({}));
    // dispatch(resetQuoteState({}));
    // navigate("/");
    // navigate("/");
    window.location.href = "https://dev.masuraksha.com/";
  };
  return (
    <Box className="proposalNavbar">
      <Grid container columnSpacing={3}>
        <Grid xs={12}>
          <img
            src="/images/masuraksha_logo.svg"
            height="36px"
            alt="Brand Logo"
            onClick={() => homeHandler()}
            style={{ cursor: "pointer" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Navbar;
