import AddressDetails from "../../../../../Pages/Desktop/HI/ProposalPage/GoDigit/AddressDetails/AddressDetails";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

const AddressDetailsContainer = () => {
  const isMobile = useIsMobile();

  return <>{isMobile ? <></> : <AddressDetails />}</>;
};

export default AddressDetailsContainer;
