import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import { HI_PATH } from "../../../../Path/HI/HIPath";
import { useAppDispatch, useAppSelector } from "../../../../State/Hooks";
import { THouseholdDetails } from "../../../../State/Types/THouseholdDetails";
import {
  extractNumbersFromString,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";
import { quoteSlice } from "../../../../State/Slices/Quotes/QuoteSlice";
import { householdDetailsSlice } from "../../../../State/Slices/HouseholdDetails/HouseholdDetailSlice";
import { proposalSlice } from "../../../../State/Slices/Proposal/ProposalSlice";
import { kycResponseSlice } from "../../../../State/Slices/KycResponse/KycResponseSlice";
import { DOC_TYPE_CODE } from "../../../../SupportingFiles/Enums/DocTypeCode";
import { KYC_STATUS } from "../../../../State/Types/TKycResponse";
import { PROPOSAL_SERVICES } from "../../../../Services/ProposalService";
import { QUOTES_SERVICES } from "../../../../Services/QuotesService";
import Navbar from "../../../../Components/Desktop/ProposalComponents/Navbar/Navbar";
import LeftSidebar from "../../../../Components/Desktop/ProposalComponents/LeftSidebar/LeftSidebar";
import HouseHoldBreakupContainer from "../../../../Container/HI/ProposalPage/ICICI/HouseHoldBreakup/HouseHoldBreakupContainer";
import VerifyKYCContainer from "../../../../Container/HI/ProposalPage/ICICI/VerifyKYC/VerifyKYCContainer";
import ManualKYCContainer from "../../../../Container/HI/ProposalPage/ICICI/ManualKYC/ManualKYCContainer";
import InBuiltCoveragesContainer from "../../../../Container/HI/ProposalPage/ICICI/InBuiltCoverages/InBuiltCoveragesContainer";
import AddOnsContainer from "../../../../Container/HI/ProposalPage/ICICI/AddOns/AddOnsContainer";
import OwnerDetailsContainer from "../../../../Container/HI/ProposalPage/ICICI/OwnerDetails/OwnerDetailsContainer";
import PropertyDetailsContainer from "../../../../Container/HI/ProposalPage/ICICI/PropertyDetails/PropertyDetailsContainer";
import AddressDetailsContainer from "../../../../Container/HI/ProposalPage/ICICI/AddressDetails/AddressDetailsContainer";
import ReviewDetailsContainer from "../../../../Container/HI/ProposalPage/ICICI/ReviewDetails/ReviewDetailsContainer";

interface PROPOSAL_INFO_RESPONSE {
  insurance: {
    id: string;
    quote_no: string;
    module: string;
    insurance_type: string;
    sum_insured: string;
    name: string;
    email: string;
    mobile: string;
    pincode: string;
    state: string;
    city: string;
    status: string;
    source_url: string;
    utm_source: string;
    utm_medium: string;
    utm_medium_d: string;
    utm_medium_m: string;
    utm_campaign: string;
    utm_keyword: string;
    tagged_by_agent: string;
    cron_mail_status: string;
    ip_address: string;
    prop_event: string;
    owner_type: string;
    building_type: string;
    building_value: string;
    household_value: string;
    carpet_area: string;
    cost_of_construction: string;
    tenor: string;
    created: string;
    updated: string;
    insured_for: string;
  };
  proposal: {
    id: string;
    quote_no: string;
    owner_details: string;
    co_owner_details: string;
    property_details: string;
    communication_address: string;
    loan_details: string;
    nominee_details: string;
    household_details: string;
    add_ons: string;
    in_built_coverages: string;
    created: string;
    updated: string;
  };
  buyAction: {
    id: string;
    quote_no: string;
    insurance_type: string;
    product_id: string;
    product_code: string;
    company_code: string;
    sum_insured: string;
    premium: string;
    prev_premium: string;
    request: string | null;
    created: string;
    updated: string;
  };
  CompanyDetails: {
    id: string;
    company_master_id: string;
    company_code: string;
    valid_from: string;
    valid_to: string;
    product_type_id: string;
    product_type_code: string;
    claim_ratio: string;
    avg_time_to_sttle_claim: string;
    net_income_ration: string;
    policy_sales_growth: string;
    customer_satisfaction: string;
    logo: string;
    status: string;
    created: string;
    logoUrl: string;
  };
  productDetails: {
    id: string;
    parent_id: string;
    product_type_id: string;
    product_type_code: string;
    product_code: string;
    product_name: string;
    product_desc: string;
    valid_from: string;
    valid_to: string;
    rating: string;
    brochure: string;
    quote_action: string;
    rate_calc_method: string;
    proposal_method: string;
    is_online: string;
    status: string;
    created: string;
    tax_calc_method: string;
    discount_calc_method: string;
    buy_online_code: string;
    special_feature_codes: string;
    policy_covers: string;
    bs_ty_new: string;
    bs_ty_rollover: string;
    bs_ty_exp: string;
    short_name: string;
    prem_calc_action: string;
    tax_calc_action: string;
    discount_calc_action: string;
  };
  ckycDetails: string;
}

const ICICI_HI_ROUTES = () => {
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state);
  const location = useLocation();
  const [isHIPath, setIsHIPath] = useState<boolean>(false);

  useEffect(() => {
    setIsHIPath(Object.values(HI_PATH).includes(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    if (!isEmpty(state.quote.quote_id)) {
      getProposalInfo(state.quote.quote_id);
    }
  }, [location.pathname]);

  const getProposalInfo = (quoteId: string) => {
    const onSuccess = (res: any) => {
      const dataToUse: PROPOSAL_INFO_RESPONSE = res.data.data;

      const objToSet: THouseholdDetails = {
        buildingValue: {
          value: dataToUse.insurance.building_value,
          warning: false,
        },
        carpetArea: {
          value: dataToUse.insurance.carpet_area,
          warning: false,
        },
        costOfconstruction: {
          value: dataToUse.insurance.cost_of_construction,
          warning: false,
        },
        email: dataToUse.insurance.email,
        householdItems: {
          value: dataToUse.insurance.household_value,
          warning: false,
        },
        policyTerm: Number(dataToUse.insurance.tenor.split("-")[1]),
        sumInsuredFor: dataToUse.insurance.insured_for,
        youAre: dataToUse.insurance.owner_type,
        youHave: dataToUse.insurance.building_type,
        name: dataToUse.insurance.name,
        mobile: dataToUse.insurance.mobile,
      };

      let navigateToBreakup = false;
      const householdSI_threshhold = 1000000;

      if (
        Number(extractNumbersFromString(dataToUse.insurance.household_value)) >
        householdSI_threshhold
      ) {
        navigateToBreakup = true;
      } else if (
        Number(extractNumbersFromString(dataToUse.insurance.household_value)) >
        Number(extractNumbersFromString(dataToUse.insurance.building_value)) *
          0.2
      ) {
        navigateToBreakup = true;
      }

      const commAddressObj = JSON.parse(
        dataToUse.proposal.communication_address
      );
      const propertyDetails = JSON.parse(dataToUse.proposal.property_details);

      dispatch(quoteSlice.actions.setQuoteId(quoteId));
      console.log("biiiiiii==", objToSet);
      dispatch(householdDetailsSlice.actions.setHouseholdDetails(objToSet));
      dispatch(quoteSlice.actions.setNavigateToBreakup(navigateToBreakup));
      if (commAddressObj) {
        // debugger;
        dispatch(
          proposalSlice.actions.setAddressDetails({
            communicationAddress: {
              address: { value: commAddressObj.address, warning: false },
              address2: { value: commAddressObj.address2, warning: false },
              city: { value: commAddressObj.city, warning: false },
              state: { value: commAddressObj?.state, warning: false },
              pincode: { value: commAddressObj.pincode, warning: false },
            },
            isSame: state.proposalInformation.addressDetails.isSame,
            propertyAddress: {
              address: {
                setFromKycResp: false,
                value: propertyDetails.address,
                warning: false,
              },
              address2: {
                setFromKycResp: false,
                value: "",
                warning: false,
              },
              city: {
                setFromKycResp: false,
                value: propertyDetails.city,
                warning: false,
              },
              state: {
                setFromKycResp: false,
                value: propertyDetails?.state,
                warning: false,
              },
              pincode: {
                setFromKycResp: false,
                value: propertyDetails.pincode,
                warning: false,
              },
            },
          })
        );
      }

      const itemsBreakup = JSON.parse(dataToUse.proposal.household_details);

      dispatch(
        proposalSlice.actions.setHouseholdItemsBreakup({
          electronics: itemsBreakup?.electronics,
          furniture: itemsBreakup?.furniture,
          others: itemsBreakup?.others,
          showError: false,
        })
      );
      console.log("xxxxx", dataToUse);
      // console.log("yyyyyy", dataToUse);
      dispatch(
        proposalSlice.actions.setPolicyInfo({
          ...state.proposalInformation.policy_information,
          CompanyDetails: {
            avg_time_to_sttle_claim:
              dataToUse.CompanyDetails.avg_time_to_sttle_claim,
            claim_ratio: dataToUse.CompanyDetails.claim_ratio,
            company_code: dataToUse.CompanyDetails.company_code,
            company_master_id: dataToUse.CompanyDetails.company_master_id,
            created: dataToUse.CompanyDetails.created,
            customer_satisfaction:
              dataToUse.CompanyDetails.customer_satisfaction,
            id: dataToUse.CompanyDetails.id,
            logo: dataToUse.CompanyDetails.logo,
            logoUrl: dataToUse.CompanyDetails.logoUrl,
            net_income_ration: dataToUse.CompanyDetails.net_income_ration,
            policy_sales_growth: dataToUse.CompanyDetails.policy_sales_growth,
            product_type_code: dataToUse.CompanyDetails.product_type_code,
            product_type_id: dataToUse.CompanyDetails.product_type_id,
            status: dataToUse.CompanyDetails.status,
            valid_from: dataToUse.CompanyDetails.valid_from,
            valid_to: dataToUse.CompanyDetails.valid_to,
          },
          productDetails: {
            brochure: dataToUse.productDetails.brochure,
            bs_ty_exp: dataToUse.productDetails.bs_ty_exp,
            bs_ty_new: dataToUse.productDetails.bs_ty_new,
            bs_ty_rollover: dataToUse.productDetails.bs_ty_rollover,
            buy_online_code: dataToUse.productDetails.buy_online_code,
            created: dataToUse.productDetails.created,
            discount_calc_action: dataToUse.productDetails.discount_calc_action,
            discount_calc_method: dataToUse.productDetails.discount_calc_method,
            id: dataToUse.productDetails.id,
            is_online: dataToUse.productDetails.is_online,
            parent_id: dataToUse.productDetails.parent_id,
            policy_covers: dataToUse.productDetails.policy_covers,
            prem_calc_action: dataToUse.productDetails.prem_calc_action,
            product_code: dataToUse.productDetails.product_code,
            product_desc: dataToUse.productDetails.product_desc,
            product_name: dataToUse.productDetails.product_name,
            product_type_code: dataToUse.productDetails.product_type_code,
            product_type_id: dataToUse.productDetails.product_type_id,
            proposal_method: dataToUse.productDetails.proposal_method,
            quote_action: dataToUse.productDetails.quote_action,
            rate_calc_method: dataToUse.productDetails.rate_calc_method,
            rating: dataToUse.productDetails.rating,
            short_name: dataToUse.productDetails.short_name,
            special_feature_codes:
              dataToUse.productDetails.special_feature_codes,
            status: dataToUse.productDetails.status,
            tax_calc_action: dataToUse.productDetails.tax_calc_action,
            tax_calc_method: dataToUse.productDetails.tax_calc_method,
            valid_from: dataToUse.productDetails.valid_from,
            valid_to: dataToUse.productDetails.valid_to,
          },
        })
      );

      if (dataToUse.ckycDetails) {
        const kycData: {
          correlationId: string;
          kyc_details: {
            alternate_address: {
              address_line_1: string;
              address_line_2: string;
              pin_code: string;
              city: string;
              district: string;
              state: string;
            };
            certificate_number: string;
            certificate_type: string;
            ckyc_number: string;
            customer_type: string;
            dob: string;
            email: string;
            first_name: string;
            full_name: string;
            gender: string;
            il_kyc_ref_no: string;
            last_name: string;
            mobile_number: string;
            permanent_address: {
              address_line_1: string;
              address_line_2: string;
              pin_code: string;
              city: string;
              district: string;
              state: string;
            };
          };
          message: string;
          status: boolean;
          statusMessage: string;
        } = JSON.parse(JSON.parse(dataToUse.ckycDetails));

        if (kycData.kyc_details) {
          dispatch(
            kycResponseSlice.actions.setKycResonse({
              aadharNumber:
                kycData.kyc_details.certificate_type ===
                DOC_TYPE_CODE.ICICI_AADHAR
                  ? kycData.kyc_details.certificate_number
                  : "",
              address_line_1:
                kycData.kyc_details.permanent_address.address_line_1,
              address_line_2:
                kycData.kyc_details.permanent_address.address_line_2,
              city: kycData.kyc_details.permanent_address.city || "",
              ckycNumber: kycData.kyc_details.ckyc_number || "",
              customer_type: kycData.kyc_details.customer_type || "",
              digitKYCId: "",
              dob: kycData.kyc_details.dob || "",
              email: kycData.kyc_details.email || "",
              first_name: kycData.kyc_details.first_name || "",
              gender: kycData.kyc_details.gender || "",
              gstinNumber: "",
              kycStatus: KYC_STATUS.FAILED,
              last_name: kycData.kyc_details.last_name || "",
              middle_name: "",
              mobile_number: kycData.kyc_details.mobile_number || "",
              panNumber:
                kycData.kyc_details.certificate_type === DOC_TYPE_CODE.ICICI_PAN
                  ? kycData.kyc_details.certificate_number
                  : "",
              pincode: kycData.kyc_details.permanent_address.pin_code || "",
              state: kycData.kyc_details.permanent_address.state || "",
              iciciKYCId: kycData.kyc_details.il_kyc_ref_no,
              iciciKycStatus:
                kycData.statusMessage.toUpperCase() === KYC_STATUS.SUCCESS
                  ? KYC_STATUS.SUCCESS
                  : KYC_STATUS.FAILED,
            })
          );
          console.log("111", dataToUse.insurance.mobile);

          const data = JSON.parse(dataToUse.proposal.owner_details);
          // debugger;
          dispatch(
            proposalSlice.actions.setOwnerDetailsScreen({
              ...state.proposalInformation.ownerDetailsScreen,
              ownerDetails: {
                dob: {
                  setFromKycResp: false,
                  value: kycData.kyc_details.dob,
                  warning: kycData.kyc_details.dob ? false : true,
                },
                email: {
                  value: data.email,
                  warning: false,
                },
                gender: {
                  value: kycData.kyc_details.gender === "M" ? "Male" : "Female",
                  warning: false,
                },
                mobile: {
                  setFromKycResp: false,
                  value: dataToUse.insurance.mobile,
                  warning: false,
                },
                name: {
                  setFromKycResp: false,
                  value: kycData.kyc_details.full_name,
                  warning: false,
                },
                pancard: {
                  value:
                    kycData.kyc_details.certificate_type ===
                    DOC_TYPE_CODE.ICICI_PAN
                      ? kycData.kyc_details.certificate_number
                      : "",
                  warning: false,
                },
              },
            })
          );
        }
      }

      if (quoteId) {
        updateIciciQuotePremium(quoteId);
      }
    };

    const onError = (err: any) => {
      console.log(err);
    };

    PROPOSAL_SERVICES.get_proposal_page_info(onSuccess, onError, quoteId);
  };

  const updateIciciQuotePremium = (quoteId: string) => {
    const quoteIdToUse = quoteId || state.quote.quote_id;

    if (!quoteIdToUse) {
      return;
    }

    const onSuccess = (res: any) => {
      dispatch(
        proposalSlice.actions.setPolicyPremiumInfo({
          sumInsured: "",
          buildingSI: "",
          householdSI: "",
          grossPremium: res.data.quoteLists.totalPremium,
          additionalPremium: "",
          ambulanceChargesCover: "",
          antiTheftDiscount: "",
          associationDiscount: "",
          basicPremium: res.data.quoteLists.basicPremium,
          consumablesCover: "",
          deductibleDiscount: "",
          derivedPremium: "",
          digit_plan_type: "",
          discountedNetPremium: "",
          discountPremium: "",
          earthquakeDiscountPremium: "",
          earthQuakePremium: "",
          enquiryId: "",
          ePolicyDiscount: "",
          lossOfRentPremium: "",
          ncbDiscount: "",
          netPremium: "",
          odDiscount: "",
          otherDiscount: "",
          otherDiscountPremium: "",
          passengerCover: "",
          planCode: "",
          serviceTax: "",
          terrorismDiscountPremium: "",
          terrorismPremium: "",
          totalPremium: "",
          totalTax: res.data.quoteLists.totalTax,
          correlationId: res.data.quoteLists.correlationId,
        })
      );
    };

    const onError = (err: any) => {
      console.log(err);
    };

    QUOTES_SERVICES.update_icici_quote_premium(
      onSuccess,
      onError,
      quoteIdToUse
    );
  };

  return (
    <>
      {isHIPath ? (
        <Box className="proposalWrapper">
          <Navbar />
          <Grid container columnSpacing={3} className="pb-0 pt-0">
            <LeftSidebar class_name="homeIcon" />
            <Grid xs className="proposalContentSection">
              <Routes>
                <Route
                  path={HI_PATH.HOUSE_HOLD_BREAKUP}
                  element={<HouseHoldBreakupContainer />}
                />
                <Route
                  path={HI_PATH.VERIFY_CKYC}
                  element={<VerifyKYCContainer />}
                />
                <Route
                  path={HI_PATH.MANUAL_KYC}
                  element={<ManualKYCContainer />}
                />
                <Route
                  path={HI_PATH.IN_BUILT_COVERAGES}
                  element={<InBuiltCoveragesContainer />}
                />
                <Route path={HI_PATH.ADD_ONS} element={<AddOnsContainer />} />
                <Route
                  path={HI_PATH.OWNER_DETAILS}
                  element={<OwnerDetailsContainer />}
                />
                <Route
                  path={HI_PATH.PROPERTY_DETAILS}
                  element={<PropertyDetailsContainer />}
                />
                <Route
                  path={HI_PATH.ADDRESS_DETAILS}
                  element={<AddressDetailsContainer />}
                />
                <Route
                  path={HI_PATH.REVIEW_DETAILS}
                  element={<ReviewDetailsContainer />}
                />
              </Routes>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </>
  );
};

export default ICICI_HI_ROUTES;
