import HouseholdItemsBreakup from "../../../../../Pages/Desktop/HI/ProposalPage/ICICI/HouseHoldBreakup/HouseHoldBreakup";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

const HouseHoldBreakupContainer = () => {
  const isMobile = useIsMobile();

  return <>{isMobile ? <></> : <HouseholdItemsBreakup />}</>;
};

export default HouseHoldBreakupContainer;
