import React from "react";
import { Box } from "@mui/system";
import Grid from "@mui/material/Unstable_Grid2";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Tab } from "@mui/material";

interface Props {
  features: Array<{ title: string; description: string }>;
  exclusions: Array<{ title: string; description: string }>;
}

function HouseholdMainContent({ features, exclusions }: Props) {
  const [value, setValue] = React.useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <Box className="modalContent">
      <Grid container spacing={3}>
        {/* <Grid xs={12}>
          <hr />
          <h5>Additional Coverage</h5>
        </Grid> */}
        <Grid xs={12} className="tabs-section">
          <TabContext value={value}>
            <TabPanel className="tabPanel" value="1">
              <Grid xs={12}>
                <h3 style={{ textAlign: "center" }}>Features Included</h3>
                <hr />
                <ul
                  className="features_ul"
                  style={{ display: "flex", flexWrap: "wrap", gap: "12px" }}
                >
                  {features.map((item, index) => {
                    return (
                      <li key={index} style={{ width: "100%" }}>
                        <span key={index}>
                          <h5>{item.title}</h5>
                          <p>{item.description}</p>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            </TabPanel>

            <TabPanel className="tabPanel" value="2">
              <Grid xs={12}>
                <h3 style={{ textAlign: "center" }}>Exclusions</h3>
                <hr />
                <ul style={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
                  {exclusions.map((item, index) => {
                    return (
                      <li
                        key={index}
                        style={{ width: "100%" }}
                        // style={{ width: "calc(50% - 6px)", listStyle: "none" }}
                      >
                        <span key={index}>
                          <h5>{item.title}</h5>
                          <p>{item.description}</p>
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            </TabPanel>

            {/* Tabs */}
            <Box className="tabFooter">
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Features" value="1" />
                <Tab label="Exclusions" value="2" />
              </TabList>
            </Box>
          </TabContext>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HouseholdMainContent;
