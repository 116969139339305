import QuotePage from "../../../Pages/Desktop/HI/QuotePage/QuotePage";
import MQuotePage from "../../../Pages/Mobile/HI/MQuotePage/MQuotePage";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

const HIQuoteContainer = () => {
  const isMobile = useIsMobile();

  return <>{isMobile ? <MQuotePage /> : <QuotePage />}</>;
};

export default HIQuoteContainer;
