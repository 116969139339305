import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../../../../Components/Desktop/ProposalComponents/AddonQuestion/AddonQuestion.scss";
import Footer from "../../../../../../Components/Desktop/ProposalComponents/Footer/Footer";
import "../../../../../../Components/Desktop/SwitchButton/SwitchButton.scss";
import { HI_PATH } from "../../../../../../Path/HI/HIPath";
import { Addon_Coverages_DTO } from "../../../../../../Services/DTO/ProposalDTO";
import { PROPOSAL_SERVICES } from "../../../../../../Services/ProposalService";
import { QUOTES_SERVICES } from "../../../../../../Services/QuotesService";
import { useAppDispatch, useAppSelector } from "../../../../../../State/Hooks";
import { pageSlice } from "../../../../../../State/Slices/PageSlice/PageSlice";
import { proposalSlice } from "../../../../../../State/Slices/Proposal/ProposalSlice";
import { RootState } from "../../../../../../State/Store";
import { TAddonCoverages } from "../../../../../../State/Types/TProposal";
import RKTextField from "../../../../../../SupportingFiles/FieldTypes/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../SupportingFiles/FieldTypes/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../../SupportingFiles/FieldTypes/SelectDropdown/SelectDropdown";
import {
  extractNumbersFromString,
  isEmpty,
  objectHasWarning,
  objectHasWarningv2,
} from "../../../../../../SupportingFiles/HelpingFunction";

function AddOns() {
  const navigate = useNavigate();
  const { PAGE_STATUS } = useAppSelector((state) => state.pageSlice);
  const state: TAddonCoverages = useAppSelector(
    (state: RootState) => state.proposalInformation.addonCoverages
  );
  const { proposalInformation, quote } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const location = useLocation();

  const [addonStatus, setAddonStatus] = useState([false, false]);

  const [personalAccidentCoverage, setPersonalAccidentCoverage] =
    useState(false);
  const [personalAccidentCoverageSpouse, setPersonalAccidentCoverageSpouse] =
    useState(false);
  const [isSectionDisabled, setIsSectionDisabled] = useState(true);

  const gender_data = [
    { key: "Male", value: "Male" },
    { key: "Female", value: "Female" },
  ];
  const relationship_data = [
    { key: "Father", value: "Father" },
    { key: "Mother", value: "Mother" },
    { key: "Brother", value: "Brother" },
    { key: "Sister", value: "Sister" },
    { key: "Grand Father", value: "Grand Father" },
    { key: "Grand Mother", value: "Grand Mother" },
    { key: "Brother In Law", value: "Brother In Law" },
    { key: "Mother In Law", value: "Mother In Law" },
    { key: "Daughter", value: "Daughter" },
    { key: "Son", value: "Son" },
    { key: "Wife", value: "Wife" },
  ];
  const [insuredAgeData, setInsuredAgeData] = React.useState([{}]);
  const [nomineeAgeData, setNomineeAgeData] = React.useState([{}]);
  const [spouseAgeData, setSpouseAgeData] = React.useState([{}]);
  const [spouseNomineeAgeData, setSpouseNomineeAgeData] = React.useState([{}]);

  useEffect(() => {
    let data = [];
    for (var i = 18; i < 111; i++) {
      data.push({ label: i, value: i + " Years" });
    }
    setInsuredAgeData(data);
    setSpouseAgeData(data);
  }, []);

  useEffect(() => {
    let data = [];
    for (var i = 5; i < 111; i++) {
      data.push({ label: i, value: i + " Years" });
    }
    setNomineeAgeData(data);
    setSpouseNomineeAgeData(data);
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    console.log("updateMasterState", attrName, parseInt(value));
    if (attrName[0] === "pacDetails") {
      if (attrName[1] === "nomineeAge") {
        if (parseInt(value) > 18) {
          console.log("aga is ", value);
          dispatch(
            proposalSlice.actions.setAddonCoverage({
              ...state,
              pacDetails: {
                ...state.pacDetails,
                [attrName[1]]: {
                  value: value,
                  warning: isEmpty(value),
                },
                nomineeAppointeeName: {
                  ...state.pacDetails.nomineeAppointeeName,
                  warning: false,
                },
              },
            })
          );
          return;
        }
      }

      dispatch(
        proposalSlice.actions.setAddonCoverage({
          ...state,
          pacDetails: {
            ...state.pacDetails,
            [attrName[1]]: {
              value: value,
              warning: isEmpty(value),
            },
          },
        })
      );
    } else if (attrName[0] === "spousePacDetails") {
      if (attrName[1] === "nomineeAge") {
        if (value > 18) {
          dispatch(
            proposalSlice.actions.setAddonCoverage({
              ...state,
              pacSpouseDetails: {
                ...state.pacSpouseDetails,
                [attrName[1]]: {
                  value: value,
                  warning: isEmpty(value),
                },
                nomineeAppointeeName: {
                  ...state.pacSpouseDetails.nomineeAppointeeName,
                  warning: false,
                },
              },
            })
          );
          return;
        }
      }

      dispatch(
        proposalSlice.actions.setAddonCoverage({
          ...state,
          pacSpouseDetails: {
            ...state.pacSpouseDetails,
            [attrName[1]]: {
              value: value,
              warning: isEmpty(value),
            },
          },
        })
      );
    } else {
      setAddonStatus(value);
    }
  };

  const updateDigitQuotePremium = () => {
    const onSuccess = (res: any) => {
      dispatch(
        proposalSlice.actions.setPolicyInfo({
          ...proposalInformation.policy_information,
          premiumDetails: res.data.quoteLists,
        })
      );
    };

    const onError = (err: any) => {
      console.log(err);
    };

    QUOTES_SERVICES.update_digit_quote_premium(
      onSuccess,
      onError,
      quote.quote_id
    );
  };

  const saveProposalInfo = (data: any) => {
    const loadingToast = toast.loading("Updating Proposal...");

    const onSuccess = (res: any) => {
      toast.dismiss(loadingToast);
      if (PAGE_STATUS) {
        dispatch(pageSlice.actions.UPDATE_PAGE_STATUS(false));
        navigate(-1);
      } else {
        navigate(HI_PATH.OWNER_DETAILS);
      }
      updateDigitQuotePremium();
    };

    const onError = (err: any) => {
      toast.dismiss(loadingToast);
    };
    PROPOSAL_SERVICES.save_proposal_info(
      onSuccess,
      onError,
      { add_ons: data },
      quote.quote_id
    );
  };

  const validate_form = () => {
    const obj = proposalInformation.addonCoverages;
    let dto: Addon_Coverages_DTO = {
      isEQzone: false,
      isEQcover: obj.isEQcover,
      isPac: state.isPac,
      isPacSpouse: state.isPacSpouse,
      coverForValuableContent:
        proposalInformation.inbuiltCoverages.coverForValuableContent,
    };

    // If isPac is true, check for warning in pacDetails
    if (obj.isPac) {
      // const warn = objectHasWarningv2(obj.pacDetails);

      const [warn, validatedObj] = objectHasWarningv2(
        structuredClone(obj.pacDetails)
      );
      // return;

      dispatch(
        proposalSlice.actions.setAddonCoverage({
          ...state,
          pacDetails: validatedObj,
        })
      );
      if (warn) {
        toast.error("Please enter valid values", {
          id: "Enter Values Error",
        });
        return;
      }

      dto = {
        ...dto,
        pacDetails: {
          insuredAge: Number(
            extractNumbersFromString(obj.pacDetails.insuredAge.value)
          ),
          insuredGender: obj.pacDetails.insuredGender.value,
          insuredName: obj.pacDetails.insuredName.value,
          nomineeAge: Number(
            extractNumbersFromString(obj.pacDetails.nomineeAge.value)
          ),
          nomineeGender: obj.pacDetails.nomineeGender.value,
          nomineeName: obj.pacDetails.nomineeName.value,
          nomineeRelation: obj.pacDetails.nomineeRelation.value,
        },
      };
    }
    // If isPacSpouse is true, check for warning in pacSpouseDetails
    if (obj.isPacSpouse) {
      // const warn = objectHasWarning(obj.pacSpouseDetails);
      const [warn2, validatedObj2] = objectHasWarningv2(
        structuredClone(obj.pacSpouseDetails)
      );
      console.log("obj.pacSpouseDetails", obj.pacSpouseDetails);
      console.log("warn spouse", warn2, validatedObj2);
      dispatch(
        proposalSlice.actions.setAddonCoverage({
          ...state,
          pacSpouseDetails: validatedObj2,
        })
      );
      if (warn2) {
        toast.error("Please enter valid values in spouse section", {
          id: "Enter Values Error",
        });
        return;
      }

      dto = {
        ...dto,
        pacSpouseDetails: {
          age: Number(extractNumbersFromString(obj.pacSpouseDetails.age.value)),
          name: obj.pacSpouseDetails.name.value,
          nomineeAge: Number(
            extractNumbersFromString(obj.pacSpouseDetails.nomineeAge.value)
          ),
          nomineeAppointeeName: obj.pacSpouseDetails.nomineeAppointeeName.value,
          nomineeGender: obj.pacSpouseDetails.nomineeGender.value,
          nomineeName: obj.pacSpouseDetails.nomineeName.value,
          nomineeRelationWithInsured:
            obj.pacSpouseDetails.nomineeRelationWithInsured.value,
        },
      };
    }

    saveProposalInfo(dto);
  };

  useEffect(() => {
    const obj = proposalInformation.addonCoverages;
    let dto = {
      isEQzone: false,
      isEQcover: obj.isEQcover,
      isPac: state.isPac,
      isPacSpouse: state.isPacSpouse,
      coverForValuableContent:
        proposalInformation.inbuiltCoverages.coverForValuableContent,
    };

    const loadingToast = toast.loading("Updating Proposal...");

    const onSuccess = (res: any) => {
      toast.dismiss(loadingToast);

      // dispatch(pageSlice.actions.UPDATE_PAGE_STATUS(false));
      updateDigitQuotePremium();
    };

    const onError = (err: any) => {
      toast.dismiss(loadingToast);
    };

    PROPOSAL_SERVICES.save_proposal_info(
      onSuccess,
      onError,
      { add_ons: dto },
      quote.quote_id
    );
  }, [
    proposalInformation.addonCoverages.isEQcover,
    state.isPac,
    state.isPacSpouse,
  ]);

  return (
    <Box>
      <h5 className="sectionTitle">Addon Details</h5>
      {/* <Grid container spacing={3}>
        <Grid xs={12} className="addonQuestion borderBottom">
          <Box>
            <Grid container spacing={2}>
              <Grid xs={9} className="question-part" alignSelf={"center"}>
                <h6>Personal Accident</h6>
                <p>Death Benefit of Rs. 5 Lakhs for Insured</p>
              </Grid>
              <Grid xs={3} textAlign="right">
                <p className="amount"></p>
                <div className="toggles">
                  <input
                    id="pac"
                    type="checkbox"
                    checked={state.isPac}
                    onClick={() => {
                      dispatch(
                        proposalSlice.actions.setAddonCoverage({
                          ...state,
                          isPac: !state.isPac,
                          isPacSpouse: false,
                        })
                      );
                      setPersonalAccidentCoverage(!personalAccidentCoverage);
                      setIsSectionDisabled(!isSectionDisabled);
                    }}
                  />
                  <label htmlFor={"pac"} />
                </div>
              </Grid>
            </Grid>
            {state.isPac ? (
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="innerheading">Insured Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Insured Name"}
                    validation_type="NAME"
                    value={state.pacDetails.insuredName.value}
                    attrName={["pacDetails", "insuredName"]}
                    value_update={updateMasterState}
                    warn_status={state.pacDetails.insuredName.warning}
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Insured Age"
                    value={state.pacDetails.insuredAge.value}
                    attrName={["pacDetails", "insuredAge"]}
                    value_update={updateMasterState}
                    data={insuredAgeData}
                    warn_status={state.pacDetails.insuredAge.warning}
                    error_message={""}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Insured Gender"
                    value={state.pacDetails.insuredGender.value}
                    attrName={["pacDetails", "insuredGender"]}
                    value_update={updateMasterState}
                    dropdown_data={gender_data}
                    warn_status={state.pacDetails.insuredGender.warning}
                  />
                </Grid>
                <Grid xs={12}>
                  <h6 className="innerheading"> Nominee Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Nominee Name"}
                    validation_type="NAME"
                    value={state.pacDetails.nomineeName.value}
                    attrName={["pacDetails", "nomineeName"]}
                    value_update={updateMasterState}
                    warn_status={state.pacDetails.nomineeName.warning}
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Nominee Age"
                    value={state.pacDetails.nomineeAge.value}
                    attrName={["pacDetails", "nomineeAge"]}
                    value_update={updateMasterState}
                    data={nomineeAgeData}
                    warn_status={state.pacDetails.nomineeAge.warning}
                    error_message={""}
                  />
                </Grid>

                {Number(
                  extractNumbersFromString(state.pacDetails.nomineeAge.value)
                ) <= 18 ? (
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title={"Appointee Name"}
                      validation_type="NAME"
                      value={state.pacDetails.nomineeAppointeeName.value}
                      attrName={["pacDetails", "nomineeAppointeeName"]}
                      value_update={updateMasterState}
                      warn_status={
                        state.pacDetails.nomineeAppointeeName.warning
                      }
                    />
                  </Grid>
                ) : null}

                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Nominee Gender"
                    value={state.pacDetails.nomineeGender.value}
                    attrName={["pacDetails", "nomineeGender"]}
                    value_update={updateMasterState}
                    dropdown_data={gender_data}
                    warn_status={state.pacDetails.nomineeGender.warning}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Relation with the insured"
                    value={state.pacDetails.nomineeRelation.value}
                    attrName={["pacDetails", "nomineeRelation"]}
                    value_update={updateMasterState}
                    dropdown_data={relationship_data}
                    warn_status={state.pacDetails.nomineeRelation.warning}
                  />
                </Grid>
              </Grid>
            ) : null}
            <Grid
              xs={12}
              className={`addonQuestion borderBottom ${
                isSectionDisabled ? "disabled-section" : ""
              }`}
            >
              <>
                <Grid container spacing={2}>
                  <Grid xs={9} className="question-part" alignSelf={"center"}>
                    <h6>Personal Accident- Spouse</h6>
                    <p>Death Benefit of Rs. 5 Lakhs for Spouse</p>
                  </Grid>
                  <Grid xs={3} textAlign="right">
                    <p className="amount"></p>
                    <div className="toggles">
                      <input
                        id="pacforSpouse"
                        type="checkbox"
                        checked={state.isPacSpouse}
                        onClick={() => {
                          if (!state.isPacSpouse) {
                            dispatch(
                              proposalSlice.actions.setAddonCoverage({
                                ...state,
                                isPacSpouse: !state.isPacSpouse,
                                isPac: true,
                              })
                            );
                          } else {
                            dispatch(
                              proposalSlice.actions.setAddonCoverage({
                                ...state,
                                isPacSpouse: !state.isPacSpouse,
                              })
                            );
                          }
                          setPersonalAccidentCoverageSpouse(
                            !personalAccidentCoverageSpouse
                          );
                          setPersonalAccidentCoverage(true);
                        }}
                      />
                      <label htmlFor={"pacforSpouse"} />
                    </div>
                  </Grid>
                </Grid>
                {state.isPacSpouse ? (
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <h6 className="innerheading"> Spouse Details</h6>
                    </Grid>
                    <Grid xs={4}>
                      <RKTextField
                        class_name="inputField"
                        title={"Spouse Name"}
                        validation_type="NAME"
                        value={state.pacSpouseDetails.name.value}
                        attrName={["spousePacDetails", "name"]}
                        value_update={updateMasterState}
                        warn_status={state.pacSpouseDetails.name.warning}
                      />
                    </Grid>
                    <Grid xs={4}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Spouse Age"
                        value={state.pacSpouseDetails.age.value}
                        attrName={["spousePacDetails", "age"]}
                        value_update={updateMasterState}
                        data={spouseAgeData}
                        warn_status={state.pacSpouseDetails.age.warning}
                        error_message={""}
                      />
                    </Grid>
                    <Grid xs={12}>
                      <h6 className="innerheading"> Spouse Nominee Details</h6>
                    </Grid>
                    <Grid xs={4}>
                      <RKTextField
                        class_name="inputField"
                        title={"Nominee Name"}
                        validation_type="NAME"
                        value={state.pacSpouseDetails.nomineeName.value}
                        attrName={["spousePacDetails", "nomineeName"]}
                        value_update={updateMasterState}
                        warn_status={state.pacSpouseDetails.nomineeName.warning}
                      />
                    </Grid>
                    <Grid xs={4}>
                      <SearchDropdown
                        class_name="inputField"
                        title="Nominee Age"
                        value={state.pacSpouseDetails.nomineeAge.value}
                        attrName={["spousePacDetails", "nomineeAge"]}
                        value_update={updateMasterState}
                        data={spouseNomineeAgeData}
                        warn_status={state.pacSpouseDetails.nomineeAge.warning}
                        error_message={""}
                      />
                    </Grid>
                    {Number(
                      extractNumbersFromString(
                        state.pacSpouseDetails.nomineeAge.value
                      )
                    ) <= 18 ? (
                      <Grid xs={4}>
                        <RKTextField
                          class_name="inputField"
                          title={"Appointee Name"}
                          validation_type="NAME"
                          value={
                            state.pacSpouseDetails.nomineeAppointeeName.value
                          }
                          attrName={[
                            "spousePacDetails",
                            "nomineeAppointeeName",
                          ]}
                          value_update={updateMasterState}
                          warn_status={
                            state.pacSpouseDetails.nomineeAppointeeName.warning
                          }
                        />
                      </Grid>
                    ) : null}
                    <Grid xs={4}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Nominee Gender"
                        value={state.pacSpouseDetails.nomineeGender.value}
                        attrName={["spousePacDetails", "nomineeGender"]}
                        value_update={updateMasterState}
                        dropdown_data={gender_data}
                        warn_status={
                          state.pacSpouseDetails.nomineeGender.warning
                        }
                      />
                    </Grid>
                    <Grid xs={4}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Relation with the insured"
                        value={
                          state.pacSpouseDetails.nomineeRelationWithInsured
                            .value
                        }
                        attrName={[
                          "spousePacDetails",
                          "nomineeRelationWithInsured",
                        ]}
                        value_update={updateMasterState}
                        dropdown_data={relationship_data}
                        warn_status={
                          state.pacSpouseDetails.nomineeRelationWithInsured
                            .warning
                        }
                      />
                    </Grid>
                  </Grid>
                ) : null}
              </>
            </Grid>
            <Grid container spacing={2}>
              <Grid xs={9} className="question-part" alignSelf={"center"}>
                <h6>EarthQuake Cover</h6>
                <p>Death Benefit of Rs. 5 Lakhs for Spouse</p>
              </Grid>
              <Grid xs={3} textAlign="right">
                <p className="amount"></p>
                <div className="toggles">
                  <input
                    id="eqCover"
                    type="checkbox"
                    checked={state.isEQcover}
                    onClick={() => {
                      dispatch(
                        proposalSlice.actions.setAddonCoverage({
                          ...state,
                          isEQcover: !state.isEQcover,
                        })
                      );
                    }}
                  />
                  <label htmlFor={"eqCover"} />
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid> */}

      <Grid container spacing={3}>
        <Grid xs={12} className="addonQuestion borderBottom">
          <Box>
            <Grid container spacing={2}>
              <Grid xs={9} className="question-part" alignSelf={"center"}>
                <h6>Personal Accident</h6>
                <p>Death Benefit of Rs. 5 Lakhs for Insured</p>
              </Grid>
              <Grid xs={3} textAlign="right">
                <p className="amount"></p>
                <div className="toggles">
                  <input
                    id="pac"
                    type="checkbox"
                    checked={state.isPac}
                    onClick={() => {
                      dispatch(
                        proposalSlice.actions.setAddonCoverage({
                          ...state,
                          isPac: !state.isPac,
                          isPacSpouse: false,
                        })
                      );
                      setPersonalAccidentCoverage(!personalAccidentCoverage);
                      setIsSectionDisabled(!isSectionDisabled);
                    }}
                  />
                  <label htmlFor={"pac"} />
                </div>
              </Grid>
            </Grid>
            {state.isPac ? (
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="innerheading">Insured Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Insured Name"}
                    validation_type="NAME"
                    value={state.pacDetails.insuredName.value}
                    attrName={["pacDetails", "insuredName"]}
                    value_update={updateMasterState}
                    warn_status={state.pacDetails.insuredName.warning}
                    error_message={
                      isEmpty(state.pacDetails.insuredName.value)
                        ? "Enter Insured Name"
                        : "Enter Valid Insured Name"
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Insured Age"
                    value={state.pacDetails.insuredAge.value}
                    attrName={["pacDetails", "insuredAge"]}
                    value_update={updateMasterState}
                    data={insuredAgeData}
                    warn_status={state.pacDetails.insuredAge.warning}
                    error_message={
                      isEmpty(state.pacDetails.insuredAge.value)
                        ? "Enter Insured Age"
                        : "Enter Valid Insured Age"
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Insured Gender"
                    value={state.pacDetails.insuredGender.value}
                    attrName={["pacDetails", "insuredGender"]}
                    value_update={updateMasterState}
                    dropdown_data={gender_data}
                    warn_status={state.pacDetails.insuredGender.warning}
                    error_message={
                      isEmpty(state.pacDetails.insuredGender.value)
                        ? "Enter Insured Gender"
                        : "Enter Valid Insured Gender"
                    }
                  />
                </Grid>
                <Grid xs={12}>
                  <h6 className="innerheading"> Nominee Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Nominee Name"}
                    validation_type="NAME"
                    value={state.pacDetails.nomineeName.value}
                    attrName={["pacDetails", "nomineeName"]}
                    value_update={updateMasterState}
                    warn_status={state.pacDetails.nomineeName.warning}
                    error_message={
                      isEmpty(state.pacDetails.nomineeName.value)
                        ? "Enter Nominee Name"
                        : "Enter Valid Nominee Name"
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Nominee Age"
                    value={state.pacDetails.nomineeAge.value}
                    attrName={["pacDetails", "nomineeAge"]}
                    value_update={updateMasterState}
                    data={nomineeAgeData}
                    warn_status={state.pacDetails.nomineeAge.warning}
                    error_message={
                      isEmpty(state.pacDetails.nomineeAge.value)
                        ? "Enter Nominee Age"
                        : "Enter Valid Nominee Age"
                    }
                  />
                </Grid>

                {Number(
                  extractNumbersFromString(state.pacDetails.nomineeAge.value)
                ) <= 18 ? (
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title={"Appointee Name"}
                      validation_type="NAME"
                      value={state.pacDetails.nomineeAppointeeName.value}
                      attrName={["pacDetails", "nomineeAppointeeName"]}
                      value_update={updateMasterState}
                      warn_status={
                        state.pacDetails.nomineeAge.value
                          ? state.pacDetails.nomineeAppointeeName.warning
                          : false
                      }
                      error_message={
                        isEmpty(state.pacDetails.nomineeAppointeeName.value)
                          ? "Enter Appointee Age"
                          : "Enter Valid Appointee Age"
                      }
                    />
                  </Grid>
                ) : null}

                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Nominee Gender"
                    value={state.pacDetails.nomineeGender.value}
                    attrName={["pacDetails", "nomineeGender"]}
                    value_update={updateMasterState}
                    dropdown_data={gender_data}
                    warn_status={state.pacDetails.nomineeGender.warning}
                    error_message={
                      isEmpty(state.pacDetails.nomineeGender.value)
                        ? "Enter Nominee Gender"
                        : "Enter Valid Nominee Gender"
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Relation with the insured"
                    value={state.pacDetails.nomineeRelation.value}
                    attrName={["pacDetails", "nomineeRelation"]}
                    value_update={updateMasterState}
                    dropdown_data={relationship_data}
                    warn_status={state.pacDetails.nomineeRelation.warning}
                    error_message={
                      isEmpty(state.pacDetails.nomineeRelation.value)
                        ? "Enter Nominee Relation"
                        : "Enter Valid Nominee Relation"
                    }
                  />
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12} className="addonQuestion borderBottom">
          <Box className={isSectionDisabled ? "disabled-section" : ""}>
            <Grid container spacing={2}>
              <Grid xs={9} className="question-part" alignSelf={"center"}>
                <h6>Personal Accident- Spouse</h6>
                <p>Death Benefit of Rs. 5 Lakhs for Spouse</p>
              </Grid>
              <Grid xs={3} textAlign="right">
                <p className="amount"></p>
                <div className="toggles">
                  <input
                    id="pacforSpouse"
                    type="checkbox"
                    checked={state.isPacSpouse}
                    onClick={() => {
                      if (!state.isPacSpouse) {
                        dispatch(
                          proposalSlice.actions.setAddonCoverage({
                            ...state,
                            isPacSpouse: !state.isPacSpouse,
                            isPac: true,
                          })
                        );
                      } else {
                        dispatch(
                          proposalSlice.actions.setAddonCoverage({
                            ...state,
                            isPacSpouse: !state.isPacSpouse,
                          })
                        );
                      }
                      setPersonalAccidentCoverageSpouse(
                        !personalAccidentCoverageSpouse
                      );
                      setPersonalAccidentCoverage(true);
                    }}
                  />
                  <label htmlFor={"pacforSpouse"} />
                </div>
              </Grid>
            </Grid>
            {state.isPacSpouse ? (
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="innerheading"> Spouse Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Spouse Name"}
                    validation_type="NAME"
                    value={state.pacSpouseDetails.name.value}
                    attrName={["spousePacDetails", "name"]}
                    value_update={updateMasterState}
                    warn_status={state.pacSpouseDetails.name.warning}
                    error_message={
                      isEmpty(state.pacSpouseDetails.name.value)
                        ? "Enter Spouse Name"
                        : "Enter Valid Spouse Name"
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Spouse Age"
                    value={state.pacSpouseDetails.age.value}
                    attrName={["spousePacDetails", "age"]}
                    value_update={updateMasterState}
                    data={spouseAgeData}
                    warn_status={state.pacSpouseDetails.age.warning}
                    error_message={
                      isEmpty(state.pacSpouseDetails.age.value)
                        ? "Enter Spouse Age"
                        : "Enter Valid Spouse Age"
                    }
                  />
                </Grid>
                <Grid xs={12}>
                  <h6 className="innerheading"> Spouse Nominee Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Nominee Name"}
                    validation_type="NAME"
                    value={state.pacSpouseDetails.nomineeName.value}
                    attrName={["spousePacDetails", "nomineeName"]}
                    value_update={updateMasterState}
                    warn_status={state.pacSpouseDetails.nomineeName.warning}
                    error_message={
                      isEmpty(state.pacSpouseDetails.nomineeName.value)
                        ? "Enter Nominee Name"
                        : "Enter Valid Nominee Name"
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Nominee Age"
                    value={state.pacSpouseDetails.nomineeAge.value}
                    attrName={["spousePacDetails", "nomineeAge"]}
                    value_update={updateMasterState}
                    data={spouseNomineeAgeData}
                    warn_status={state.pacSpouseDetails.nomineeAge.warning}
                    error_message={
                      isEmpty(state.pacSpouseDetails.nomineeAge.value)
                        ? "Enter Nominee Age"
                        : "Enter Valid Nominee Age"
                    }
                  />
                </Grid>
                {Number(
                  extractNumbersFromString(
                    state.pacSpouseDetails.nomineeAge.value
                  )
                ) <= 18 ? (
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title={"Appointee Name"}
                      validation_type="NAME"
                      value={state.pacSpouseDetails.nomineeAppointeeName.value}
                      attrName={["spousePacDetails", "nomineeAppointeeName"]}
                      value_update={updateMasterState}
                      warn_status={
                        state.pacSpouseDetails.nomineeAge.value
                          ? state.pacSpouseDetails.nomineeAppointeeName.warning
                          : false
                      }
                      error_message={
                        isEmpty(
                          state.pacSpouseDetails.nomineeAppointeeName.value
                        )
                          ? "Enter Appointee Name"
                          : "Enter Valid Appointee Name"
                      }
                    />
                  </Grid>
                ) : null}
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Nominee Gender"
                    value={state.pacSpouseDetails.nomineeGender.value}
                    attrName={["spousePacDetails", "nomineeGender"]}
                    value_update={updateMasterState}
                    dropdown_data={gender_data}
                    warn_status={state.pacSpouseDetails.nomineeGender.warning}
                    error_message={
                      isEmpty(state.pacSpouseDetails.nomineeGender.value)
                        ? "Enter Nominee Gender"
                        : "Enter Valid Nominee Gender"
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Relation with the insured"
                    value={
                      state.pacSpouseDetails.nomineeRelationWithInsured.value
                    }
                    attrName={[
                      "spousePacDetails",
                      "nomineeRelationWithInsured",
                    ]}
                    value_update={updateMasterState}
                    dropdown_data={relationship_data}
                    warn_status={
                      state.pacSpouseDetails.nomineeRelationWithInsured.warning
                    }
                    error_message={
                      isEmpty(state.pacSpouseDetails.nomineeGender.value)
                        ? "Enter Spouse Relation"
                        : "Enter Valid Spouse Relation"
                    }
                  />
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12} className="addonQuestion borderBottom">
          <Box>
            <Grid container spacing={2}>
              <Grid xs={9} className="question-part" alignSelf={"center"}>
                <h6>EarthQuake Cover</h6>
                <p>Death Benefit of Rs. 5 Lakhs for Spouse</p>
              </Grid>
              <Grid xs={3} textAlign="right">
                <p className="amount"></p>
                <div className="toggles">
                  <input
                    id="eqCover"
                    type="checkbox"
                    checked={state.isEQcover}
                    onClick={() => {
                      dispatch(
                        proposalSlice.actions.setAddonCoverage({
                          ...state,
                          isEQcover: !state.isEQcover,
                        })
                      );
                    }}
                  />
                  <label htmlFor={"eqCover"} />
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      <Footer continue_function={validate_form} />
    </Box>
  );
}

export default AddOns;
