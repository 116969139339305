import Masonry from "@mui/lab/Masonry";
import { Box } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import toast from "react-hot-toast";
import Footer from "../../../../../../Components/Desktop/ProposalComponents/Footer/Footer";
import PreviewDetailBox from "../../../../../../Components/Desktop/ProposalComponents/PreviewDetailBox/PreviewDetailBox";
import {
  HI_PATH,
  HI_PATH_POLICY_CONFIRM,
} from "../../../../../../Path/HI/HIPath";
import { QUOTES_SERVICES } from "../../../../../../Services/QuotesService";
import { useAppSelector } from "../../../../../../State/Hooks";
import { RootState } from "../../../../../../State/Store";
import "../../../../../../Shared/ProposalPage.scss";
import { URL_CONSTANTS } from "../../../../../../APIVar/URLConstants";
import {
  generateRandomString,
  isEmpty,
  removeNamePrefix,
} from "../../../../../../SupportingFiles/HelpingFunction";
import AddressPreviewDetailBox from "../../../../../../Components/Desktop/ProposalComponents/AddressPreviewDetailBox/AddressPreviewDetailBox";

const ReviewDetails: React.FC = () => {
  const { proposalInformation, quote, kycResponseSlice } = useAppSelector(
    (state) => state
  );

  const quoteState = useAppSelector((state: RootState) => state.quote);
  const [load, setLoad] = useState<boolean>(false);
  const [previewDetails, setPreviewDetails] = useState<
    Array<{
      title: string;
      editLink: string;
      detailView: Array<{
        label: string;
        label_value: string;
      }>;
    }>
  >([
    {
      title: "Owner Details",
      editLink: HI_PATH.OWNER_DETAILS,
      detailView: [
        {
          label: "Full Name",
          label_value: removeNamePrefix(
            proposalInformation.ownerDetailsScreen.ownerDetails.name.value
          ),
        },
        {
          label: "DOB",
          label_value: proposalInformation.ownerDetailsScreen.ownerDetails.dob
            .value
            ? proposalInformation.ownerDetailsScreen.ownerDetails.dob.value.toString()
            : "",
        },
        {
          label: "Gender",
          label_value:
            proposalInformation.ownerDetailsScreen.ownerDetails.gender.value,
        },
        {
          label: "Mobile",
          label_value:
            proposalInformation.ownerDetailsScreen.ownerDetails.mobile.value,
        },
        {
          label: "Email",
          label_value:
            proposalInformation.ownerDetailsScreen.ownerDetails.email.value,
        },
        {
          label: "Pancard",
          label_value:
            proposalInformation.ownerDetailsScreen.ownerDetails.pancard.value,
        },
      ],
    },
    // {
    //   title: "Co-Owner Details",
    //   editLink: HI_PATH.OWNER_DETAILS,
    //   detailView: [
    //     {
    //       label: "Full Name",
    //       label_value:
    //         proposalInformation.ownerDetailsScreen.coOwnerDetails.name.value,
    //     },
    //     {
    //       label: "DOB",
    //       label_value: proposalInformation.ownerDetailsScreen.coOwnerDetails.dob
    //         .value
    //         ? format(
    //             new Date(
    //               proposalInformation.ownerDetailsScreen.coOwnerDetails.dob.value
    //             ),
    //             "P"
    //           )
    //         : "",
    //     },
    //     {
    //       label: "Gender",
    //       label_value:
    //         proposalInformation.ownerDetailsScreen.coOwnerDetails.gender.value,
    //     },
    //     {
    //       label: "Mobile",
    //       label_value:
    //         proposalInformation.ownerDetailsScreen.coOwnerDetails.mobile.value,
    //     },
    //     {
    //       label: "Email",
    //       label_value:
    //         proposalInformation.ownerDetailsScreen.coOwnerDetails.email.value,
    //     },
    //   ],
    // },
    !isEmpty(proposalInformation.ownerDetailsScreen.coOwnerDetails.name.value)
      ? {
          title: "Co-Owner Details",
          editLink: HI_PATH.OWNER_DETAILS,
          detailView: [
            {
              label: "Full Name",
              label_value:
                proposalInformation.ownerDetailsScreen.coOwnerDetails.name
                  .value,
            },
            // {
            //   label: "DOB",
            //   label_value: proposalInformation.ownerDetailsScreen.coOwnerDetails
            //     .dob.value
            //     ? format(
            //         new Date(
            //           proposalInformation.ownerDetailsScreen.coOwnerDetails.dob.value
            //         ),
            //         "P"
            //       )
            //     : "",
            // },
            {
              label: "DOB",
              label_value: proposalInformation.ownerDetailsScreen.coOwnerDetails
                .dob.value
                ? proposalInformation.ownerDetailsScreen.coOwnerDetails.dob.value.toString()
                : "",
            },
            {
              label: "Gender",
              label_value:
                proposalInformation.ownerDetailsScreen.coOwnerDetails.gender
                  .value,
            },
            {
              label: "Mobile",
              label_value:
                proposalInformation.ownerDetailsScreen.coOwnerDetails.mobile
                  .value,
            },
            {
              label: "Email",
              label_value:
                proposalInformation.ownerDetailsScreen.coOwnerDetails.email
                  .value,
            },
          ],
        }
      : { detailView: [], editLink: "", title: "" },
    {
      title: "Address Details",
      editLink: HI_PATH.ADDRESS_DETAILS,
      detailView: [
        {
          label: "Address",
          label_value:
            proposalInformation.addressDetails.propertyAddress.address.value,
        },
        {
          label: "City",
          label_value:
            proposalInformation.addressDetails.propertyAddress.city.value,
        },
        {
          label: "Pincode",
          label_value:
            proposalInformation.addressDetails.propertyAddress.pincode.value,
        },
      ],
    },
    {
      title: "In-Built Covers",
      editLink: HI_PATH.IN_BUILT_COVERAGES,
      detailView: [
        {
          label: "Loss of Rent",
          label_value: proposalInformation.inbuiltCoverages.lossOfRentSelcted
            ? "Yes"
            : "No",
        },
        // {
        //   label: "No of Months",
        //   label_value: proposalInformation.inbuiltCoverages.num_months_rent,
        // },
        ...(proposalInformation.inbuiltCoverages.num_months_rent !== ""
          ? [
              {
                label: "No of Months",
                label_value:
                  proposalInformation.inbuiltCoverages.num_months_rent,
              },
            ]
          : []),
        {
          label: "Loss of Rent Alternative",
          label_value: proposalInformation.inbuiltCoverages.rentAltSelected
            ? "Yes"
            : "No",
        },
        // {
        //   label: "No of Months for Alternative",
        //   label_value: proposalInformation.inbuiltCoverages.num_months_alt,
        // },
        ...(proposalInformation.inbuiltCoverages.num_months_alt !== ""
          ? [
              {
                label: "No of Months for Alternative",
                label_value:
                  proposalInformation.inbuiltCoverages.num_months_alt,
              },
            ]
          : []),
        {
          label: "Cover for Valuable Contents",
          label_value: proposalInformation.inbuiltCoverages
            .isCoverForValuableContent
            ? "Yes"
            : "No",
        },
      ],
    },
    {
      title: "Addon Covers",
      editLink: HI_PATH.ADD_ONS,
      detailView: [
        {
          label: "Personal Accident Cover",
          label_value: proposalInformation.addonCoverages.isPac ? "Yes" : "No",
        },
        // {
        //   label: "Personal Accident Cover for Spouse",
        //   label_value: proposalInformation.addonCoverages.isPacSpouse
        //     ? "Yes"
        //     : "No",
        // },
        {
          label: "EarthQuake Cover",
          label_value: proposalInformation.addonCoverages.isEQcover
            ? "Yes"
            : "No",
        },
      ],
    },
    {
      title: "Property Details",
      editLink: HI_PATH.PROPERTY_DETAILS,
      detailView: [
        {
          label: "Year of Construction",
          label_value:
            proposalInformation.propertyDetails.constructionYear.value,
        },
        {
          label: "Type of Building",
          label_value: proposalInformation.propertyDetails.typeOfBuilding.value,
        },
        // {
        //   label: "Floor Number",
        //   label_value: proposalInformation.propertyDetails.floorNo?.value
        //     ? proposalInformation.propertyDetails.floorNo?.value
        //     : "",
        // },
        ...(proposalInformation.propertyDetails.typeOfBuilding.value !==
        "Standalone House"
          ? [
              {
                label: "Floor Number",
                label_value: proposalInformation.propertyDetails.floorNo?.value
                  ? proposalInformation.propertyDetails.floorNo?.value
                  : "",
              },
            ]
          : []),
      ],
    },
  ]);

  // const handleMakePaymentClicked = () => {
  //   // console.log("inside handleMakePaymentClicked");
  //   setLoad(true);
  //   const loadingToast = toast.loading("Loading...");

  //   const onSuccess = (res: any) => {
  //     // window.location.replace(res.data.data);
  //     setLoad(false);
  //     window.location.href = res.data.data;

  //     // toast.dismiss(loadingToast);
  //   };

  //   const onError = (err: any) => {
  //     setLoad(false);
  //     toast.dismiss(loadingToast);
  //     toast.error("Failed!");
  //   };

  //   let redirectionURL = "";
  //   let cancelURL = "";

  //   if (typeof window !== "undefined") {
  //     redirectionURL =
  //       window.location.protocol +
  //       "//" +
  //       window.location.host +
  //       HI_PATH_POLICY_CONFIRM;

  //     cancelURL =
  //       window.location.protocol +
  //       "//" +
  //       window.location.host +
  //       "/" +
  //       quote.quote_id; // (or whatever)
  //   }

  //   const dto: {
  //     correlationId: string;
  //     quote_no: string;
  //     redirectionURL: string;
  //     cancelURL: string;
  //     finalPremium: string;
  //     loanAccountNumber: string;
  //     product_id: string;
  //   } = {
  //     correlationId: proposalInformation.policy_information.premiumDetails
  //       .correlationId
  //       ? proposalInformation.policy_information.premiumDetails.correlationId
  //       : "",
  //     quote_no: quote.quote_id,
  //     redirectionURL: URL_CONSTANTS.icici_payment_verify(
  //       quote.quote_id,
  //       window.location.protocol +
  //         "//" +
  //         window.location.host +
  //         HI_PATH_POLICY_CONFIRM +
  //         quote.quote_id
  //     ),
  //     cancelURL,
  //     finalPremium:
  //       proposalInformation.policy_information.premiumDetails.grossPremium,
  //     loanAccountNumber: generateRandomString(),
  //     product_id: proposalInformation.policy_information.productDetails.id,
  //   };

  //   QUOTES_SERVICES.icici_create_quote(dto, onSuccess, onError);
  // };

  // const HI_PATH_POLICY_CONFIRM = "/policy-confirm"; // Ensure this is correctly defined

  // const handleMakePaymentClicked = () => {
  //   setLoad(true);
  //   const loadingToast = toast.loading("Loading...");

  //   const onSuccess = (res: any) => {
  //     setLoad(false);
  //     window.location.href = res.data.data;
  //   };

  //   const onError = (err: any) => {
  //     setLoad(false);
  //     toast.dismiss(loadingToast);
  //     toast.error("Failed!");
  //   };

  //   let redirectionURL = "";
  //   let cancelURL = "";

  //   if (typeof window !== "undefined") {
  //     const baseUrl = `${window.location.protocol}//${window.location.host}`;
  //     console.log("baseUrl:", baseUrl);
  //     redirectionURL = `${baseUrl}${HI_PATH_POLICY_CONFIRM}?insurer=ICICI&message=fail&quote_no=${quote.quote_id}`;
  //     console.log("redirectionURL:", redirectionURL);
  //     cancelURL = `${baseUrl}/${quote.quote_id}`;
  //   }

  //   const encodedRedirectionURL = encodeURIComponent(redirectionURL);
  //   console.log("encodedRedirectionURL====>", encodedRedirectionURL);
  //   const finalRedirectionURL = URL_CONSTANTS.icici_payment_verify(
  //     quote.quote_id,
  //     encodedRedirectionURL
  //   );

  //   console.log("finalRedirectionURL:", finalRedirectionURL);

  //   const dto = {
  //     correlationId:
  //       proposalInformation.policy_information.premiumDetails.correlationId ||
  //       "",
  //     quote_no: quote.quote_id,
  //     redirectionURL: finalRedirectionURL,
  //     cancelURL,
  //     finalPremium:
  //       proposalInformation.policy_information.premiumDetails.grossPremium,
  //     loanAccountNumber: generateRandomString(),
  //     product_id: proposalInformation.policy_information.productDetails.id,
  //   };

  //   QUOTES_SERVICES.icici_create_quote(dto, onSuccess, onError);
  // };

  const handleMakePaymentClicked = () => {
    setLoad(true);
    const loadingToast = toast.loading("Loading...");

    const onSuccess = (res: any) => {
      setLoad(false);
      window.location.href = res.data.data;
    };

    const onError = (err: any) => {
      setLoad(false);
      toast.dismiss(loadingToast);
      toast.error("Failed!");
    };

    let redirectionURL = "";
    let cancelURL = "";

    if (typeof window !== "undefined") {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      console.log("baseUrl:", baseUrl);
      const insurer = encodeURIComponent("ICICI");
      const message = encodeURIComponent("fail");
      const quote_no = encodeURIComponent(quote.quote_id);
      // redirectionURL = `${baseUrl}${HI_PATH_POLICY_CONFIRM}?insurer=${insurer}&message=${message}&quote_no=${quote_no}`;
      redirectionURL = `${baseUrl}${HI_PATH_POLICY_CONFIRM}`;
      console.log("redirectionURL:", redirectionURL);
      cancelURL = `${baseUrl}/${quote.quote_id}`;
    }

    const encodedRedirectionURL = encodeURIComponent(redirectionURL);
    console.log("encodedRedirectionURL====>", encodedRedirectionURL);
    const finalRedirectionURL = URL_CONSTANTS.icici_payment_verify(
      quote.quote_id,
      encodedRedirectionURL
    );

    console.log("finalRedirectionURL:", finalRedirectionURL);

    const dto = {
      correlationId:
        proposalInformation.policy_information.premiumDetails.correlationId ||
        "",
      quote_no: quote.quote_id,
      redirectionURL: finalRedirectionURL,
      cancelURL,
      finalPremium:
        proposalInformation.policy_information.premiumDetails.grossPremium,
      loanAccountNumber: generateRandomString(),
      product_id: proposalInformation.policy_information.productDetails.id,
    };
    // debugger;

    QUOTES_SERVICES.icici_create_quote(dto, onSuccess, onError);
  };

  return (
    <Box className="proposalPreview">
      <h5 className="sectionTitle">Just make sure, all details are correct</h5>
      <Masonry columns={2} spacing={3} className="px-0 mx-0">
        {/* Personal, Address Details Box */}
        {previewDetails.map((data, index) => {
          let baseUrl = HI_PATH.Proposal + "/" + quoteState.quote_id + "/";

          return (
            // <PreviewDetailBox
            //   title={data.title}
            //   data={data.detailView}
            //   editLink={data.editLink}

            // />
            <>
              {isEmpty(data.title) ? null : data.title === "Address Details" ? (
                <AddressPreviewDetailBox load={load} />
              ) : (
                <PreviewDetailBox
                  title={data.title}
                  data={data.detailView}
                  editLink={!load ? data.editLink : ""}
                  isCommunicationSameAsPermanent={
                    proposalInformation.addressDetails.isSame
                  }
                />
              )}
            </>
          );
        })}
      </Masonry>

      <Footer continue_function={handleMakePaymentClicked} loader={load} />
    </Box>
  );
};

export default ReviewDetails;
