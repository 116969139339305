import { Box, Button, CircularProgress, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../../../../Components/Desktop/Buttons/Buttons.scss";
import Navbar from "../../../../../../Components/Desktop/ProposalComponents/Navbar/Navbar";
import { POLICY_SERVICES } from "../../../../../../Services/PolicyService";
import "./PolicyConfirm.scss";
import { isEmpty } from "../../../../../../SupportingFiles/HelpingFunction";
import { useDispatch } from "react-redux";
import { resetProposalState } from "../../../../../../State/Slices/Proposal/ProposalSlice";
import { resetKYCResponseState } from "../../../../../../State/Slices/KycResponse/KycResponseSlice";
import { resetHouseHoldState } from "../../../../../../State/Slices/HouseholdDetails/HouseholdDetailSlice";

function PolicyConfirm() {
  // const { quoteId } = useParams();
  // const param = useParams();
  // console.log("quoteId========>", quoteId);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [pdfLink, setPdfLink] = useState<{
    kycVerificationStatus: string;
    policyNumber: string;
    policyStatus: string;
    paymentStatus: string;
    link: string;
    pdfLink: string;
    message?: string;
  }>({
    kycVerificationStatus: "",
    policyNumber: "",
    policyStatus: "",
    paymentStatus: "",
    link: "",
    pdfLink: "",
    message: "",
  });
  const navigate = useNavigate();
  const [msg, setMsg] = useState<string>("");
  useEffect(() => {
    const policyNo = new URLSearchParams(window.location.search).get(
      "policyNo"
    );

    const insurer = new URLSearchParams(window.location.search).get("insurer");
    const quoteId = new URLSearchParams(window.location.search).get("quoteId");
    console.log("vvvvvvvvvv===>", quoteId, typeof insurer);
    // debugger;
    // if (policyNo) {
    if (insurer == "ICICI") {
      if (quoteId) {
        console.log("vvvvvvvvvv===>", policyNo, insurer);
        getPolicyPdf_icici(`${policyNo}`, quoteId);
      }
    } else {
      getPolicyPdf(`${policyNo}`);
    }
    // } else {
    //   setLoading(false);
    // }

    console.log("Policy No.", policyNo);
  }, [window.location.search]);
  // console.log("xxxxxxxxxxxx======>", pdfLink);
  const getPolicyPdf_icici = (policyNo: string, quoteNo: string) => {
    const onSuccess = (res: any) => {
      console.log("getPolicy pdf icici", res);
      // if (res.data.message) {
      //   setMsg(res.data.message);
      // }
      setLoading(false);

      // setPdfLink(res?.data?.quoteLists);
      !isEmpty(pdfLink.link)
        ? setPdfLink(res?.data?.quoteLists)
        : setPdfLink({
            ...res?.data?.quoteLists,
            message: res.data.message || "",
          });
    };

    const onError = (err: any) => {
      setLoading(false);
      console.log(err);
    };
    setLoading(true);
    POLICY_SERVICES.icici_policy_pdf(onSuccess, onError, quoteNo, policyNo);
  };

  const getPolicyPdf = (policyNo: string) => {
    const onSuccess = (res: any) => {
      setLoading(false);
      // debugger;
      setPdfLink(res.data.quoteLists);
    };

    const onError = (err: any) => {
      setLoading(false);
    };

    POLICY_SERVICES.get_payment_status(onSuccess, onError, policyNo);
  };
  // console.log("msgggg====>", msg);
  // console.log("pdfffff====>", pdfLink);
  const homeHandler = () => {
    window.location.href = "https://dev.masuraksha.com/";
    dispatch(resetProposalState({}));
    dispatch(resetKYCResponseState({}));
    dispatch(resetHouseHoldState({}));
  };
  return (
    // <div>
    //   <Box className="policyConfirmWrapper">
    //     <Navbar />

    //     <Grid container spacing={3}>
    //       <Grid xs={12} className="my-6" textAlign="center">
    //         <img src="./images/policy-confirmation.svg" alt="" />
    //       </Grid>

    //       <Grid xs={12} textAlign="center">
    //         {/* <h3>Dear Rahul,</h3> */}
    //         <p className="mb-8">
    //           <span>GREAT!!</span> Congratulations on buying your Home
    //           Insurance.
    //           <br />
    //           Thank you for choosing us.
    //         </p>
    //         <p className="dwnld_p mb-8">
    //           You can{" "}
    //           {loading ? (
    //             "Loading..."
    //           ) : (
    //             <a href={pdfLink} download>
    //               Download
    //             </a>
    //           )}{" "}
    //           and manage your policy documents.
    //         </p>
    //       </Grid>

    //       <Grid xs={12} textAlign="center">
    //         <div className="ctaBtn">
    //           <Button className="regularSecondaryBtn">My Account</Button>
    //         </div>
    //       </Grid>
    //     </Grid>
    //   </Box>
    // </div>
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
            height: "100vh",
            width: "100%",
          }}
        >
          <CircularProgress />
          <p>Please wait...</p>
        </Box>
      ) : pdfLink.paymentStatus === "NOT_PAID" ? (
        <div>
          <Box className="policyConfirmWrapper">
            <Navbar />
            <Grid container columnSpacing={3}>
              <Grid xs={12}>
                <Box className="pc-innerwrapper">
                  <Grid container columnSpacing={0}>
                    <Grid xs={12} textAlign="center" marginBottom={"20px"}>
                      <img src="../images/oops-error.svg" alt="" />
                    </Grid>
                    <Grid xs={12} textAlign="center">
                      <h3 className="mb-4">
                        Payment{" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {pdfLink?.paymentStatus}
                        </span>
                      </h3>
                    </Grid>

                    <Grid xs={12} textAlign="center">
                      <div className="ctaBtn">
                        {/* <button
                        text_name={"Try Again"}
                        class_name="greenXlBtn"
                      /> */}
                        <Button className="regularSecondaryBtn">
                          Try Again
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
      ) : (
        <div>
          <Box className="policyConfirmWrapper">
            <Navbar />
            <Grid container columnSpacing={3}>
              <Grid xs={12}>
                <Box className="pc-innerwrapper">
                  <Grid container columnSpacing={0}>
                    <Grid xs={12} textAlign="center" marginBottom={"20px"}>
                      {(pdfLink.paymentStatus as string) === "PAID" ? (
                        <img src="../images/policy-confirmation.svg" alt="" />
                      ) : (
                        <img src="../images/oops-error.svg" alt="" />
                      )}
                    </Grid>
                    <Grid xs={12} textAlign="center">
                      <h3 className="mb-4">
                        {/* Dear {pdfLink.customer_name} */}
                      </h3>
                      {(pdfLink.paymentStatus as string) === "PAID" ? (
                        <p className="mb-5">
                          <span>GREAT !!</span> Congratulations on buying your
                          Vehicle Insurance Policy.
                          <br />
                          Thank you for choosing us
                        </p>
                      ) : (
                        <p className="mb-5">
                          {/* Your policy is on hold due to KYC verification failed.
                          Once KYC number is generated your policy will be
                          issued. */}
                          {pdfLink.message}
                        </p>
                      )}

                      {!isEmpty(pdfLink.policyNumber) ? (
                        <p className="dwnld_p mb-8">
                          Policy No: {pdfLink.policyNumber}
                        </p>
                      ) : null}
                      {/* {pdfLink.message !== "success" ? // <>
                      //   <p className="dwnld_p mb-8">
                      //     Please click{" "}
                      //     <span
                      //       style={{
                      //         textDecoration: "underline",
                      //         color: "#E6253C",
                      //       }}
                      //       onClick={() =>
                      //         window.open(pdfLink.pdfLink, "_blank")
                      //       }
                      //     >
                      //       here
                      //     </span>{" "}
                      //     to generate KYC number
                      //   </p>
                      // </>
                      null : !isEmpty(pdfLink.link) ? (
                        <p className="dwnld_p mb-8">
                          You can{" "}
                          <Link
                            onClick={() => window.open(pdfLink.link, "_blank")}
                          >
                            Download
                          </Link>{" "}
                          and manage your policy documents.
                        </p>
                      ) : isEmpty(pdfLink.pdfLink) &&
                        !pdfLink.link.includes("https") ? (
                        <p className="dwnld_p mb-8">
                          PDF will generate after some time or try after some
                          time
                        </p>
                      ) : (
                        <p className="dwnld_p mb-8">
                          You can{" "}
                          <Link
                            onClick={() =>
                              window.open(pdfLink.pdfLink, "_blank")
                            }
                          >
                            Download
                          </Link>{" "}
                          and manage your policy documents.
                        </p>
                      )} */}
                      {pdfLink.policyStatus === "EFFECTIVE" &&
                      pdfLink.kycVerificationStatus === "DONE" ? (
                        isEmpty(pdfLink.pdfLink) ? (
                          <p className="dwnld_p mb-8">
                            PDF will generate after some time or try after some
                            time
                          </p>
                        ) : (
                          <p className="dwnld_p mb-8">
                            You can{" "}
                            <Link
                              onClick={() =>
                                window.open(pdfLink.pdfLink, "_blank")
                              }
                            >
                              Download
                            </Link>{" "}
                            and manage your policy documents.
                          </p>
                        )
                      ) : (
                        <p className="dwnld_p mb-8">
                          Please click{" "}
                          <span
                            style={{
                              textDecoration: "underline",
                              color: "#E6253C",
                            }}
                            onClick={() => window.open(pdfLink.link, "_blank")}
                          >
                            here
                          </span>{" "}
                          to generate KYC number
                        </p>
                      )}
                    </Grid>
                    <Grid xs={12} textAlign="center">
                      <div className="ctaBtn">
                        {/* <CustomButton
                onClickFunction={() => {
                  window.location.href = "https://devev.jioinsure.in";
                }}
                text_name={"Go to Home"}
                class_name="greenXlBtn"
              /> */}
                        <Button
                          className="regularSecondaryBtn"
                          // onClick={() =>
                          //   (window.location.href =
                          //     "https://dev.masuraksha.com/")
                          // }
                          onClick={homeHandler}
                        >
                          Go to Home
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </div>
        // <div>
        //   <Box className="policyConfirmWrapper">
        //     <Navbar />
        //     <Grid container columnSpacing={3}>
        //       <Grid xs={12}>
        //         <Box className="pc-innerwrapper">
        //           <Grid container columnSpacing={0}>
        //             <Grid xs={12} textAlign="center" marginBottom={"20px"}>
        //               <img src="../images/policy-confirmation.svg" alt="" />
        //             </Grid>
        //             <Grid xs={12} textAlign="center">
        //               <h3 className="mb-4">
        //                 {/* Dear {pdfLink.customer_name} */}
        //               </h3>
        //               {(pdfLink.paymentStatus as string) === "PAID" ? (
        //                 <p className="mb-5">
        //                   <span>GREAT !!</span> Congratulations on buying your
        //                   Vehicle Insurance Policy.
        //                   <br />
        //                   Thank you for choosing us
        //                 </p>
        //               ) : (
        //                 <p className="mb-5">
        //                   Your policy is on hold due to KYC verification failed.
        //                   Once KYC number is generated your policy will be
        //                   issued.
        //                 </p>
        //               )}

        //               <p className="dwnld_p mb-8">
        //                 Policy No: {pdfLink.policyNumber}
        //               </p>
        //               {!isEmpty(pdfLink.link) ? (
        //                 <p className="dwnld_p mb-8">
        //                   Please click{" "}
        //                   <span
        //                     style={{
        //                       textDecoration: "underline",
        //                       color: "#E6253C",
        //                     }}
        //                     onClick={() => window.open(pdfLink.link, "_blank")}
        //                   >
        //                     here
        //                   </span>{" "}
        //                   to generate KYC number
        //                 </p>
        //               ) : isEmpty(pdfLink.pdfLink) &&
        //                 !pdfLink.pdfLink.includes("https") ? (
        //                 <p className="dwnld_p mb-8">
        //                   PDF will generate after some time or try after some
        //                   time
        //                 </p>
        //               ) : (
        //                 <p className="dwnld_p mb-8">
        //                   You can{" "}
        //                   <Link
        //                     onClick={() =>
        //                       window.open(pdfLink.pdfLink, "_blank")
        //                     }
        //                   >
        //                     Download
        //                   </Link>{" "}
        //                   and manage your policy documents.
        //                 </p>
        //               )}
        //             </Grid>
        //             <Grid xs={12} textAlign="center">
        //               <div className="ctaBtn">
        //                 {/* <CustomButton
        //                 onClickFunction={() => {
        //                   window.location.href = "https://devev.jioinsure.in";
        //                 }}
        //                 text_name={"Go to Home"}
        //                 class_name="greenXlBtn"
        //               /> */}
        //                 <Button
        //                   className="regularSecondaryBtn"
        //                   onClick={() =>
        //                     (window.location.href =
        //                       "https://dev.masuraksha.com/")
        //                   }
        //                 >
        //                   Go to Home
        //                 </Button>
        //               </div>
        //             </Grid>
        //           </Grid>
        //         </Box>
        //       </Grid>
        //     </Grid>
        //   </Box>
        // </div>
      )}
    </>
  );
}

export default PolicyConfirm;
