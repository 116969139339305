import { default as moment } from "moment";

const formats = [
  "YYYY-MM-DD",
  "MM-DD-YYYY",
  "DD-MM-YYYY",
  "YYYY/MM/DD",
  "MM/DD/YYYY",
  "DD/MM/YYYY",
  "MMM DD, YYYY",
  "MMMM DD, YYYY",
  "MMM DD YYYY",
  "MMMM DD YYYY",
  "DD-M-YYYY",
  "YYYY-MM-DD HH:MM:SS",
  "ddd MMM DD YYYY HH:mm:ss GMTZZZZ (Z)",
];

export function validateEmail(email: string) {
  var re = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z-])+\.)+([a-zA-Z]{2,4})+$/;
  return re.test(email);
}
export const removeNamePrefix = (name: string) => {
  // Define a list of prefixes to remove
  const prefixes = ["Mr", "Mrs"];

  // Iterate through prefixes and remove them if found at the beginning of the name
  for (const prefix of prefixes) {
    if (name.startsWith(prefix)) {
      // Remove the prefix and any following whitespace
      return name.slice(prefix.length).trim();
    }
  }

  // If no prefix found, return the original name
  return name;
};

export function titleCase(str: string) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

export const isEmpty = (value: string | Date | null | undefined | number) => {
  return (
    value === null ||
    value === undefined ||
    `${value}`.trim().length === 0 ||
    value == ""
  );
};

export function validatePincode(pincode: string) {
  if (`${pincode}` == "111111" || `${pincode}` == "000000") {
    return false;
  } else if (pincode != undefined) {
    if (`${pincode}`.length == 6) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function formatNumberToLakhOrCrores(value: string) {
  const number = Number(value);

  if (number >= 10000000) {
    const formatted = (number / 10000000).toFixed(2);
    return formatted.endsWith(".00")
      ? formatted.slice(0, -3) === "1"
        ? "₹ 1 Crore"
        : `₹ ${formatted.slice(0, -3)} Crores`
      : formatted === "1"
      ? "₹ 1 Crore"
      : `₹ ${formatted} Crores`;
  } else if (number >= 100000) {
    const formatted = (number / 100000).toFixed(2);

    return formatted.endsWith(".00")
      ? formatted.slice(0, -3) === "1"
        ? "₹ 1 Lakh"
        : `₹ ${formatted.slice(0, -3)} Lakhs`
      : formatted === "1"
      ? "₹ 1 Lakh"
      : `₹ ${formatted} Lakhs`;
  } else {
    return "₹ " + number.toString();
  }
}

export function validateRegno(reg_no: string) {
  var re = /^[A-Z]{2,2}[0-9]{2}[A-Z]{0,3}[0-9]{4,4}$/;
  var re_dl = /^[A-Z]{2,2}[0-9]{1,2}[A-Z]{0,3}[0-9]{4,4}$/;
  if (reg_no.substring(0, 2) == "DL") {
    return re_dl.test(reg_no);
  }
  return re.test(reg_no);
}

export function validateFullName(full_name: string) {
  if (full_name != undefined) {
    var name = full_name.toString().trim();
    if (name.includes(" ")) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function validateMobileNumber(mobile_no: string) {
  if (mobile_no != undefined && mobile_no != null) {
    var mobile = mobile_no.toString().trim();
    if (
      mobile.toString().length == 10 &&
      parseInt(mobile.substring(0, 1)) >= 6
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

// validate url
export function validateUrl(value: string) {
  var re = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z-])+\.)+([a-zA-Z]{2,4})+$/;
  return re.test(value);
}

export function formatIndianCurrency(value: number) {
  return new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 10,
  }).format(value);
}

export function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function extractNumbersFromString(str: string) {
  return str.replace(/[^0-9]/g, "");
}

export function isValid_Driving_License_Number(license_Number: string) {
  // Regex to check valid
  // license_Number
  let regex = new RegExp(
    /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$/
  );

  // if license_Number
  // is empty return false
  if (license_Number == null) {
    return false;
  }

  // Return true if the license_Number
  // matched the ReGex
  if (regex.test(license_Number) == true) {
    return true;
  } else {
    return false;
  }
}

export function isValid_Pan_Number(pan_num: string) {
  let regex = new RegExp(/^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/);

  if (pan_num == null) {
    return false;
  }

  if (regex.test(pan_num) == true) {
    return true;
  } else {
    return false;
  }
}

export function isValid_Aadhar(aadhar: string) {
  let regex = new RegExp(
    /(^[0-9]{4}[0-9]{4}[0-9]{4}$)|(^[0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|(^[0-9]{4}-[0-9]{4}-[0-9]{4}$)/
  );

  if (aadhar == null) {
    return false;
  }

  if (regex.test(aadhar) == true) {
    return true;
  } else {
    return false;
  }
}
export const formatNumberWithCurrencySymbol = (
  value: number | string
): string => {
  // Convert the input to a number if it's a string
  let numericValue: number;

  if (typeof value === "string") {
    // Remove leading and trailing whitespace
    const trimmedValue = value.trim();

    // Check if the trimmed value is empty
    if (trimmedValue === "") {
      return "Empty Value";
    }

    numericValue = parseFloat(trimmedValue);

    // Check if parsing failed
    if (isNaN(numericValue)) {
      return "Invalid Number";
    }
  } else if (typeof value === "number") {
    numericValue = value;
  } else {
    return "Invalid Type"; // Handle other data types
  }

  const nf = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formattedValue = nf.format(numericValue);

  // Add a space after the currency symbol
  return formattedValue.replace("₹", "₹ ");
};

export function isValid_Passport_num(pass_num: string) {
  let regex = new RegExp(/^[A-PR-WYa-pr-wy][1-9]\d\s?\d{4}[1-9]$/);

  if (pass_num == null) {
    return false;
  }

  if (regex.test(pass_num) == true) {
    return true;
  } else {
    return false;
  }
}

export function uploadImage(file: any, callback: any) {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    const base64String = reader.result as string;
    // var base64Image = base64String.replace(
    //   /^data:(image\/[a-z]+|application\/pdf);base64,/,
    //   ""
    // );
    callback(base64String);
  };
}

export function hasEmptyProperty(obj: any): boolean {
  return Object.values(obj).some((value) => {
    if (typeof value === "string") {
      return value.trim() === "";
    } else if (typeof value === "number") {
      return value === 0;
    }
    return false;
  });
}

export function objectHasWarning(obj: any): boolean {
  let warn = false;

  const arr = Object.keys(obj);

  for (let i = 0; i < arr.length; i++) {
    const keyToUse = arr[i];
    console.log("warn  object==>", obj[keyToUse].warning);
    if (obj[keyToUse].warning) {
      warn = true;
      break;
    }
  }

  return warn;
}
export function objectHasWarningv2(obj: any): any[] {
  let warn = false;

  const arr = Object.keys(obj);

  for (let i = 0; i < arr.length; i++) {
    const keyToUse = arr[i];
    if (obj[keyToUse].value == "") {
      if (keyToUse !== "nomineeAppointeeName") {
        warn = true;
        obj[keyToUse].warning = true;
      } else if (
        keyToUse === "nomineeAppointeeName" &&
        parseInt(obj["nomineeAge"].value) < 18
      ) {
        warn = true;
        obj[keyToUse].warning = true;
      }
    }

    // if (obj[keyToUse].value > 18) {
    //   console.log("kkjkkj");
    //   obj[keyToUse].warning = false;
    // }
    // if (obj[keyToUse].warning) {
    //   warn = true;
    //   break;
    // }
  }

  return [warn, structuredClone(obj)];
}
// export function objectHasWarningv3(obj: any): any[] {
//   let warn = false;

//   const arr = Object.keys(obj);

//   for (let i = 0; i < arr.length; i++) {
//     const keyToUse = arr[i];
//     if (obj[keyToUse].value == "") {
//       if (keyToUse !== "nomineeAppointeeName") {
//         warn = true;
//         obj[keyToUse].warning = true;
//       } else if (
//         keyToUse === "nomineeAppointeeName" &&
//         parseInt(obj["nomineeAge"].value) < 18
//       ) {
//         console.log("inside age check");
//         warn = true;
//         obj[keyToUse].warning = true;
//       }
//     }
//     // Set warning to false for nomineeGender if it is an empty string
//     // if (keyToUse === "nomineeGender" && obj[keyToUse].value === "") {
//     //   obj[keyToUse].warning = false;
//     // }
//   }
//   console.log("warn v233", warn);
//   console.log("structuredClone(obj) v233", structuredClone(obj));
//   return [warn, structuredClone(obj)];
// }
export function objectHasWarningv3(obj: any): any[] {
  let warn = false;

  const arr = Object.keys(obj);

  for (let i = 0; i < arr.length; i++) {
    const keyToUse = arr[i];
    if (obj[keyToUse].value === "") {
      if (
        keyToUse !== "nomineeAppointeeName" &&
        (keyToUse as string) !== "nomineeGender"
      ) {
        warn = true;
        obj[keyToUse].warning = true;
      } else if (
        keyToUse === "nomineeAppointeeName" &&
        parseInt(obj["nomineeAge"].value) <= 18
      ) {
        warn = true;
        obj[keyToUse].warning = true;
      }
    }

    if (keyToUse === "nomineeGender" && obj[keyToUse].value === "") {
      warn = false;
      obj[keyToUse].warning = false;
    }
  }

  return [warn, structuredClone(obj)];
}

export function calculatePercentageOfNum(
  precentage: number,
  number: number
): number {
  return (precentage / 100) * number;
}

export function generateRandomString() {
  return Math.random().toString(36).slice(2);
}

export function validatePAN(panNumber: any): boolean {
  const panRegex = /^[A-Z]{5,5}[0-9]{4,4}[A-Z]{1,1}$/;
  return panRegex.test(panNumber);
}

export function FORMAT_DD_MM_YYYY(value: string) {
  const outputFormat = "DD-MM-YYYY";

  if (moment(value, outputFormat, true).isValid()) {
    return value;
  }
  let formattedDate = moment(value).format(outputFormat);

  if (moment(formattedDate, outputFormat, true).isValid()) {
    return formattedDate;
  } else {
    return "";
  }
}

export function FORMAT_YYYY_MM_DD(value: string) {
  let formattedDate = "";

  for (const format of formats) {
    const date = moment(value, format, true);
    if (date.isValid()) {
      formattedDate = date.format("YYYY-MM-DD");
      break;
    }
  }

  if (formattedDate === "") {
    return value; // Return original value if no valid format is found
  }

  return formattedDate;
}
