import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import { Box, Button, Grid, Link, Modal } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../State/Hooks";
import { kycResponseSlice } from "../../../../State/Slices/KycResponse/KycResponseSlice";
import { RootState } from "../../../../State/Store";
import { TKycResponse } from "../../../../State/Types/TKycResponse";
import "../../../../Shared/ProposalPage.scss";
import { HI_PATH } from "../../../../Path/HI/HIPath";
import { ECompanyCode } from "../../../../State/Enum/ECompanyCode";
import {
  FORMAT_DD_MM_YYYY,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";

interface Props {
  modalOpen: boolean;
  setModalOpen: any;
  pageStatus?: number;
  setPageStatus?: any;
  subRoutes?: Array<string>;
}

const KycSuccessPopup = ({
  modalOpen,
  setModalOpen,
  pageStatus,
  setPageStatus,
  subRoutes,
}: Props) => {
  const kycRespState: TKycResponse = useAppSelector(
    (state: RootState) => state.kycResponseSlice
  );
  console.log("kycRespState", kycRespState);
  const { CompanyDetails } = useAppSelector(
    (state) => state.proposalInformation.policy_information
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  function formatDateString(inputDateStr: any) {
    // Split the input date string by '/'
    const [year, month, day] = inputDateStr.split("/");

    // Return the formatted date string as "DD/MM/YYYY"
    return `${day}/${month}/${year}`;
  }
  return (
    <Modal open={modalOpen} className="modalWrapper">
      <Box className="modalInner xl-width">
        <Grid container spacing={2}>
          <Grid xs={12}>
            <h4 className="popup-heading mb-2">CKYC Details</h4>
            <p className="grey_p">
              We fetch these details as per record, please verify
            </p>
            <Link
              className="close-button"
              onClick={() => {
                setModalOpen(false);
              }}
            />
            <hr />
          </Grid>
        </Grid>
        <Grid container spacing={2} textAlign="center">
          <Grid xs={12} className="">
            <span className="policy_number_box my-4">
              {CompanyDetails.company_code === ECompanyCode.ICICI
                ? kycRespState.iciciKYCId
                : kycRespState.digitKYCId}
            </span>
          </Grid>
        </Grid>
        <Grid container spacing={2} textAlign="center">
          <Grid xs={6}>
            <h6>
              Full Name:
              <span>
                {/* {kycRespState.first_name +
                  " " +
                  (kycRespState.middle_name && kycRespState.middle_name !== null
                    ? kycRespState.middle_name
                    : "") +
                  " " +
                  kycRespState.last_name} */}
                {kycRespState.first_name +
                  " " +
                  (kycRespState.middle_name && kycRespState.middle_name !== null
                    ? kycRespState.middle_name
                    : "") +
                  " " +
                  (kycRespState.last_name && kycRespState.last_name !== null
                    ? kycRespState.last_name
                    : "")}
              </span>
            </h6>
          </Grid>
          <Grid xs={6}>
            <h6>
              DOB:
              <span>
                {/* {kycRespState.dob && format(new Date(kycRespState.dob), "PP")}{" "} */}
                {/* {kycRespState.dob_or_doi.toString()} */}
                {/* {new Date(
                  kycRespState.dob
                    ? (kycRespState.dob as any)
                    : (kycRespState.dob_or_doi as any)
                ).toLocaleDateString("en-GB")} */}
                {/* {kycRespState.digitKYCId
                  ? new Date(
                      kycRespState.dob
                        ? (kycRespState.dob as any)
                        : (kycRespState.dob_or_doi as any)
                    ).toLocaleDateString("en-US")
                  : kycRespState.dob.toString()} */}

                {/* {kycRespState.digitKYCId
                  ? FORMAT_DD_MM_YYYY(kycRespState.dob as string)
                  : ""} */}
                {/* {kycRespState.digitKYCId
                  ? (() => {
                      const dob: any = kycRespState.dob
                        ? kycRespState.dob
                        : kycRespState.dob_or_doi;
                      const formattedDate = new Date(
                        dob as any
                      ).toLocaleDateString("en-US");
                      return formattedDate;
                    })()
                  : kycRespState.dob.toString()} */}
                {kycRespState.digitKYCId
                  ? (() => {
                      const dob: any = kycRespState.dob
                        ? kycRespState.dob
                        : kycRespState.dob_or_doi;
                      const date = new Date(dob as any);
                      const month = ("0" + (date.getMonth() + 1)).slice(-2);
                      const day = ("0" + date.getDate()).slice(-2);
                      const year = date.getFullYear();
                      return `${month}/${day}/${year}`;
                    })()
                  : kycRespState.dob.toString()}
              </span>
            </h6>
          </Grid>
          {!isEmpty(kycRespState.address_line_1) ? (
            <Grid xs={12}>
              <h6>
                Address:
                <span>
                  {kycRespState.address_line_1 +
                    " " +
                    kycRespState.address_line_2 +
                    ", " +
                    kycRespState.city +
                    ", " +
                    kycRespState.state}
                </span>
              </h6>
            </Grid>
          ) : null}
          {/* <Grid xs={12}>
            <h6>
              Address:
              <span>
                {kycRespState.address_line_1 +
                  " " +
                  kycRespState.address_line_2 +
                  ", " +
                  kycRespState.city +
                  ", " +
                  kycRespState.state}
              </span>
            </h6>
          </Grid> */}
          <Grid xs={12}>
            <hr />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid xs={6} style={{ textAlign: "center" }} className="ctaBtn">
            <Button
              variant="contained"
              className="regularPrimaryBtn"
              onClick={() => {
                dispatch(kycResponseSlice.actions.reset());
                setModalOpen(false);
              }}
            >
              <SentimentDissatisfiedOutlinedIcon className="mr-2" /> It's not me
            </Button>
          </Grid>
          <Grid xs={6} style={{ textAlign: "center" }} className="ctaBtn">
            <Button
              variant="contained"
              className="regularSecondaryBtn"
              onClick={() => {
                navigate(HI_PATH.ADD_ONS);
                setModalOpen(false);
              }}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default KycSuccessPopup;
