import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "./Quote.scss";
import { Box, Button } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

function MQuoteLoading({ logoUrl }: { logoUrl: string }) {
  return (
    <>
      <Box className="quoteContainer quoteLoader">
        <Grid container spacing={2} alignItems={"center"}>
          <Grid xs={6}>
            <img
              className="insurer"
              src={
                logoUrl
                  ? logoUrl
                  : "../images/insurerlogo/kotak_general_insurance.svg"
              }
            />
            <p className="mb-1">
              <div className="load-animate w-60"></div>
            </p>
            <p>
              <div className="load-animate w-60 mb-0"></div>
            </p>
          </Grid>
          <Grid xs={6} textAlign="right">
            <p className="plan_name">
              {" "}
              <div className="load-animate w-60"></div>
            </p>
            <Button className="redBtn mt-2 mb-3">Buy Now</Button>
            <br />
            <div className="load-animate w-75"></div>
            <div className="load-animate w-50"></div>
          </Grid>
          <Grid xs={12} paddingTop={0} paddingBottom={0}>
            <hr />
          </Grid>
          <Grid xs={6}>
            <div className="load-animate w-60"></div>
            <div className="load-animate w-50"></div>
          </Grid>
          <Grid xs={6} textAlign={"right"}>
            <div className="load-animate w-60"></div>
            <div className="load-animate w-50"></div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default MQuoteLoading;
