import React from "react";
import { Box } from "@mui/system";
import Grid from "@mui/material/Unstable_Grid2";
// import "../ModalDetailsPopups.scss";
import { Button, Link, Modal, Tab } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";


interface Props {
  open_status: boolean;
  value_update: Function;
  attrName: any;
}
const HouseholdDetailPopup: React.FC<Props> = ({
  open_status,
  value_update,
  attrName,
}) => {
   const [value, setValue] = React.useState("1");
   const handleChange = (event: React.SyntheticEvent, newValue: string) => {
     setValue(newValue);
   };
  return (
    <Box>
      <Modal open={open_status} onClose={() => value_update(attrName, false)}>
        <Box className="modalDetailPopup">
          <Box className="modalDetailPopup-inner">
            <Box className="header">
              <Grid container spacing={2}>
                <Grid xs={12} display="flex" alignItems="center">
                  <h4>Policy Details</h4>
                  <Button className="redBtn mx-3">Buy</Button>
                  <Button className="redBtn">
                    <EmailIcon />
                  </Button>
                  <Link
                    className="close"
                    onClick={() => value_update(attrName, false)}
                  ></Link>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Box className="policyDetailBox">
                <Grid container spacing="3" alignItems={"center"}>
                  <Grid xs={6}>
                    <img
                      className="insurer"
                      src="../images/insurerlogo/kotak_general_insurance.svg"
                    />
                    <p>Bharat Griha Raksha</p>
                  </Grid>
                  <Grid xs={6} textAlign="right">
                    <p className="premium mb-2">
                      Premium (Incl. GST){" "}
                      <span>
                        <CurrencyRupeeIcon /> 9,123
                      </span>
                    </p>
                    <Link className="brochure">Policy Brochure</Link>
                  </Grid>
                </Grid>
              </Box>
              <Box className="modalContent">
                {/* <Grid container spacing={3}>
                  <Grid xs={12}>
                    <h4>Feature Included</h4>
                    <hr />
                    <h5>Debris Removal</h5>
                    <p className="mb-4">
                      Provides reimbursement of expenses incurred towards
                      removal of debris from insured premises Upto 2% of Claim
                      Amount
                    </p>

                    <h5>Rent for Alternate Accomodation </h5>
                    <p className="mb-4">
                      Coverage for alternate accommodation availed of by the
                      insured in the event of the insured's home is destroyed or
                      damaged
                    </p>

                    <h5>Loss of Rent </h5>
                    <p className="mb-4">
                      Provides reimbursement of rent of the while the Home
                      Building is not fit for living because of physical loss
                      arising out of an Insured Event.
                    </p>

                    <h5>Earthquake</h5>
                    <p>
                      Coverages are provided against in the cases property
                      damage in the event of earthquake.
                    </p>
                  </Grid>
                  <Grid xs={12}>
                    <h4>Feature Excluded</h4>
                    <hr />
                    <h5>Loss of Art Work</h5>
                    <p className="mb-4">
                      Antiques, paintings or work of art, unless separately
                      covered.
                    </p>

                    <h5>Seepage losses</h5>
                    <p className="mb-4">
                      Damages caused to the house due to Seepage.
                    </p>

                    <h5>Storage Loss</h5>
                    <p className="mb-4">
                      Loss or damage to stock in cold storage due to change in
                      temperature.
                    </p>

                    <h5>War</h5>
                    <p>Act of any foreign country or war</p>
                  </Grid>
                  <Grid xs={12}>
                    <hr />
                    <h5>Additional Coverage</h5>
                  </Grid>
                </Grid> */}
                <Grid container spacing={3}>
                  <Grid xs={12} className="tabs-section">
                    <TabContext value={value}>
                      <TabPanel className="tabPanel" value="1">
                        <Grid container columnSpacing={0} rowSpacing={2}>
                          <Grid xs={12}>
                            <ul>
                              <li>
                                <h4>Loss of Rent</h4>
                              </li>
                              <li>
                                <h4>Loss of Rent</h4>
                              </li>
                              <li>
                                <h4>Loss of Rent</h4>
                              </li>
                              <li>
                                <h4>Loss of Rent</h4>
                              </li>
                              <li>
                                <h4>Loss of Rent</h4>
                              </li>
                            </ul>
                          </Grid>
                        </Grid>
                      </TabPanel>
                      {/* Exclusions */}
                      <TabPanel className="tabPanel" value="2">
                        <Grid container columnSpacing={1} rowSpacing={2}>
                          <Grid xs={12}>
                            <ul>
                              <li>
                                <h4>Loss of Rent</h4>
                              </li>
                              <li>
                                <h4>Loss of Rent</h4>
                              </li>
                              <li>
                                <h4>Loss of Rent</h4>
                              </li>
                              <li>
                                <h4>Loss of Rent</h4>
                              </li>
                              <li>
                                <h4>Loss of Rent</h4>
                              </li>
                            </ul>
                          </Grid>
                        </Grid>
                      </TabPanel>

                      {/* Tabs */}
                      <Box className="tabFooter">
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Features" value="1" />
                          <Tab label="Exclusions" value="2" />
                        </TabList>
                      </Box>
                    </TabContext>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default HouseholdDetailPopup;
