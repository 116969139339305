import { BrowserRouter } from "react-router-dom";
import GO_DIGIT_HI_ROUTES from "./Routes/HI/Proposal/GoDigit/GoDigitHIRoutes";
import ICICI_HI_ROUTES from "./Routes/HI/Proposal/ICICI/ICICIHIRoutes";
import HI_QUOTE_ROUTES from "./Routes/HI/Quote/HIQuoteRoute";
import { ECompanyCode } from "./State/Enum/ECompanyCode";
import { useAppSelector } from "./State/Hooks";
import GoDigit from "./Pages/Mobile/HI/MProposalPage/GoDigit/GoDigit";
import ICICI from "./Pages/Mobile/HI/MProposalPage/ICICI/ICICI";

function App() {
  const { company_code } = useAppSelector(
    (state) => state.proposalInformation.policy_information.CompanyDetails
  );
  return (
    <BrowserRouter>
      <HI_QUOTE_ROUTES />
      {company_code === ECompanyCode.GO_DIGIT ? <GO_DIGIT_HI_ROUTES /> : null}
      {company_code === ECompanyCode.ICICI ? <ICICI_HI_ROUTES /> : null}
    </BrowserRouter>
  );
}

export default App;
