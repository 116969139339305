import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Quote from "../../../../Components/Desktop/QuotePage/HomeInsurer/HouseholdQuote";
import Navbar from "../../../../Components/Desktop/Navbar/Navbar";
import ProductInfoBar from "../../../../Components/Desktop/QuotePage/HomeInsurer/ProductInfoBar/ProductInfoBar";
import { HOUSEHOLD_DETAIL_SERVICES } from "../../../../Services/HouseholdDetailsService";
import { QUOTES_SERVICES } from "../../../../Services/QuotesService";
import { useAppDispatch, useAppSelector } from "../../../../State/Hooks";
import { householdDetailsSlice } from "../../../../State/Slices/HouseholdDetails/HouseholdDetailSlice";
import { quoteSlice } from "../../../../State/Slices/Quotes/QuoteSlice";
import { RootState } from "../../../../State/Store";
import { THouseholdDetails } from "../../../../State/Types/THouseholdDetails";
import { TQuickQuote } from "../../../../State/Types/TQuickQuote";
import { SI_TYPE } from "../../../../SupportingFiles/Enums/SI_Type";
import { formatIndianCurrency } from "../../../../SupportingFiles/HelpingFunction";
import "../../../../Shared/QuotePage.scss";

function QuotePage() {
  const quoteState = useAppSelector((state: RootState) => state.quote);
  const dispatch = useAppDispatch();
  const [quotesLoading, setQuotesLoading] = useState<boolean>(true);
  const [quickQuotes, setQuickQuotes] = useState<TQuickQuote[]>([]);
  const [filteredQuickQuotes, setFilteredQuickQuotes] = useState<TQuickQuote[]>(
    []
  );
  const [companyList, setCompanyList] = useState<
    Array<{
      company_code: string;
      logoUrl: string;
      productId: string;
      product_type_code: string;
      product_code: string;
      product_name: string;
      product_desc: string;
      sumInsured: string;
    }>
  >([
    {
      company_code: "",
      logoUrl: "",
      product_code: "",
      product_desc: "",
      product_name: "",
      product_type_code: "",
      productId: "",
      sumInsured: "",
    },
  ]);
  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);

    const id = params.get("id");
    getQuotes(id || quoteState.quote_id);
    getHouseholdDetails(id || quoteState.quote_id);

    if (id) {
      dispatch(quoteSlice.actions.setQuoteId(id));
    }
    getCompanyList();
  }, []);

  const getCompanyList = () => {
    const onSuccess = (res: any) => {
      setCompanyList(
        res.data.companyList.map((item: any) => {
          return {
            company_code: item.company_code,
            logoUrl: item.logoUrl,
            productId: item.product.id,
            product_type_code: item.product.product_type_code,
            product_code: item.product.product_code,
            product_name: item.product.product_name,
            product_desc: item.product.product_desc,
          };
        })
      );
      // setInsurers(
      //   res.data.companyList.map((item: any) => {
      //     return {
      //       check_status: false,
      //       label_name: item.product.product_desc,
      //     };
      //   })
      // );
    };

    const onError = (err: any) => {
      console.log(err);
    };

    QUOTES_SERVICES.get_company_list(onSuccess, onError);
  };

  const getQuotes = (quoteId: string) => {
    setQuotesLoading(true);

    const onSuccess = (res: any) => {
      if (res.data.quoteLists.length == 0) {
        toast.error("No Plans Found");
      }

      setQuickQuotes(res.data.quoteLists.filter((item: any) => item));
      setFilteredQuickQuotes(res.data.quoteLists.filter((item: any) => item));
      setQuotesLoading(false);
    };

    const onError = (err: any) => {
      setQuotesLoading(false);
    };

    QUOTES_SERVICES.get_quick_qoutes(onSuccess, onError, quoteId);
  };

  const getHouseholdDetails = (id: string) => {
    const onSuccess = (res: any) => {
      const data: {
        id: string;
        quote_no: string;
        module: string;
        insurance_type: string;
        sum_insured: string;
        name: string;
        email: string;
        mobile: string;
        pincode: string;
        state: string;
        city: string;
        status: string;
        source_url: string;
        utm_source: string;
        utm_medium: string;
        utm_medium_d: string;
        utm_medium_m: string;
        utm_campaign: string;
        utm_keyword: string;
        tagged_by_agent: string;
        cron_mail_status: string;
        ip_address: string;
        prop_event: string;
        owner_type: string;
        building_type: string;
        building_value: string;
        household_value: string;
        carpet_area: string;
        cost_of_construction: string;
        tenor: string;
        created: string;
        updated: string;
        insured_for: SI_TYPE;
      } = res.data.data;

      const objToSet: THouseholdDetails = {
        youAre: data.owner_type,
        youHave: data.building_type,
        buildingValue: {
          value: formatIndianCurrency(Number(data.building_value)),
          warning: false,
        },
        carpetArea: {
          value: formatIndianCurrency(Number(data.carpet_area)),
          warning: false,
        },
        costOfconstruction: {
          value: formatIndianCurrency(Number(data.cost_of_construction)),
          warning: false,
        },
        householdItems: {
          value: formatIndianCurrency(Number(data.household_value)),
          warning: false,
        },
        sumInsuredFor: data.insured_for,
        email: data.email,
        policyTerm: Number(data.tenor.split("-")[1]),
        mobile: data.mobile,
      };

      dispatch(householdDetailsSlice.actions.setHouseholdDetails(objToSet));
    };

    const onError = (err: any) => {};

    HOUSEHOLD_DETAIL_SERVICES.getHouseholdDetail(onSuccess, onError, id);
  };

  return (
    <Box className="quoteWrapper">
      <Navbar quickQuotes={quickQuotes} quotesLoading={quotesLoading} />
      <ProductInfoBar
        refetch_quotes={getQuotes}
        quotesLoading={quotesLoading}
      />
      <Grid container spacing={3} justifyContent={"center"}>
        <Grid xs={12} lg={9}>
          {!quotesLoading ? (
            <Box className="quoteInfo">
              {quickQuotes.length > 0 && (
                <>
                  <h4>We found {quickQuotes.length} plans for you.</h4>
                  <p>Prices shown are inclusive of GST.</p>
                </>
              )}
            </Box>
          ) : (
            <Box className="quoteInfo">
              <h4>Fetching Quotes...</h4>
            </Box>
          )}

          <Quote
            quickQuotes={filteredQuickQuotes}
            loading={quotesLoading}
            companyList={companyList}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default QuotePage;
