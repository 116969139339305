import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "./Quote.scss";
import { Box, Button } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

function QuoteLoading({ logoUrl }: { logoUrl: string }) {
  return (
    <Box className="quoteContainer_d quoteLoader">
      <Grid container rowSpacing={2} columnSpacing={3} alignItems={"center"}>
        <Grid xs={2}>
          <img
            className="insurer"
            src={
              logoUrl
                ? logoUrl
                : "../images/insurerlogo/kotak_general_insurance.svg"
            }
          />
        </Grid>
        <Grid xs={2}>
          <div className="load-animate w-60"></div>
          <div className="load-animate w-50"></div>
        </Grid>
        <Grid xs={2}>
          <div className="load-animate w-60"></div>
          <div className="load-animate w-50"></div>
        </Grid>
        <Grid xs={2}>
          <div className="load-animate w-60"></div>
          <div className="load-animate w-50"></div>
        </Grid>
        <Grid xs={2}>
          <div className="load-animate w-60"></div>
          <div className="load-animate w-50"></div>
        </Grid>
        <Grid xs={2} textAlign="right">
          <div className="load-animate w-80"></div>
          <Button className="redBtn mb-3 mt-2">Buy Now</Button>
          <br />
          <div className="load-animate w-40"></div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default QuoteLoading;
