import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import toast from "react-hot-toast";
import { EMAIL_QUOTES_DTO } from "../../../Services/DTO/QuotesDTO";
import { QUOTES_SERVICES } from "../../../Services/QuotesService";
import { useAppSelector } from "../../../State/Hooks";
import { RootState } from "../../../State/Store";
import { TQuickQuote } from "../../../State/Types/TQuickQuote";
import {
  extractNumbersFromString,
  formatIndianCurrency,
} from "../../../SupportingFiles/HelpingFunction";
import "./Navbar.scss";
import { useNavigate } from "react-router-dom";
import EmailPopup from "../EmailPopup/EmailPopup";
import { useState } from "react";

interface Props {
  quickQuotes: Array<TQuickQuote>;
  quotesLoading: boolean;
}

function Navbar({ quickQuotes, quotesLoading }: Props) {
  const state: RootState = useAppSelector((state) => state);
  console.log("quickQuotes", quickQuotes);
  const handleEmailQuotes = () => {
    const onSuccess = (res: any) => {
      toast.success("Successfully Emailed you the quotes");
    };

    const onError = (err: any) => {};

    const data: EMAIL_QUOTES_DTO = {
      email: state.householdDetails.email,
      mobile: "",
      quoteNo: state.quote.quote_id,
      quotes: quickQuotes.map((item) => {
        return {
          logo: item.CompanyDetails.logoUrl,
          planName: item.productDetails.product_name,
          price:
            item.premiumDetails.grossPremium ||
            item.premiumDetails.totalPremium,
          quotePageLink:
            window.location.protocol +
            "//" +
            window.location.host +
            "/" +
            state.quote.quote_id,
          sumInsured: formatIndianCurrency(
            Number(
              extractNumbersFromString(
                state.householdDetails.buildingValue.value
              )
            ) +
              Number(
                extractNumbersFromString(
                  state.householdDetails.householdItems.value
                )
              )
          ),
        };
      }),
    };
    QUOTES_SERVICES.email_quotes(onSuccess, onError, data);
  };
  const navigate = useNavigate();
  const [openEmailPopup, setOpenEmailPopup] = useState(false);
  const homeHandler = () => {
    window.location.href = "https://dev.masuraksha.com/";
  };
  return (
    <Box className="navbarDesktop">
      <EmailPopup
        open_status={openEmailPopup}
        value_update={setOpenEmailPopup}
        quickQuotes={quickQuotes}
      />
      <Grid container rowSpacing={2} columnSpacing={3} alignItems={"center"}>
        <Grid xs={6}>
          <img
            src="../images/masuraksha_logo.svg"
            height="46px"
            onClick={() => homeHandler()}
          />
        </Grid>
        <Grid xs={6} display="flex" justifyContent="end" alignItems="center">
          {/* {!quotesLoading && ( */}
          <Button onClick={() => setOpenEmailPopup(true)} className="btn mr-4">
            Email Quotes
          </Button>
          {/* )} */}
          <h6 className="mb-0 toll-num">
            <span>Toll Free:</span> 1800 123 624276
          </h6>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Navbar;
