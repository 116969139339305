import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Navbar from "../../../../Components/Mobile/Quotes/Navbar/Navbar";
import FilterSection from "../../../../Components/Mobile/Quotes/ProductInfoFields/FilterSection";
import "../../../../Shared/MQuotePage.scss";
import MQuoteLoading from "../../../../Components/Mobile/Quotes/Quote/MQuoteLoading";
import { useState } from "react";
import MHouseholdQuote from "../../../../Components/Mobile/Quotes/Quote/MHouseholdQuote/MHouseholdQuote";

function MQuotePage() {
  // Quotation Lists
  const quoteList: Array<{
    planName: string;
    premium: string;
    data: Array<{ featureName: string; featureType: string }>;
  }> = [
    {
      planName: "Dream House Plan",
      premium: "4574",
      data: [
        { featureName: "Personal Accident", featureType: "Included" },
        { featureName: "Personal Accident Spouse", featureType: "Free" },
      ],
    },
    {
      planName: "Dream House Plan",
      premium: "4574",
      data: [
        { featureName: "Personal Accident", featureType: "Included" },
        { featureName: "Personal Accident Spouse", featureType: "Free" },
      ],
    },
    {
      planName: "Dream House Plan",
      premium: "4574",
      data: [
        { featureName: "Personal Accident", featureType: "Included" },
        { featureName: "Personal Accident Spouse", featureType: "Free" },
      ],
    },
  ];

  const [openModify, setOpenModify] = useState({
    ModifyDetailPopupSH: false,
    HouseholdModifyDetails: false,
  });

  const handleOpenModifyPopup = () => {
    setOpenModify((prev) => ({
      ...prev,
      HouseholdModifyDetails: true,
    }));
  };
  console.log("hhhhhhhhhhhhhhhh", openModify.HouseholdModifyDetails);

  return (
    <Box className="quoteWrapper_m">
      <Navbar
        main_detail="Owner, Flat"
        sub_detail="50,000"
        handleOpenModifyPopup={handleOpenModifyPopup}
        openModify={openModify}
        setOpenModify={setOpenModify}
      />
      <FilterSection />

      <Grid container spacing={3} className="mt-1 mb-2">
        <Grid xs={12}>
          <h6>03 plans found</h6>
          <p>Price shown are inclusive of GST.</p>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <MQuoteLoading logoUrl="" />
          <MHouseholdQuote data={quoteList} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default MQuotePage;
