import React, { useState } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./FilterSection.scss";
import SelectDropdown from "../../../../SupportingFiles/FieldTypes/SelectDropdown/SelectDropdown";

function FilterSection() {
  const [quoteFields, setQuoteFields] = useState<{
    policyTerm: { value: string; warning: boolean };
    propertyAge: { value: string; warning: boolean };
  }>({
    policyTerm: { value: "5 Years", warning: false },
    propertyAge: { value: "1-5 Years", warning: false },
  });
  const policyTerm_data = [
    { key: "1 Years", value: "1 Years" },
    { key: "2 Years", value: "2 Years" },
    { key: "3 Years", value: "3 Years" },
    { key: "4 Years", value: "4 Years" },
    { key: "5 Years", value: "5 Years" },
    { key: "6 Years", value: "6 Years" },
    { key: "7 Years", value: "7 Years" },
    { key: "8 Years", value: "8 Years" },
    { key: "9 Years", value: "9 Years" },
    { key: "10 Years", value: "10 Years" },
    { key: "11 Year", value: "11 Year" },
    { key: "12 Years", value: "12 Years" },
    { key: "13 Years", value: "13 Years" },
    { key: "14 Years", value: "14 Years" },
    { key: "15 Years", value: "15 Years" },
    { key: "16 Years", value: "16 Years" },
    { key: "17 Years", value: "17 Years" },
    { key: "18 Years", value: "18 Years" },
    { key: "19 Years", value: "19 Years" },
    { key: "20 Years", value: "20 Years" },
  ];
  const propertyAge_data = [
    { key: "1-5 Years", value: "1-5 Years" },
    { key: "5-10 Years", value: "5-10 Years" },
    { key: "10-15 Years", value: "10-15 Years" },
    { key: "15-20 Years", value: "15-20 Years" },
    { key: "20-25 Years", value: "20-25 Years" },
    { key: "25-30 Years", value: "25-30 Years" },
    { key: "30-35 Years", value: "30-35 Years" },
    { key: "More than 35 Years", value: "More than 35 Years" },
  ];

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };

  return (
    <Box className="filterFields">
      <Grid container spacing={2}>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField smWidth"
            title="Policy Term"
            value={quoteFields.policyTerm.value}
            attrName={["policyTerm", "value", quoteFields, setQuoteFields]}
            value_update={fieldsUpdateState}
            dropdown_data={policyTerm_data}
            warn_status={quoteFields.policyTerm.warning}
          />
        </Grid>
        <Grid xs={6}>
          <SelectDropdown
            class_name="inputField smWidth"
            title="Property Age"
            value={quoteFields.propertyAge.value}
            attrName={["propertyAge", "value", quoteFields, setQuoteFields]}
            value_update={fieldsUpdateState}
            dropdown_data={propertyAge_data}
            warn_status={quoteFields.propertyAge.warning}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default FilterSection;
