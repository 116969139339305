import React, { useState, useEffect } from "react";
import { Box, Button, Link } from "@mui/material";
import "./LeftDetailSection.scss";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import {
  extractNumbersFromString,
  formatIndianCurrency,
  formatNumberToLakhOrCrores,
} from "../../../../SupportingFiles/HelpingFunction";
import EmailIcon from "@mui/icons-material/Email";
import { POLICY_SERVICES } from "../../../../Services/PolicyService";
import { useAppSelector } from "../../../../State/Hooks";
import { RootState } from "../../../../State/Store";
import { toast } from "react-hot-toast";
import { EMAIL_QUOTES_DTO } from "../../../../Services/DTO/QuotesDTO";
import { QUOTES_SERVICES } from "../../../../Services/QuotesService";
import { TQuickQuote } from "../../../../State/Types/TQuickQuote";
import EmailPopup from "../../EmailPopup/EmailPopup";

interface Props {
  handleBuyClicked: any;
  quoteObj: TQuickQuote;
  onClose: () => any;
  changeEmailPopup: (value: boolean) => any;
}

const LeftDetailSection: React.FC<Props> = ({
  handleBuyClicked,
  quoteObj,
  onClose,
  changeEmailPopup,
}) => {
  const [openEmailPopup, setOpenEmailPopup] = useState(false);
  const [pdfLink, setPdfLink] = useState<string>("");
  const state: RootState = useAppSelector((state) => state);

  useEffect(() => {
    download_brochure();
  }, []);

  const download_brochure = () => {
    const onSuccess = (res: any) => {
      setPdfLink(res.data.data);
    };

    const onError = (err: any) => {};

    POLICY_SERVICES.download_brochure(
      onSuccess,
      onError,
      quoteObj.productDetails.id
    );
  };

  const handleEmailQuotes = () => {
    const onSuccess = (res: any) => {
      toast.success("Successfully Emailed you the quotes");
    };

    const onError = (err: any) => {};

    const data: EMAIL_QUOTES_DTO = {
      email: state.householdDetails.email,
      mobile: "",
      quoteNo: state.quote.quote_id,
      quotes: [
        {
          logo: quoteObj.CompanyDetails?.logoUrl,
          planName: quoteObj.productDetails?.product_name,
          price:
            quoteObj.premiumDetails?.totalPremium ||
            quoteObj.premiumDetails?.netPremium,
          quotePageLink: `http://localhost:3000/proposal/${state.quote.quote_id}/householdBreakup`,
          sumInsured: formatIndianCurrency(
            Number(
              extractNumbersFromString(
                state.householdDetails.buildingValue.value
              )
            ) +
              Number(
                extractNumbersFromString(
                  state.householdDetails.householdItems.value
                )
              )
          ),
        },
      ],
    };
    QUOTES_SERVICES.email_quotes(onSuccess, onError, data);
  };

  return (
    <Box className="leftSection">
      {/* <EmailPopup
        open_status={openEmailPopup}
        value_update={setOpenEmailPopup}
      /> */}
      <img className="insurer" src={quoteObj.CompanyDetails?.logoUrl} />
      <p className="planName">{quoteObj.productDetails?.product_name}</p>

      {/* <p>{cover_title}</p> */}
      {/* <h6>
        {cover_value && (
          <>
            <CurrencyRupeeIcon /> {cover_value}
          </>
        )}
      </h6> */}
      <p>SI for Building</p>
      <h6 className="premium">
        {formatNumberToLakhOrCrores(quoteObj.premiumDetails.buildingSI)}
      </h6>
      <p>SI for Household Items</p>
      <h6 className="premium">
        {formatNumberToLakhOrCrores(quoteObj.premiumDetails.householdSI)}
      </h6>
      <p>Premium (Incl. GST)</p>
      <h6 className="premium">
        {quoteObj.premiumDetails?.totalPremium ||
        quoteObj.premiumDetails?.grossPremium ? (
          <>
            <CurrencyRupeeIcon />{" "}
            {formatIndianCurrency(
              Number(
                quoteObj.premiumDetails?.grossPremium ||
                  quoteObj.premiumDetails?.totalPremium
              )
            )}
          </>
        ) : (
          <>Loading...</>
        )}
      </h6>
      <Button onClick={handleBuyClicked} className="redBtn">
        Buy Now
      </Button>

      <Link
        href={pdfLink}
        target="_blank"
        rel="noreferrer"
        className="brochure"
      >
        Policy Brochure
      </Link>

      <Link
        onClick={() => {
          changeEmailPopup(true);
          onClose();
        }}
        className="sendMail"
      >
        <EmailIcon />
        Send Email
      </Link>
    </Box>
  );
};

export default LeftDetailSection;
