import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link } from "@mui/material";
import "./Navbar.scss";
import EditIcon from "@mui/icons-material/Edit";
import HouseholdModifyDetails from "../../../../Pages/Mobile/HI/MQuotePage/ModifyDetailsPopups/HouseholdModifyDetails";
import ModifyDetailPopupSH from "../../../../Pages/Mobile/ShopKeeperInsurance/MQuotePage/ModifyDetailsPopupSH/ModifyDetailPopupSH";

interface Props {
  main_detail: string;
  sub_detail: string;
  handleOpenModifyPopup: Function;
  openModify: any;
  setOpenModify: Function;
}

const Navbar: React.FC<Props> = ({
  main_detail,
  sub_detail,
  handleOpenModifyPopup,
  openModify,
  setOpenModify,
}) => {
  console.log(openModify.HouseholdModifyDetails,"openSH.ModifyDetailPopupSH333", openModify.ModifyDetailPopupSH);
  return (
    <Box className="navbar">
      <Grid container spacing={2} rowSpacing={3}>
        <Grid xs={12} className="navbarInner">
          <Link className="backStep"></Link>
          <Button
            className="editbtn btn"
            onClick={() => handleOpenModifyPopup()}
          >
            <EditIcon />
          </Button>
          
          {openModify.ModifyDetailPopupSH == true ? (
            <ModifyDetailPopupSH
              openSH={openModify}
              setOpenSH={setOpenModify}
            />
          ) : null}

          {openModify.HouseholdModifyDetails ==true && (
            <HouseholdModifyDetails
              openHI={openModify}
              setOpenHI={setOpenModify}
            />
          )}

          <div>
            <h6 className="mobile_h6">{main_detail}</h6>
            <p className="mobile_nav_p">
              Building: <span>{sub_detail}</span>
            </p>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Navbar;
