export enum DOC_TYPE_CODE {
  Aadhar_POA = "D25",
  Driving_License = "D26",
  Passport = "D28",
  Aadhar_POV = "D03",
  Ckyc_Num = "D02",
  Pan_Card = "D07",
  ICICI_PAN = "PAN",
  ICICI_AADHAR = "AADHAAR",
  ICICI_CKYC_NO = "CKYC_NO",
  ICICI_DRIVING_LICENCE = "DRIVING_LICENCE",
  ICICI_PASSPORT = "PASSPORT",
  ICICI_VOTER_ID = "VOTERID",
}
