import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link } from "@mui/material";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { HI_PATH } from "../../../../../Path/HI/HIPath";
import { BuyActionDTO } from "../../../../../Services/DTO/PolicyDTO";
import { POLICY_SERVICES } from "../../../../../Services/PolicyService";
import { useAppDispatch, useAppSelector } from "../../../../../State/Hooks";
import { proposalSlice } from "../../../../../State/Slices/Proposal/ProposalSlice";
import { quoteSlice } from "../../../../../State/Slices/Quotes/QuoteSlice";
import { RootState } from "../../../../../State/Store";
import { TQuickQuote } from "../../../../../State/Types/TQuickQuote";
import {
  extractNumbersFromString,
  formatIndianCurrency,
  formatNumberToLakhOrCrores,
  formatNumberWithCurrencySymbol,
} from "../../../../../SupportingFiles/HelpingFunction";
import "../Quote.scss";
import HouseholdDetailPopup from "../../../../../Pages/Mobile/HI/MQuotePage/PolicyDetailsPopups/HouseholdDetailPopup";

interface Props {
  data: Array<{
    planName: string;
    premium: string;
    data: Array<{
      featureName: string;
      featureType: string;
    }>;
  }>;
}

const MHouseholdQuote: React.FC<Props> = ({ data }) => {
  const [openPolicyPopup, setOpenPolicyPopup] = useState(false);
  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  const [loading, setloading] = useState(true);

  return (
    <>
      {loading && (
        <div className="noQuote-sec">
          <img src="./images/no-record-found.svg" alt="" />
          <h4 className="mt-3">Sorry no quotes found!</h4>
          <p className="light_p mb-5">
            We can't find the quotes as per your entered details. Please try
            again.
          </p>
        </div>
      )}
      {data.map((sub_data) => (
        <Box className="quoteContainer">
          <Grid container spacing={2} alignItems={"center"}>
            <Grid xs={6}>
              <img
                className="insurer"
                src="../images/insurerlogo/kotak_general_insurance.svg"
              />
              <Link
                className="planViewLink"
                onClick={() => setOpenPolicyPopup(true)}
              >
                Plan Details
              </Link>
              <p>
                Feature Count: <span>15</span>
              </p>
            </Grid>
            <Grid xs={6} textAlign="right">
              <p className="plan_name">{sub_data.planName}</p>
              <Button className="redBtn my-3">Buy Now</Button>
              <br />
              <p>
                Premium (Inc. GST)
                <br />
                <span>₹ {sub_data.premium}</span>
              </p>
            </Grid>
            <Grid xs={12} paddingTop={0} paddingBottom={0}>
              <hr />
              {/* <ul>
                {sub_data.data.map((data: any) => (
                  <li>
                    <p>
                      {data.featureName} <span>- {data.featureType}</span>
                    </p>
                  </li>
                ))}
              </ul> */}
            </Grid>
            <Grid xs={6}>
              <p>
                SI for Building <br />
                <span>₹ 1 crore</span>
              </p>
            </Grid>
            <Grid xs={6} textAlign={"right"}>
              <p>
                SI for Household Items <br />
                <span>₹ 10 Lakh</span>
              </p>
            </Grid>
          </Grid>

          <HouseholdDetailPopup
            open_status={openPolicyPopup}
            attrName={setOpenPolicyPopup}
            value_update={updateMasterState}
          />
        </Box>
      ))}
    </>
  );
};

export default MHouseholdQuote;
