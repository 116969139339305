import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../../../../Components/Desktop/ProposalComponents/AddonQuestion/AddonQuestion.scss";
import "../../../../../../Components/Desktop/SwitchButton/SwitchButton.scss";
import { HI_PATH } from "../../../../../../Path/HI/HIPath";
import { useAppDispatch, useAppSelector } from "../../../../../../State/Hooks";
import { proposalSlice } from "../../../../../../State/Slices/Proposal/ProposalSlice";
import { RootState } from "../../../../../../State/Store";
import {
  TAddonCoverages,
  TAddonPACDetails,
  TAddonSpousePACDetails,
} from "../../../../../../State/Types/TProposal";
import RKTextField from "../../../../../../SupportingFiles/FieldTypes/RKTextField/RKTextField";
import SearchDropdown from "../../../../../../SupportingFiles/FieldTypes/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../../SupportingFiles/FieldTypes/SelectDropdown/SelectDropdown";
import {
  extractNumbersFromString,
  isEmpty,
  objectHasWarning,
  objectHasWarningv2,
  objectHasWarningv3,
} from "../../../../../../SupportingFiles/HelpingFunction";
import Footer from "../../../../../../Components/Desktop/ProposalComponents/Footer/Footer";
import { Addon_Coverages_DTO } from "../../../../../../Services/DTO/ProposalDTO";
import toast from "react-hot-toast";
import { PROPOSAL_SERVICES } from "../../../../../../Services/ProposalService";
import { QUOTES_SERVICES } from "../../../../../../Services/QuotesService";
import { pageSlice } from "../../../../../../State/Slices/PageSlice/PageSlice";

function AddOns() {
  const navigate = useNavigate();
  const { PAGE_STATUS } = useAppSelector((state) => state.pageSlice);
  const state: TAddonCoverages = useAppSelector(
    (state: RootState) => state.proposalInformation.addonCoverages
  );
  const { proposalInformation, quote } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  const [personalAccidentCoverage, setPersonalAccidentCoverage] =
    useState(false);

  const [isSectionDisabled, setIsSectionDisabled] = useState(true);
  const [load, setLoad] = useState(false);
  const gender_data = [
    { key: "Male", value: "Male" },
    { key: "Female", value: "Female" },
  ];
  const relationship_data = [
    { key: "Father", value: "Father" },
    { key: "Mother", value: "Mother" },
    { key: "Brother", value: "Brother" },
    { key: "Sister", value: "Sister" },
    { key: "Grand Father", value: "Grand Father" },
    { key: "Grand Mother", value: "Grand Mother" },
    { key: "Brother In Law", value: "Brother In Law" },
    { key: "Mother In Law", value: "Mother In Law" },
    { key: "Daughter", value: "Daughter" },
    { key: "Son", value: "Son" },
    { key: "Wife", value: "Wife" },
  ];
  const [insuredAgeData, setInsuredAgeData] = React.useState([{}]);
  const [nomineeAgeData, setNomineeAgeData] = React.useState([{}]);
  const [spouseAgeData, setSpouseAgeData] = React.useState([{}]);
  const [spouseNomineeAgeData, setSpouseNomineeAgeData] = React.useState([{}]);

  // const [load, setLoad] = React.useState(false);

  useEffect(() => {
    let data = [];
    for (var i = 18; i < 80; i++) {
      data.push({ label: i, value: i + " Years" });
    }
    setInsuredAgeData(data);
    setSpouseAgeData(data);
  }, []);

  useEffect(() => {
    let data = [];
    for (var i = 5; i < 80; i++) {
      data.push({ label: i, value: i + " Years" });
    }
    setNomineeAgeData(data);
    setSpouseNomineeAgeData(data);
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName[0] === "pacDetails") {
      if (attrName[1] === "nomineeAge") {
        if (value > 18) {
          dispatch(
            proposalSlice.actions.setAddonCoverage({
              ...state,
              pacDetails: {
                ...state.pacDetails,
                [attrName[1]]: {
                  value: value,
                  warning: isEmpty(value),
                },
                nomineeAppointeeName: {
                  ...state.pacDetails.nomineeAppointeeName,
                  warning: false,
                },
              },
            })
          );
          return;
        }
      }

      dispatch(
        proposalSlice.actions.setAddonCoverage({
          ...state,
          pacDetails: {
            ...state.pacDetails,
            [attrName[1]]: {
              value: value,
              warning: isEmpty(value),
            },
          },
        })
      );
    } else if (attrName[0] === "spousePacDetails") {
      if (attrName[1] === "nomineeAge") {
        if (value > 18) {
          dispatch(
            proposalSlice.actions.setAddonCoverage({
              ...state,
              pacSpouseDetails: {
                ...state.pacSpouseDetails,
                [attrName[1]]: {
                  value: value,
                  warning: isEmpty(value),
                },
                nomineeAppointeeName: {
                  ...state.pacSpouseDetails.nomineeAppointeeName,
                  warning: false,
                },
              },
            })
          );
          return;
        }
      }

      dispatch(
        proposalSlice.actions.setAddonCoverage({
          ...state,
          pacSpouseDetails: {
            ...state.pacSpouseDetails,
            [attrName[1]]: {
              value: value,
              warning: isEmpty(value),
            },
          },
        })
      );
    }
  };

  const updateQuotePremium = (loadingToast: any) => {
    const onSuccess = (res: any) => {
      dispatch(
        proposalSlice.actions.setPolicyInfo({
          ...proposalInformation.policy_information,

          // premiumDetails: res.data.quoteLists,
          premiumDetails: {
            ...res.data.quoteLists,
            grossPremium: res.data.quoteLists.totalPremium,
          },
          // grossPremium: res.data.quoteLists.totalPremium,
        })
      );
      toast.dismiss(loadingToast);
      // debugger;
    };
    // setLoad(false);
    const onError = (err: any) => {
      console.log(err);
    };

    QUOTES_SERVICES.update_icici_quote_premium(
      onSuccess,
      onError,
      quote.quote_id
    );
  };

  const saveProposalInfo = (data: any) => {
    const loadingToast = toast.loading("Updating Proposal...");

    const onSuccess = (res: any) => {
      if (PAGE_STATUS) {
        dispatch(pageSlice.actions.UPDATE_PAGE_STATUS(false));
        navigate(-1);
      } else {
        navigate(HI_PATH.IN_BUILT_COVERAGES);
      }
      toast.dismiss(loadingToast);
      updateQuotePremium(loadingToast);
    };
    // setLoad(false);
    const onError = (err: any) => {
      toast.dismiss(loadingToast);

      // setWaitForApi(false);
    };

    PROPOSAL_SERVICES.save_proposal_info(
      onSuccess,
      onError,
      data,
      quote.quote_id
    );
  };

  const validate_form = () => {
    // return;
    setLoad(true);
    // console.log("setloadddd====>", load);
    // debugger;
    const obj = proposalInformation.addonCoverages;

    let dto: Addon_Coverages_DTO = {
      isEQzone: false,
      isEQcover: obj.isEQcover,
      coverForValuableContent:
        proposalInformation.inbuiltCoverages.coverForValuableContent,
    };

    // If isPac is true, check for warning in pacDetails
    if (obj.isPac) {
      const [warn, validateObj] = objectHasWarningv3(
        structuredClone(obj.pacDetails)
      );

      dispatch(
        proposalSlice.actions.setAddonCoverage({
          ...state,
          pacDetails: validateObj,
        })
      );
      if (warn) {
        toast.error("Please enter valid values", {
          id: "Enter Values Error",
        });
        setLoad(false);
        return;
      }
      // return;
      // obj.pacDetails = validateObj;

      dto = {
        ...dto,
        pacDetails: {
          insuredAge: Number(
            extractNumbersFromString(obj.pacDetails.insuredAge.value)
          ),
          insuredGender: obj.pacDetails.insuredGender.value,
          insuredName: obj.pacDetails.insuredName.value,
          nomineeAge: Number(
            extractNumbersFromString(obj.pacDetails.nomineeAge.value)
          ),
          nomineeGender: obj.pacDetails.nomineeGender.value,
          nomineeName: obj.pacDetails.nomineeName.value,
          nomineeRelation: obj.pacDetails.nomineeRelation.value,
        },
      };
    }

    // If isPacSpouse is true, check for warning in pacSpouseDetails
    if (obj.isPacSpouse) {
      const warn = objectHasWarning(obj.pacSpouseDetails);

      if (warn) {
        toast.error("Please enter valid values", {
          id: "Enter Values Error",
        });
        setLoad(false);
        return;
      }

      dto = {
        ...dto,
        pacSpouseDetails: {
          age: Number(extractNumbersFromString(obj.pacSpouseDetails.age.value)),
          name: obj.pacSpouseDetails.name.value,
          nomineeAge: Number(
            extractNumbersFromString(obj.pacSpouseDetails.nomineeAge.value)
          ),
          nomineeAppointeeName: obj.pacSpouseDetails.nomineeAppointeeName.value,
          nomineeGender: obj.pacSpouseDetails.nomineeGender.value,
          nomineeName: obj.pacSpouseDetails.nomineeName.value,
          nomineeRelationWithInsured:
            obj.pacSpouseDetails.nomineeRelationWithInsured.value,
        },
      };
    }

    saveProposalInfo(dto);
  };
  const updateICICIQuotePremium = () => {
    // setLoad(true);
    const onSuccess = (res: any) => {
      // dispatch(
      //   proposalSlice.actions.setPolicyInfo({
      //     ...proposalInformation.policy_information,
      //     premiumDetails: res.data.quoteLists,
      //   })
      // );
      // debugger;
    };

    const onError = (err: any) => {
      console.log(err);
    };

    QUOTES_SERVICES.update_icici_quote_premium(
      onSuccess,
      onError,
      quote.quote_id
    );
  };
  useEffect(() => {
    const obj = proposalInformation.addonCoverages;
    let dto = {
      isEQzone: false,
      isEQcover: obj.isEQcover,
      isPac: state.isPac,
      isPacSpouse: state.isPacSpouse,
      coverForValuableContent:
        proposalInformation.inbuiltCoverages.coverForValuableContent,
    };

    const onSuccess = (res: any) => {
      // toast.dismiss(loadingToast);
      updateICICIQuotePremium();
      // dispatch(pageSlice.actions.UPDATE_PAGE_STATUS(false));
    };

    const onError = (err: any) => {
      // toast.dismiss(loadingToast);
    };

    PROPOSAL_SERVICES.save_proposal_info(
      onSuccess,
      onError,
      { add_ons: dto },
      quote.quote_id
    );
  }, [proposalInformation.addonCoverages.isEQcover, state.isPac]);
  const toggleHandler = () => {
    console.log("toggle handler", state);
    const updatedDataaa = {
      ...state,
      isPac: !state.isPac,
      isPacSpouse: false,
    };
    // dispatch(
    //   proposalSlice.actions.setAddonCoverage({
    //     ...state,
    //     isPac: !state.isPac,
    //     isPacSpouse: false,
    //   })
    // );
    setPersonalAccidentCoverage(!personalAccidentCoverage);
    setIsSectionDisabled(!isSectionDisabled);

    console.log("ispac-----===>", state.isPac);
    if (state.isPac) {
      console.log("inide this is pac");
      // dispatch(
      //   proposalSlice.actions.setAddonCoverage({
      //     ...state,
      //     pacDetails: {
      //       insuredAge: {
      //         value: "",
      //         warning: false,
      //       },
      //       insuredGender: {
      //         value: "",
      //         warning: false,
      //       },
      //       insuredName: {
      //         value: "",
      //         warning: false,
      //       },
      //       nomineeAge: {
      //         value: "",
      //         warning: false,
      //       },
      //       nomineeGender: {
      //         value: "",
      //         warning: false,
      //       },
      //       nomineeName: {
      //         value: "",
      //         warning: false,
      //       },
      //       nomineeRelation: {
      //         value: "",
      //         warning: false,
      //       },
      //       nomineeAppointeeName: {
      //         value: "",
      //         warning: false,
      //       },
      //     },
      //   })
      // );
      updatedDataaa.pacDetails = {
        insuredAge: {
          value: "",
          warning: false,
        },
        insuredGender: {
          value: "",
          warning: false,
        },
        insuredName: {
          value: "",
          warning: false,
        },
        nomineeAge: {
          value: "",
          warning: false,
        },
        nomineeGender: {
          value: "",
          warning: false,
        },
        nomineeName: {
          value: "",
          warning: false,
        },
        nomineeRelation: {
          value: "",
          warning: false,
        },
        nomineeAppointeeName: {
          value: "",
          warning: false,
        },
      };
    }
    dispatch(proposalSlice.actions.setAddonCoverage(updatedDataaa));
  };
  return (
    <Box>
      <h5 className="sectionTitle">Addon Details</h5>
      <Grid container spacing={3}>
        <Grid xs={12} className="addonQuestion borderBottom">
          <>
            <Grid container spacing={2}>
              <Grid xs={9} className="question-part" alignSelf={"center"}>
                <h6>Personal Accident</h6>
                <p>Death Benefit of Rs. 5 Lakhs each for Insured and Spouse</p>
              </Grid>
              <Grid xs={3} textAlign="right">
                <p className="amount">-</p>
                <div className="toggles">
                  <input
                    id="pac"
                    type="checkbox"
                    checked={state.isPac}
                    // onClick={() => {
                    //   dispatch(
                    //     proposalSlice.actions.setAddonCoverage({
                    //       ...state,
                    //       isPac: !state.isPac,
                    //       isPacSpouse: false,
                    //     })
                    //   );
                    //   setPersonalAccidentCoverage(!personalAccidentCoverage);
                    //   setIsSectionDisabled(!isSectionDisabled);
                    // }}
                    onClick={() => toggleHandler()}
                  />
                  <label htmlFor={"pac"} />
                </div>
              </Grid>
            </Grid>
            {state.isPac ? (
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="innerheading">Insured Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Insured Name"}
                    validation_type="NAME"
                    value={state.pacDetails.insuredName.value}
                    attrName={["pacDetails", "insuredName"]}
                    value_update={updateMasterState}
                    warn_status={state.pacDetails.insuredName.warning}
                    error_message={
                      isEmpty(state.pacDetails.insuredName.value)
                        ? "Enter Insured Name"
                        : "Enter Name"
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Insured Age"
                    value={state.pacDetails.insuredAge.value}
                    attrName={["pacDetails", "insuredAge"]}
                    value_update={updateMasterState}
                    data={insuredAgeData}
                    warn_status={state.pacDetails.insuredAge.warning}
                    error_message={
                      isEmpty(state.pacDetails.insuredAge.value)
                        ? "Select Insured Age"
                        : "Select Age"
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Insured Gender"
                    value={state.pacDetails.insuredGender.value}
                    attrName={["pacDetails", "insuredGender"]}
                    value_update={updateMasterState}
                    dropdown_data={gender_data}
                    warn_status={state.pacDetails.insuredGender.warning}
                    error_message={
                      isEmpty(state.pacDetails.insuredGender.value)
                        ? "Select Gender"
                        : "Select Gender"
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Relation with the applicant"
                    value={state.pacDetails.nomineeRelation.value}
                    attrName={["pacDetails", "nomineeRelation"]}
                    value_update={updateMasterState}
                    dropdown_data={[
                      { key: "Self", value: "Self" },
                      ...relationship_data,
                    ]}
                    warn_status={state.pacDetails.nomineeRelation.warning}
                    error_message={
                      isEmpty(state.pacDetails.nomineeRelation.value)
                        ? "Select Nominee Relation"
                        : "Select Nominee Relation"
                    }
                  />
                </Grid>
                <Grid xs={12}>
                  <h6 className="innerheading"> Nominee Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Nominee Name"}
                    validation_type="NAME"
                    value={state.pacDetails.nomineeName.value}
                    attrName={["pacDetails", "nomineeName"]}
                    value_update={updateMasterState}
                    warn_status={state.pacDetails.nomineeName.warning}
                    error_message={
                      isEmpty(state.pacDetails.nomineeName.value)
                        ? "Enter Nominee Name"
                        : "Enter Name"
                    }
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Nominee Age"
                    value={state.pacDetails.nomineeAge.value}
                    attrName={["pacDetails", "nomineeAge"]}
                    value_update={updateMasterState}
                    data={nomineeAgeData}
                    warn_status={state.pacDetails.nomineeAge.warning}
                    error_message={
                      isEmpty(state.pacDetails.nomineeAge.value)
                        ? "Select Nominee Age"
                        : "Select Age"
                    }
                  />
                </Grid>

                {Number(
                  extractNumbersFromString(state.pacDetails.nomineeAge.value)
                ) <= 18 ? (
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title={"Appointee Name"}
                      validation_type="NAME"
                      value={state.pacDetails.nomineeAppointeeName.value}
                      attrName={["pacDetails", "nomineeAppointeeName"]}
                      value_update={updateMasterState}
                      warn_status={
                        state.pacDetails.nomineeAppointeeName.warning
                      }
                      error_message={
                        isEmpty(state.pacDetails.nomineeAppointeeName.value)
                          ? "Enter Appointee Name"
                          : "Enter Appointee Name"
                      }
                    />
                  </Grid>
                ) : null}
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Relation with the insured"
                    value={state.pacDetails.nomineeRelation.value}
                    attrName={["pacDetails", "nomineeRelation"]}
                    value_update={updateMasterState}
                    dropdown_data={relationship_data}
                    warn_status={state.pacDetails.nomineeRelation.warning}
                    error_message={
                      isEmpty(state.pacDetails.nomineeRelation.value)
                        ? "Select insured Relation"
                        : "Select insured Relation"
                    }
                  />
                </Grid>
              </Grid>
            ) : null}
          </>
        </Grid>
        {/* <Grid
          xs={12}
          className={`addonQuestion borderBottom ${
            isSectionDisabled ? "disabled-section" : ""
          }`}
        >
          <>
            <Grid container spacing={2}>
              <Grid xs={9} className="question-part" alignSelf={"center"}>
                <h6>Personal Accident- Spouse</h6>
                <p>Death Benefit of Rs. 5 Lakhs for Spouse</p>
              </Grid>
              <Grid xs={3} textAlign="right">
                <p className="amount"></p>
                <div className="toggles">
                  <input
                    id="pacforSpouse"
                    type="checkbox"
                    checked={state.isPacSpouse}
                    onClick={() => {
                      if (!state.isPacSpouse) {
                        dispatch(
                          proposalSlice.actions.setAddonCoverage({
                            ...state,
                            isPacSpouse: !state.isPacSpouse,
                            isPac: true,
                          })
                        );
                      } else {
                        dispatch(
                          proposalSlice.actions.setAddonCoverage({
                            ...state,
                            isPacSpouse: !state.isPacSpouse,
                          })
                        );
                      }
                      setPersonalAccidentCoverageSpouse(
                        !personalAccidentCoverageSpouse
                      );
                      setPersonalAccidentCoverage(true);
                    }}
                  />
                  <label htmlFor={"pacforSpouse"} />
                </div>
              </Grid>
            </Grid>
            {state.isPacSpouse ? (
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h6 className="innerheading"> Spouse Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Spouse Name"}
                    validation_type="NAME"
                    value={state.pacSpouseDetails.name.value}
                    attrName={["spousePacDetails", "name"]}
                    value_update={updateMasterState}
                    warn_status={state.pacSpouseDetails.name.warning}
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Spouse Age"
                    value={state.pacSpouseDetails.age.value}
                    attrName={["spousePacDetails", "age"]}
                    value_update={updateMasterState}
                    data={spouseAgeData}
                    warn_status={state.pacSpouseDetails.age.warning}
                    error_message={""}
                  />
                </Grid>
                <Grid xs={12}>
                  <h6 className="innerheading"> Spouse Nominee Details</h6>
                </Grid>
                <Grid xs={4}>
                  <RKTextField
                    class_name="inputField"
                    title={"Nominee Name"}
                    value={state.pacSpouseDetails.nomineeName.value}
                    attrName={["spousePacDetails", "nomineeName"]}
                    value_update={updateMasterState}
                    warn_status={state.pacSpouseDetails.nomineeName.warning}
                  />
                </Grid>
                <Grid xs={4}>
                  <SearchDropdown
                    class_name="inputField"
                    title="Nominee Age"
                    value={state.pacSpouseDetails.nomineeAge.value}
                    attrName={["spousePacDetails", "nomineeAge"]}
                    value_update={updateMasterState}
                    data={spouseNomineeAgeData}
                    warn_status={state.pacSpouseDetails.nomineeAge.warning}
                    error_message={""}
                  />
                </Grid>
                {Number(
                  extractNumbersFromString(
                    state.pacSpouseDetails.nomineeAge.value
                  )
                ) <= 18 ? (
                  <Grid xs={4}>
                    <RKTextField
                      class_name="inputField"
                      title={"Appointee Name"}
                      value={state.pacSpouseDetails.nomineeAppointeeName.value}
                      attrName={["spousePacDetails", "nomineeAppointeeName"]}
                      value_update={updateMasterState}
                      warn_status={
                        state.pacSpouseDetails.nomineeAppointeeName.warning
                      }
                    />
                  </Grid>
                ) : null}
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Nominee Gender"
                    value={state.pacSpouseDetails.nomineeGender.value}
                    attrName={["spousePacDetails", "nomineeGender"]}
                    value_update={updateMasterState}
                    dropdown_data={gender_data}
                    warn_status={state.pacSpouseDetails.nomineeGender.warning}
                  />
                </Grid>
                <Grid xs={4}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Relation with the insured"
                    value={
                      state.pacSpouseDetails.nomineeRelationWithInsured.value
                    }
                    attrName={[
                      "spousePacDetails",
                      "nomineeRelationWithInsured",
                    ]}
                    value_update={updateMasterState}
                    dropdown_data={relationship_data}
                    warn_status={
                      state.pacSpouseDetails.nomineeRelationWithInsured.warning
                    }
                  />
                </Grid>
              </Grid>
            ) : null}
          </>
        </Grid> */}
        <Grid
          xs={12}
          className={`addonQuestion borderBottom ${
            isSectionDisabled ? "disabled-section" : ""
          }`}
        >
          <>
            <Grid container spacing={2}>
              <Grid xs={9} className="question-part" alignSelf={"center"}>
                <h6>EarthQuake Cover</h6>
                <p>Death Benefit of Rs. 5 Lakhs for Spouse</p>
              </Grid>
              <Grid xs={3} textAlign="right">
                <p className="amount">-</p>
                <div className="toggles">
                  <input
                    id="eqCover"
                    type="checkbox"
                    checked={state.isEQcover}
                    onClick={() => {
                      dispatch(
                        proposalSlice.actions.setAddonCoverage({
                          ...state,
                          isEQcover: !state.isEQcover,
                        })
                      );
                    }}
                  />
                  <label htmlFor={"eqCover"} />
                </div>
              </Grid>
            </Grid>
          </>
        </Grid>
      </Grid>
      <Footer continue_function={validate_form} loader={load} />
    </Box>
  );
}

export default AddOns;
