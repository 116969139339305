import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { HOUSEHOLD_DETAIL_SERVICES } from "../../../../../Services/HouseholdDetailsService";
import { useAppDispatch, useAppSelector } from "../../../../../State/Hooks";
import { householdDetailsSlice } from "../../../../../State/Slices/HouseholdDetails/HouseholdDetailSlice";
import { RootState } from "../../../../../State/Store";
import SelectDropdown from "../../../../../SupportingFiles/FieldTypes/SelectDropdown/SelectDropdown";
import {
  capitalizeFirstLetter,
  extractNumbersFromString,
} from "../../../../../SupportingFiles/HelpingFunction";
import HouseholdModifyDetails from "../ModifyDetailsPopups/HouseholdModifyDetails/HouseholdModifyDetails";
import "./ProductInfoBar.scss";
import QuoteLoading from "../../QuoteLoading";

interface Props {
  refetch_quotes: any;
  quotesLoading: boolean;
}

const ProductInfoBar: React.FC<Props> = ({ refetch_quotes, quotesLoading }) => {
  const state: RootState = useAppSelector((state: any) => state);
  const dispatch = useAppDispatch();

  const [openModify, setOpenModify] = useState(false);

  const [quoteFields, setQuoteFields] = useState<{
    policyTerm: { value: string; warning: boolean };
    propertyAge: { value: string; warning: boolean };
    sumInsured: { value: string; warning: boolean };
  }>({
    policyTerm: { value: "1 Years", warning: false },
    propertyAge: { value: "1-5 Years", warning: false },
    sumInsured: { value: "10 Lakhs", warning: false },
  });

  const policyTerm_data = [
    { key: "1 Years", value: "1 Year" },
    { key: "2 Years", value: "2 Years" },
    { key: "3 Years", value: "3 Years" },
    { key: "4 Years", value: "4 Years" },
    { key: "5 Years", value: "5 Years" },
    { key: "6 Years", value: "6 Years" },
    { key: "7 Years", value: "7 Years" },
    { key: "8 Years", value: "8 Years" },
    { key: "9 Years", value: "9 Years" },
    { key: "10 Years", value: "10 Years" },
    { key: "11 Years", value: "11 Years" },
    { key: "12 Years", value: "12 Years" },
    { key: "13 Years", value: "13 Years" },
    { key: "14 Years", value: "14 Years" },
    { key: "15 Years", value: "15 Years" },
    { key: "16 Years", value: "16 Years" },
    { key: "17 Years", value: "17 Years" },
    { key: "18 Years", value: "18 Years" },
    { key: "19 Years", value: "19 Years" },
    { key: "20 Years", value: "20 Years" },
  ];
  const propertyAge_data = [
    { key: "1-5 Years", value: "1-5 Years" },
    { key: "5-10 Years", value: "5-10 Years" },
    { key: "10-15 Years", value: "10-15 Years" },
    { key: "15-20 Years", value: "15-20 Years" },
    { key: "20-25 Years", value: "20-25 Years" },
    { key: "25-30 Years", value: "25-30 Years" },
    { key: "30-35 Years", value: "30-35 Years" },
    { key: "More than 35 Years", value: "More than 35 Years" },
  ];
  const sumInsured_data = [
    { key: "5 Lakhs", value: "5 Lakhs" },
    { key: "10 Lakhs", value: "10 Lakhs" },
    { key: "15 Lakhs", value: "15 Lakhs" },
  ];

  useEffect(() => {
    setQuoteFields({
      ...quoteFields,
      policyTerm: {
        ...quoteFields.policyTerm,
        value: `${state.householdDetails.policyTerm} Years`,
      },
    });
  }, [state.householdDetails.policyTerm]);

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  const onPolicyTermUpdate = (value: string) => {
    const onSuccess = (res: any) => {
      refetch_quotes(state.quote.quote_id);
      dispatch(
        householdDetailsSlice.actions.setPolicyTerm(
          Number(extractNumbersFromString(value))
        )
      );
    };

    const onError = (err: any) => {
      // toast.error("Failed to update policy term")
    };

    HOUSEHOLD_DETAIL_SERVICES.updateHouseholdDetails(onSuccess, onError, {
      quote_no: state.quote.quote_id,
      tenor: "1-" + extractNumbersFromString(value),
    });
  };

  return (
    <Box className="productInfoSection">
      <Grid container spacing={0} className="px-3">
        <Grid xs={5}>
          <Box className="productDetails">
            <div className="houseIcon"></div>
            <div>
              {/* {!quotesLoading && ( */}
              <Button
                className="redBtn"
                onClick={() => setOpenModify(true)}
                disabled={quotesLoading}
                style={{ color: quotesLoading ? "white" : "white" }}
              >
                Edit
              </Button>
              {/* // )} */}
              <h5>{`${capitalizeFirstLetter(
                state.householdDetails.youAre
              )}, ${capitalizeFirstLetter(
                state.householdDetails.youHave
              )}`}</h5>
              <div>
                {state.householdDetails.buildingValue.value ? (
                  <>
                    Building:
                    <span
                      style={{
                        marginRight: 7,
                        fontWeight: "bold",
                      }}
                    >
                      <CurrencyRupeeIcon
                        style={{
                          fontSize: "13px",
                          position: "relative",
                          top: "2px",
                        }}
                      />
                      {state.householdDetails.buildingValue.value}
                    </span>
                  </>
                ) : null}{" "}
                {state.householdDetails.householdItems.value ? (
                  <>
                    Household:
                    <span
                      style={{
                        marginRight: 7,
                        fontWeight: "bold",
                      }}
                    >
                      <CurrencyRupeeIcon
                        style={{
                          fontSize: "13px",
                          position: "relative",
                          top: "2px",
                        }}
                      />
                      {state.householdDetails.householdItems.value}
                    </span>
                  </>
                ) : null}
              </div>

              <HouseholdModifyDetails
                refetch_quotes={refetch_quotes}
                open_status={openModify}
                attrName={setOpenModify}
                value_update={updateMasterState}
                currentPolicyTerm={extractNumbersFromString(
                  quoteFields.policyTerm.value
                )}
              />
            </div>
          </Box>
        </Grid>
        <Grid xs={7} display="flex" alignItems="center" justifyContent="end">
          <Box className="productFields">
            <SelectDropdown
              class_name="inputField smWidth mr-3"
              title="Policy Term"
              value={quoteFields.policyTerm.value}
              attrName={["policyTerm", "value", quoteFields, setQuoteFields]}
              value_update={(attrName: any, value: any) => {
                fieldsUpdateState(attrName, value);
                onPolicyTermUpdate(value);
              }}
              dropdown_data={policyTerm_data}
              warn_status={quoteFields.policyTerm.warning}
              disabled={quotesLoading}
            />
            <SelectDropdown
              class_name="inputField smWidth"
              title="Property Age"
              value={quoteFields.propertyAge.value}
              attrName={["propertyAge", "value", quoteFields, setQuoteFields]}
              value_update={fieldsUpdateState}
              dropdown_data={propertyAge_data}
              warn_status={quoteFields.propertyAge.warning}
              disabled={quotesLoading}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductInfoBar;
