import { Box, Button, Grid, Link, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { HI_PATH } from "../../../../Path/HI/HIPath";
import { useAppSelector } from "../../../../State/Hooks";
import { RootState } from "../../../../State/Store";
import "../../../../Shared/ProposalPage.scss";

interface Props {
  modalOpen: boolean;
  setModalOpen: any;
}

const KycFailedPopup = ({ modalOpen, setModalOpen }: Props) => {
  const quoteState = useAppSelector((state: RootState) => state.quote);

  const navigate = useNavigate();

  return (
    <Modal open={modalOpen} className="modalWrapper">
      <Box className="modalInner xl-width">
        <Grid container spacing={2}>
          <Grid xs={12}>
            <h4 className="popup-heading mb-4">CKYC Details</h4>
            <Link
              href="#"
              className="close-button"
              onClick={() => {
                setModalOpen(false);
              }}
            />
            <hr />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <p>
            Sorry,we are not getting any information from insurer side, please
            click below Button to upload or verify with other Document
          </p>
        </Grid>

        <Grid container spacing={2}>
          <Grid xs={12} className="ctaBtn mt-5">
            <hr />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid xs={6} className="ctaBtn">
            <Button
              variant="contained"
              className="regularSecondaryBtn"
              onClick={() => {
                setModalOpen(false);
              }}
            >
              Verify with other Document
            </Button>
          </Grid>
          <Grid xs={6} style={{ textAlign: "right" }} className="ctaBtn">
            <Button
              variant="contained"
              className="regularSecondaryBtn"
              onClick={() => {
                navigate(HI_PATH.MANUAL_KYC);
                setModalOpen(false);
              }}
            >
              Manual KYC
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default KycFailedPopup;
