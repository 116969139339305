import InbuiltCoverage from "../../../../../Pages/Desktop/HI/ProposalPage/GoDigit/InbuiltCoverage/InbuiltCoverage";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

const InBuiltCoveragesContainer = () => {
  const isMobile = useIsMobile();

  return <>{isMobile ? <></> : <InbuiltCoverage />}</>;
};

export default InBuiltCoveragesContainer;
