import OwnerDetails from "../../../../../Pages/Desktop/HI/ProposalPage/ICICI/OwnerDetails/OwnerDetails";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

const OwnerDetailsContainer = () => {
  const isMobile = useIsMobile();

  return <>{isMobile ? <></> : <OwnerDetails />}</>;
};

export default OwnerDetailsContainer;
