import PolicyConfirm from "../../../../../Pages/Desktop/HI/ProposalPage/GoDigit/PolicyConfirm/PolicyConfirm";
import VerifyKYC from "../../../../../Pages/Desktop/HI/ProposalPage/GoDigit/VerifyKYC/VerifyKYC";
import useIsMobile from "../../../../../SupportingFiles/MobileProvider";

const PolicyConfirmContainer = () => {
  const isMobile = useIsMobile();
  console.log("checking policy confirm container", isMobile);

  return <>{isMobile ? <></> : <PolicyConfirm />}</>;
};

export default PolicyConfirmContainer;
