import Axios from "axios";
// import { ALL_ROUTES } from "../Routes/allroutes";
// import { AUTH_SERVICE } from "./AuthService";

export default class AxiosAPIInstance {
  public api;
  public cancelApi;
  constructor() {
    const user_token = sessionStorage.getItem("usertoken");
    if (!user_token) {
      // window.location.pathname = ALL_ROUTES.LOGIN;
    }
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    this.api = Axios.create({
      cancelToken: source.token,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Access-Token": access_token,
        // auth: user_token,
        Username: "35327650",
        Password: "Digit@123$",
      },
    });
    this.api.interceptors.response.use(
      (config) => config,
      (err) => {
        const originalConfig = err.config;
        if (err?.response?.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          const onSuccess = (data: any) => {
            const newToken = data.token;
            // localStorage.setItem();
            // localStorage.setItem();
            originalConfig.headers["authorization"] = user_token;
            return this.api(originalConfig);
          };
          const onError = (error: any) => {};
          //   return AUTH_SERVICE.getRefreshToken(onSuccess, onError);
        }
        if (err?.response?.status === 400) {
          return Promise.reject(err);
        }
      }
    );
    this.cancelApi = source.cancel;
  }
}
