import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import sessionStorage from "redux-persist/lib/storage/session";
import { householdDetailsSlice } from "./Slices/HouseholdDetails/HouseholdDetailSlice";
import { kycResponseSlice } from "./Slices/KycResponse/KycResponseSlice";
import { pageSlice } from "./Slices/PageSlice/PageSlice";
import { proposalSlice } from "./Slices/Proposal/ProposalSlice";
import { quoteSlice } from "./Slices/Quotes/QuoteSlice";

// Combine individual reducers into a root reducer
const rootReducer = combineReducers({
  householdDetails: householdDetailsSlice.reducer,
  quote: quoteSlice.reducer,
  proposalInformation: proposalSlice.reducer,
  kycResponseSlice: kycResponseSlice.reducer,
  pageSlice: pageSlice.reducer,
});

// Configure Redux Persist with session storage
const persistConfig = {
  key: "root",
  storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
const store = configureStore({
  reducer: persistedReducer,
});

// Create the Redux Persist store
const persistor = persistStore(store);

export { persistor, store };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
