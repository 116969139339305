import React, { useState } from "react";
import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Modal from "@mui/material/Modal";
import "../../../../../Shared/MModalDetailsPopups.scss";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import RKTextField from "../../../../../SupportingFiles/FieldTypes/RKTextField/RKTextField";
import { formatIndianCurrency } from "../../../../../SupportingFiles/HelpingFunction";
import CustomCheckbox from "../../../../../SupportingFiles/FieldTypes/CustomCheckbox/CustomCheckbox";
import SelectDropdown from "../../../../../SupportingFiles/FieldTypes/SelectDropdown/SelectDropdown";

interface Props {
  openSH: any;
  setOpenSH: Function;
}
const ModifyDetailPopupSH: React.FC<Props> = ({ openSH, setOpenSH }) => {
  const [updateFields, setUpdateFields] = useState<{
    businessType: { value: string; warning: boolean };
    buildingValue: { value: string; warning: boolean };
    plantsMachinery: { value: string; warning: boolean };
    furnitureFixture: { value: string; warning: boolean };
    rawMaterial: { value: string; warning: boolean };
    stockInProcess: { value: string; warning: boolean };
    finishedStock: { value: string; warning: boolean };
  }>({
    businessType: { value: "Battery Shop", warning: false },
    buildingValue: { value: "₹ 10,00,000", warning: false },
    plantsMachinery: { value: "₹ 10,00,000", warning: false },
    furnitureFixture: { value: "₹ 10,00,000", warning: false },
    rawMaterial: { value: "₹ 10,00,000", warning: false },
    stockInProcess: { value: "₹ 10,00,000", warning: false },
    finishedStock: { value: "₹ 10,00,000", warning: false },
  });

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };

  const [youAre, setYouAre] = React.useState("Owner");
   const businessType_data = [
     { key: "Battery Shop", value: "Battery Shop" },
     { key: "Confectionary Shop", value: "Confectionary Shop" },
     { key: "Hardware Shop ", value: "Hardware Shop" },
   ];
  const handleYouAre = (
    event: React.MouseEvent<HTMLElement>,
    newYouAre: string
  ) => {
    setYouAre(newYouAre);
  };
  const [isChecked, setIsChecked] = useState({
    building: true,
    content: true,
    stock: true,
  });
  const toggleCheckBox = (attrName: string, checkStatus: boolean) => {
    setIsChecked((prev) => ({ ...prev, [attrName]: checkStatus }));
  };
  return (
    <Box>
      <Modal open={openSH.ModifyDetailPopupSH == true}>
        <Box className="modalDetailPopup">
          <Box className="modalDetailPopup-inner">
            <Box className="header">
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h4>Update Shop Details</h4>
                  <Link
                    className="close"
                    onClick={() =>
                      setOpenSH((prev: any) => ({
                        ...prev,
                        ModifyDetailPopupSH: false,
                      }))
                    }
                  ></Link>
                </Grid>
              </Grid>
            </Box>
            <Box className="modalContent ">
              <Grid container spacing={3} justifyContent="center">
                <Grid xs={12} className="toggleButtons" textAlign="center">
                  <h5 className="mb-2">You Are?</h5>
                  <ToggleButtonGroup
                    value={youAre}
                    exclusive
                    onChange={handleYouAre}
                  >
                    <ToggleButton value="Owner">Owner</ToggleButton>
                    <ToggleButton value="Tenant">Tenant</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid xs={12} textAlign="center">
                  <h5 className="mb-2">You Have?</h5>
                  <SelectDropdown
                    class_name="inputField "
                    title="Business Type"
                    value={updateFields.businessType.value}
                    attrName={[
                      "businessType",
                      "value",
                      updateFields,
                      setUpdateFields,
                    ]}
                    value_update={fieldsUpdateState}
                    dropdown_data={businessType_data}
                    warn_status={updateFields.businessType.warning}
                  />
                </Grid>
                <Grid xs={12} textAlign="center">
                  <h5 className="mb-2">Sum Insured Values</h5>
                  {youAre === "Owner" && (
                    <CustomCheckbox
                      label={"Building"}
                      attrName={"building"}
                      value={isChecked.building}
                      value_update={toggleCheckBox}
                    />
                  )}

                  <CustomCheckbox
                    label={"Content"}
                    attrName={"content"}
                    value={isChecked.content}
                    value_update={toggleCheckBox}
                  />
                  <CustomCheckbox
                    label={"Stock"}
                    attrName={"stock"}
                    value={isChecked.stock}
                    value_update={toggleCheckBox}
                  />
                </Grid>

                {/* Building Fields */}
                {isChecked.building && youAre === "Owner" && (
                  <Grid container spacing={2} justifyContent="center">
                    <Grid xs={12} textAlign="center">
                      <h5>Enter Value of Building</h5>
                    </Grid>
                    <Grid xs={12}>
                      <RKTextField
                        class_name="inputField"
                        title="Value of Building (in Rs.)"
                        value={updateFields.buildingValue.value}
                        attrName={[
                          "buildingValue",
                          "value",
                          updateFields,
                          setUpdateFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={updateFields.buildingValue.warning}
                      />
                    </Grid>
                  </Grid>
                )}

                {/* content Fields */}
                {isChecked.content && (
                  <Grid container spacing={2} justifyContent="center">
                    <Grid xs={12} textAlign="center">
                      <h5>Enter Value of Content Items</h5>
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title="Value of Household Items (in Rs.)"
                        value={updateFields.plantsMachinery.value}
                        attrName={[
                          "plantsMachinery",
                          "value",
                          updateFields,
                          setUpdateFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={updateFields.plantsMachinery.warning}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title="Value of Household Items (in Rs.)"
                        value={updateFields.furnitureFixture.value}
                        attrName={[
                          "furnitureFixture",
                          "value",
                          updateFields,
                          setUpdateFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={updateFields.furnitureFixture.warning}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title="Value of Household Items (in Rs.)"
                        value={updateFields.rawMaterial.value}
                        attrName={[
                          "rawMaterial",
                          "value",
                          updateFields,
                          setUpdateFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={updateFields.rawMaterial.warning}
                      />
                    </Grid>
                  </Grid>
                )}

                {/* stock Fields */}
                {isChecked.stock && (
                  <Grid container spacing={2} justifyContent="center">
                    <Grid xs={12} textAlign="center">
                      <h5>Enter Value Stock Items</h5>
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title="Stock in Process (in Rs.)"
                        value={updateFields.stockInProcess.value}
                        attrName={[
                          "stockInProcess",
                          "value",
                          updateFields,
                          setUpdateFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={updateFields.stockInProcess.warning}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title="Finished Stock (in Rs.)"
                        value={updateFields.finishedStock.value}
                        attrName={[
                          "finishedStock",
                          "value",
                          updateFields,
                          setUpdateFields,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={updateFields.finishedStock.warning}
                      />
                    </Grid>
                  </Grid>
                )}

                <Grid xs={12} textAlign="center">
                  <Button className="redBtn">Update Details</Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ModifyDetailPopupSH;
