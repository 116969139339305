import { URL_CONSTANTS } from "../APIVar/URLConstants";
import { GetAPI, PostAPI } from "./AxiosApiInstance/API";
import { EMAIL_QUOTES_DTO } from "./DTO/QuotesDTO";

const get_quick_qoutes = (onSuccess: any, onError: any, quoteId: string) => {
  PostAPI.call(
    `${URL_CONSTANTS.get_quick_quotes_by_quote_id}`,
    { quote_no: quoteId },
    onSuccess,
    {},
    onError
  );
};

const create_quote = (dto: any, onSuccess: any, onError: any) => {
  PostAPI.call(`${URL_CONSTANTS.create_quote}`, dto, onSuccess, {}, onError);
};

const icici_create_quote = (dto: any, onSuccess: any, onError: any) => {
  PostAPI.call(URL_CONSTANTS.icici_create_quote, dto, onSuccess, {}, onError);
};

const update_digit_quote_premium = (
  onSuccess: any,
  onError: any,
  quoteId: string
) => {
  if (!quoteId) {
    return;
  }

  GetAPI.call(
    URL_CONSTANTS.update_digit_quote_premium(quoteId),
    onSuccess,
    {},
    onError
  );
};

const update_icici_quote_premium = (
  onSuccess: any,
  onError: any,
  quoteId: string
) => {
  GetAPI.call(
    URL_CONSTANTS.update_icici_quote_premium(quoteId),
    onSuccess,
    {},
    onError
  );
};

const get_company_list = (onSuccess: any, onError: any) => {
  GetAPI.call(URL_CONSTANTS.get_company_list, onSuccess, {}, onError);
};

const email_quotes = (onSuccess: any, onError: any, dto: EMAIL_QUOTES_DTO) => {
  PostAPI.call(URL_CONSTANTS.email_quotes, dto, onSuccess, {}, onError);
};

export const QUOTES_SERVICES = {
  get_quick_qoutes,
  create_quote,
  update_digit_quote_premium,
  get_company_list,
  update_icici_quote_premium,
  email_quotes,
  icici_create_quote,
};
