import { URL_CONSTANTS } from "../APIVar/URLConstants";
import {
  GetAPI,
  PostAPI,
  DeleteAPI,
  PatchAPI,
  PutAPI,
} from "./AxiosApiInstance/API";

const getHouseholdDetail = (
  onSuccess: Function,
  onError: Function,
  query: string
) => {
  GetAPI.call(
    URL_CONSTANTS.get_household_detail_by_quote_id(query),
    onSuccess,
    onError
  );
};

const updateHouseholdDetails = (
  onSuccess: Function,
  onError: Function,
  params: any
) => {
  PatchAPI.call(
    URL_CONSTANTS.update_household_details,
    params,
    onSuccess,
    {},
    onError
  );
};

export const HOUSEHOLD_DETAIL_SERVICES = {
  getHouseholdDetail,
  updateHouseholdDetails,
};
