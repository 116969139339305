import { Box, Link } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { POLICY_SERVICES } from "../../../../../../Services/PolicyService";
import { TQuickQuote } from "../../../../../../State/Types/TQuickQuote";
import HouseholdMainContent from "../../../../PolicyPopup/HouseholdMainContent/HouseholdMainContent";
import LeftDetailSection from "../../../../PolicyPopup/LeftDetailSection/LeftDetailSection";
import EmailPopup from "../../../../EmailPopup/EmailPopup";

interface Props {
  open_status: boolean;
  value_update: Function;
  attrName: any;
  quoteObj: TQuickQuote;
  handleBuyClicked: any;
}

const PolicyDetailPopup = ({
  open_status,
  value_update,
  attrName,
  quoteObj,
  handleBuyClicked,
}: Props) => {
  const [openEmailPopup, setOpenEmailPopup] = useState(false);
  const [features, setFeatures] = useState<
    Array<{ title: string; description: string }>
  >([]);
  const [exclusions, setExclusions] = useState<
    Array<{ title: string; description: string }>
  >([]);

  useEffect(() => {
    setFeatures([]);
    setExclusions([]);
    getPlanDetails();
  }, [quoteObj?.productDetails?.id, open_status]);
  // console.log("yyyyy====>", quoteObj);
  const getPlanDetails = () => {
    const onSuccess = (res: any) => {
      if (!res.data.data.details) {
        return;
      }

      const detailObj = JSON.parse(res.data.data.details);

      setFeatures(detailObj.features);
      setExclusions(detailObj.exclusions);
    };

    const onError = (err: any) => {
      console.log(err);
    };

    POLICY_SERVICES.get_plan_details(
      onSuccess,
      onError,
      quoteObj.productDetails.id
    );
  };

  return (
    <Box>
      <EmailPopup
        open_status={openEmailPopup}
        value_update={setOpenEmailPopup}
        quickQuotes={quoteObj}
      />
      <Modal open={open_status} onClose={() => value_update(attrName, false)}>
        <Box className="modalDetailPopup_d">
          <Box className="modalDetailPopup_d-inner">
            <Box className="header">
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h4>Policy Details</h4>
                  <Link
                    className="close"
                    onClick={() => value_update(attrName, false)}
                  ></Link>
                </Grid>
              </Grid>
            </Box>
            <Box display="flex">
              <LeftDetailSection
                quoteObj={quoteObj}
                handleBuyClicked={() => handleBuyClicked(quoteObj)}
                onClose={() => value_update(attrName, false)}
                changeEmailPopup={(value: boolean) => setOpenEmailPopup(value)}
              />
              <HouseholdMainContent
                features={features}
                exclusions={exclusions}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default PolicyDetailPopup;
