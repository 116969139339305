import { Route, Routes } from "react-router-dom";
import HIQuoteContainer from "../../../Container/HI/QuotePage/HIQuoteContainer";
import { HI_PATH_POLICY_CONFIRM } from "../../../Path/HI/HIPath";
import PolicyConfirmContainer from "../../../Container/HI/ProposalPage/GoDigit/PolicyCofirm/PolicyConfirmContainer";

function HI_QUOTE_ROUTES() {
  return (
    <Routes>
      <Route path={"/"} element={<HIQuoteContainer />} />
      <Route
        path={HI_PATH_POLICY_CONFIRM}
        element={<PolicyConfirmContainer />}
      />
    </Routes>
  );
}
export default HI_QUOTE_ROUTES;
