import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import "../FieldTypes.scss";

interface Props {
  title: string;
  value: string;
  attrName: any;
  value_update: Function;
  dropdown_data: Array<any>;
  warn_status?: boolean;
  class_name?: string;
  error_message?: string;
  value_type_key?: boolean;
  disabled?: boolean;
}

const defaultProps: Props = {
  title: "",
  value: "",
  attrName: "",
  warn_status: false,
  value_update: () => {},
  dropdown_data: [],
  error_message: "Please select",
  value_type_key: false,
  disabled: false
};

const SelectDropdown: React.FC<Props> = ({
  title,
  value,
  value_update,
  attrName,
  dropdown_data,
  warn_status,
  class_name,
  error_message,
  value_type_key,
  disabled
}) => {
  return (
    <div className={class_name} >
      <FormControl fullWidth>
        <InputLabel>{title}</InputLabel>
        <Select
          value={value}
          label={title}
          disabled={disabled}
          // style={{ border: "2px solid blue" }}
          onChange={(e) => value_update(attrName, e.target.value)}
        >
          {dropdown_data.map((data) => (
            <MenuItem
              key={data.key}
              value={data.key}
              // style={{ border: "2px solid red" }}
            >
              {data.value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {warn_status == true ? (
        <span className="error">{error_message}</span>
      ) : null}
    </div>
  );
};

SelectDropdown.defaultProps = defaultProps;

export default SelectDropdown;
