import {
  Box,
  Button,
  Link,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import "../../../Shared/ModalDetailsPopups.scss";
import RKTextField from "../../../SupportingFiles/FieldTypes/RKTextField/RKTextField";
import { QUOTES_SERVICES } from "../../../Services/QuotesService";
import toast from "react-hot-toast";
import {
  extractNumbersFromString,
  formatIndianCurrency,
  isEmpty,
  validateEmail,
} from "../../../SupportingFiles/HelpingFunction";
import { EMAIL_QUOTES_DTO } from "../../../Services/DTO/QuotesDTO";
import { RootState } from "../../../State/Store";
import { useAppSelector } from "../../../State/Hooks";
import { TQuickQuote } from "../../../State/Types/TQuickQuote";
import { ECompanyCode } from "../../../State/Enum/ECompanyCode";

interface Props {
  open_status: boolean;
  value_update: Function;
  // closepolicyDetailPopup?: boolean;
  quickQuotes?: TQuickQuote | Array<TQuickQuote>;
}

const EmailPopup: React.FC<Props> = ({
  open_status,
  value_update,
  // closepolicyDetailPopup,
  quickQuotes,
}) => {
  const [email, setEmail] = useState("");
  const state: RootState = useAppSelector((state) => state);
  // console.log("bismalogoo===quickQuotes=>", quickQuotes);
  const updateEmailHandler = (attrName: any, value: any) => {
    setEmail(value);
  };
  const check_error = () => {
    let hasError = false;
    const data = validateEmail(email.trim());
    if (!data) {
      return (hasError = true);
    }
    // let;
  };
  const validate_form = () => {
    const hasError: any = check_error();
    console.log("has error in email", hasError);
    if (hasError) {
      toast.error("Please enter valid email", {
        id: "Enter Values Error",
      });
      return;
    }
    const onSuccess = (res: any) => {
      toast.success("Email sent");
      setEmail("");
      value_update(false);
    };
    const onError = () => {};
    // if (quickQuotes) {
    //   const data: EMAIL_QUOTES_DTO = {
    //     email: email,
    //     mobile: "",
    //     quoteNo: state.quote.quote_id,
    //     quotes: [
    //       {
    //         logo: quickQuotes?.CompanyDetails?.logo,
    //         planName: quickQuotes?.productDetails?.product_name,
    //         price: quickQuotes?.premiumDetails?.totalPremium,
    //         quotePageLink: "",
    //         sumInsured: quickQuotes?.premiumDetails?.sumInsured,
    //       },
    //     ],
    //     // quotes: quickQuotes.map((item) => {
    //     //   return {
    //     //     logo: item.CompanyDetails.logoUrl,
    //     //     planName: item.productDetails.product_name,
    //     //     price:
    //     //       item.premiumDetails.grossPremium ||
    //     //       item.premiumDetails.totalPremium,
    //     //     quotePageLink:
    //     //       window.location.protocol +
    //     //       "//" +
    //     //       window.location.host +
    //     //       "/" +
    //     //       state.quote.quote_id,
    //     //     sumInsured: formatIndianCurrency(
    //     //       Number(
    //     //         extractNumbersFromString(
    //     //           state.householdDetails.buildingValue.value
    //     //         )
    //     //       ) +
    //     //         Number(
    //     //           extractNumbersFromString(
    //     //             state.householdDetails.householdItems.value
    //     //           )
    //     //         )
    //     //     ),
    //     //   };
    //     // }),
    //   };
    //   QUOTES_SERVICES.email_quotes(onSuccess, onError, data);
    // }
    if (quickQuotes) {
      if (Array.isArray(quickQuotes)) {
        const data: EMAIL_QUOTES_DTO = {
          email: email,
          mobile: "",
          quoteNo: state.quote.quote_id,
          quotes: quickQuotes.map((item) => ({
            logo: item.CompanyDetails.logoUrl,
            planName: item.productDetails?.product_name,
            price:
              item.CompanyDetails.company_code === ECompanyCode.GO_DIGIT
                ? item.premiumDetails?.grossPremium
                : item.premiumDetails?.totalPremium,
            // price: item.premiumDetails?.grossPremium,
            quotePageLink:
              window.location.protocol +
              "//" +
              window.location.host +
              "/" +
              state.quote.quote_id,
            sumInsured: item.premiumDetails?.sumInsured,
          })),
        };
        console.log("some check", data);
        QUOTES_SERVICES.email_quotes(onSuccess, onError, data);
      } else {
        const data: EMAIL_QUOTES_DTO = {
          email: email,
          mobile: "",
          quoteNo: state.quote.quote_id,
          quotes: [
            {
              logo: quickQuotes.CompanyDetails.logoUrl,
              planName: quickQuotes.productDetails?.product_name,
              // price: quickQuotes.premiumDetails?.grossPremium,
              price:
                quickQuotes.CompanyDetails.company_code ===
                ECompanyCode.GO_DIGIT
                  ? quickQuotes.premiumDetails?.grossPremium
                  : quickQuotes.premiumDetails?.totalPremium,
              quotePageLink:
                window.location.protocol +
                "//" +
                window.location.host +
                "/" +
                state.quote.quote_id,
              sumInsured: quickQuotes.premiumDetails?.sumInsured,
            },
          ],
        };
        QUOTES_SERVICES.email_quotes(onSuccess, onError, data);
      }
    }
  };
  return (
    <Box>
      <Modal open={open_status} onClose={() => value_update(false)}>
        <Box className="modalDetailPopup_d sm">
          <Box className="modalDetailPopup_d-inner">
            <Box className="header">
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h4>SEND EMAIL</h4>
                  <Link
                    className="close"
                    onClick={() => value_update(false)}
                  ></Link>
                </Grid>
              </Grid>
            </Box>
            <Box className="modalContent px-6">
              <Grid container spacing={3} justifyContent="center">
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField "
                    title="Enter Email"
                    value={email}
                    attrName={["email", "value", email, setEmail]}
                    // value_update={() => {}}
                    warn_status={false}
                    // error_message="Enter Email"
                    value_update={updateEmailHandler}
                    error_message={
                      isEmpty(email) ? "Enter Email" : "Enter Valid Email"
                    }
                  />
                </Grid>
                <Grid xs={12} textAlign="center">
                  <Button className="redBtn" onClick={validate_form}>
                    Send
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default EmailPopup;
