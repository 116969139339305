import { Alert, Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import Footer from "../../../../../../Components/Desktop/ProposalComponents/Footer/Footer";
import { HI_PATH } from "../../../../../../Path/HI/HIPath";
import { Save_HouseholdBreakup_DTO } from "../../../../../../Services/DTO/ProposalDTO";
import { PROPOSAL_SERVICES } from "../../../../../../Services/ProposalService";
import { QUOTES_SERVICES } from "../../../../../../Services/QuotesService";
import { useAppDispatch, useAppSelector } from "../../../../../../State/Hooks";
import { proposalSlice } from "../../../../../../State/Slices/Proposal/ProposalSlice";
import RKTextField from "../../../../../../SupportingFiles/FieldTypes/RKTextField/RKTextField";
import {
  extractNumbersFromString,
  formatNumberWithCurrencySymbol,
} from "../../../../../../SupportingFiles/HelpingFunction";

enum ItemLabels {
  CLOTHING_SI = "Value of Clothing Items",
  FURNITURE_SI = "Value of Furniture Items",
  DURABLES_SI = "Value of Durables Items",
  JEWELLERY_SI = "Value of Jewellery Items",
}

const HouseholdItemsBreakup: React.FC = () => {
  const { PAGE_STATUS } = useAppSelector((state) => state.pageSlice);
  const state = useAppSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedForBreakup, setSelectedForBreakup] = useState<Array<string>>(
    []
  );

  const [clothingValue, setClothingValue] = useState<number>(0);
  const [furnitureValue, setFurnitureValue] = useState<number>(0);
  const [durablesValue, setDurablesValue] = useState<number>(0);
  const [jewelleryValue, setJewelleryValue] = useState<number>(0);

  const [breakupTotal, setBreakupTotal] = useState<number>(0);
  const [showError, setShowError] = useState<boolean>(false);

  useEffect(() => {
    const newSelectedForBreakup = [...selectedForBreakup];

    const objToUse: any = state.proposalInformation.householdItemsBreakup;

    Object.keys(objToUse).map((item) => {
      if (objToUse[item]) {
        if (item === "electronics") {
          newSelectedForBreakup.push(ItemLabels.CLOTHING_SI);
          setClothingValue(objToUse[item]);
        } else if (item === "furniture") {
          newSelectedForBreakup.push(ItemLabels.FURNITURE_SI);
          setFurnitureValue(objToUse[item]);
        } else if (item === "others") {
          newSelectedForBreakup.push(ItemLabels.DURABLES_SI);
          setDurablesValue(objToUse[item]);
        }
      }
    });

    setSelectedForBreakup(newSelectedForBreakup);
  }, [state.proposalInformation.householdItemsBreakup]);

  useEffect(() => {
    const newTotal =
      Number(extractNumbersFromString(clothingValue.toString())) +
      Number(extractNumbersFromString(furnitureValue.toString())) +
      Number(extractNumbersFromString(durablesValue.toString())) +
      Number(extractNumbersFromString(jewelleryValue.toString()));

    let newShowError =
      Number(newTotal) !==
      Number(
        extractNumbersFromString(state.householdDetails.householdItems.value)
      );

    setShowError(newShowError);
    setBreakupTotal(Number(newTotal));

    dispatch(
      proposalSlice.actions.setHouseholdItemsBreakup({
        ...state.proposalInformation.householdItemsBreakup,
        clothing: clothingValue,
        furniture: furnitureValue,
        durables: durablesValue,
        jewellery: jewelleryValue,
        showError: newShowError,
      })
    );
  }, [clothingValue, furnitureValue, durablesValue, jewelleryValue]);

  const updateMasterState = (attrName: any, value: number) => {
    attrName(value);
  };

  const handleCheckBreakup = (
    valueState: any,
    checked: boolean,
    label: string
  ) => {
    let newTotal: number = breakupTotal;

    let newSelectedForBreakup = [...selectedForBreakup];

    if (checked) {
      newTotal = newTotal + valueState;
      newSelectedForBreakup.push(label);
    } else {
      newTotal = newTotal - valueState;
      newSelectedForBreakup = newSelectedForBreakup.filter(
        (item) => item !== label
      );
    }

    if (label === ItemLabels.CLOTHING_SI) {
      if (!checked) {
        setClothingValue(0);
      }
    } else if (label === ItemLabels.FURNITURE_SI) {
      if (!checked) {
        setFurnitureValue(0);
      }
    } else if (label === ItemLabels.DURABLES_SI) {
      if (!checked) {
        setDurablesValue(0);
      }
    } else if (label === ItemLabels.JEWELLERY_SI) {
      if (!checked) {
        setJewelleryValue(0);
      }
    }

    if (newTotal !== Number(state.householdDetails.householdItems.value)) {
      setShowError(true);
    } else {
      setShowError(false);
    }

    setSelectedForBreakup(newSelectedForBreakup);
    setBreakupTotal(newTotal);
  };

  const updateQuotePremium = () => {
    const onSuccess = (res: any) => {
      dispatch(
        proposalSlice.actions.setPolicyInfo({
          ...state.proposalInformation.policy_information,
          premiumDetails: res.data.quoteLists,
        })
      );
    };

    const onError = (err: any) => {
      console.log(err);
    };

    QUOTES_SERVICES.update_icici_quote_premium(
      onSuccess,
      onError,
      state.quote.quote_id
    );
  };

  const saveProposalInfo = (data: any) => {
    const loadingToast = toast.loading("Updating Proposal...");

    const onSuccess = (res: any) => {
      toast.dismiss(loadingToast);

      if (PAGE_STATUS) {
        navigate(-1);
      } else {
        navigate(HI_PATH.VERIFY_CKYC);
      }
      // updateQuotePremium();
    };

    const onError = (err: any) => {
      toast.dismiss(loadingToast);
    };

    PROPOSAL_SERVICES.save_proposal_info(
      onSuccess,
      onError,
      data,
      state.quote.quote_id
    );
  };

  const validate_form = () => {
    // If no error in householdItemsBreakup, save the proposal info
    if (!state.proposalInformation.householdItemsBreakup.showError) {
      const dto = {
        household_details: {
          clothing: state.proposalInformation.householdItemsBreakup.clothing
            ? Number(
                extractNumbersFromString(
                  `${state.proposalInformation.householdItemsBreakup.clothing}`
                )
              )
            : 0,
          furniture: state.proposalInformation.householdItemsBreakup.furniture
            ? Number(
                extractNumbersFromString(
                  `${state.proposalInformation.householdItemsBreakup.furniture}`
                )
              )
            : 0,
          durables: state.proposalInformation.householdItemsBreakup.durables
            ? Number(
                extractNumbersFromString(
                  `${state.proposalInformation.householdItemsBreakup.durables}`
                )
              )
            : 0,
          jewellery: state.proposalInformation.householdItemsBreakup.jewellery
            ? Number(
                extractNumbersFromString(
                  `${state.proposalInformation.householdItemsBreakup.jewellery}`
                )
              )
            : 0,
        },
      };
      saveProposalInfo(dto);
    } else {
      toast.error("Please provide correct information.", {
        id: "Save Proposal Error",
      });
    }
  };

  return (
    <Box>
      <h5 className="sectionTitle">Breakup of Household Items Sum Insured</h5>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <div className="householdItemDiv">
            <FormControlLabel
              className="proposalCheckBoxWrapper"
              control={
                <Checkbox
                  checked={selectedForBreakup.includes(ItemLabels.CLOTHING_SI)}
                  onClick={(e: any) => {
                    if (e.target.checked) {
                      if (
                        breakupTotal ===
                        Number(state.householdDetails.householdItems.value)
                      ) {
                        return;
                      }
                    }
                    handleCheckBreakup(
                      clothingValue,
                      e.target.checked,
                      ItemLabels.CLOTHING_SI
                    );
                  }}
                />
              }
              label={ItemLabels.CLOTHING_SI}
            />
          </div>
        </Grid>
        {selectedForBreakup.includes(ItemLabels.CLOTHING_SI) ? (
          <Grid xs={6}>
            <RKTextField
              class_name="inputField inrField"
              validation_type="CURRENCY"
              title={"Enter Value for Clothing Items"}
              value={clothingValue}
              attrName={setClothingValue}
              value_update={updateMasterState}
              warn_status={false}
              InputProps={{
                startAdornment: <>₹</>,
              }}
            />
          </Grid>
        ) : null}
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <div className="householdItemDiv">
            <FormControlLabel
              className="proposalCheckBoxWrapper"
              control={
                <Checkbox
                  checked={selectedForBreakup.includes(ItemLabels.FURNITURE_SI)}
                  onClick={(e: any) => {
                    if (e.target.checked) {
                      if (
                        breakupTotal ===
                        Number(state.householdDetails.householdItems.value)
                      ) {
                        return;
                      }
                    }
                    handleCheckBreakup(
                      furnitureValue,
                      e.target.checked,
                      ItemLabels.FURNITURE_SI
                    );
                  }}
                />
              }
              label={ItemLabels.FURNITURE_SI}
            />
          </div>
        </Grid>
        {selectedForBreakup.includes(ItemLabels.FURNITURE_SI) ? (
          <Grid xs={6}>
            <RKTextField
              class_name="inputField inrField"
              validation_type="CURRENCY"
              title={"Enter Value for Furniture Items"}
              value={furnitureValue}
              attrName={setFurnitureValue}
              value_update={updateMasterState}
              warn_status={false}
              InputProps={{
                startAdornment: <>₹</>,
              }}
            />
          </Grid>
        ) : null}
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <div className="householdItemDiv">
            <FormControlLabel
              className="proposalCheckBoxWrapper"
              control={
                <Checkbox
                  checked={selectedForBreakup.includes(ItemLabels.DURABLES_SI)}
                  onClick={(e: any) => {
                    if (e.target.checked) {
                      if (
                        breakupTotal ===
                        Number(state.householdDetails.householdItems.value)
                      ) {
                        return;
                      }
                    }
                    handleCheckBreakup(
                      durablesValue,
                      e.target.checked,
                      ItemLabels.DURABLES_SI
                    );
                  }}
                />
              }
              label={ItemLabels.DURABLES_SI}
            />
          </div>
        </Grid>
        {selectedForBreakup.includes(ItemLabels.DURABLES_SI) ? (
          <Grid xs={6}>
            <RKTextField
              class_name="inputField inrField"
              validation_type="CURRENCY"
              title={"Enter Value for Durables Items"}
              value={durablesValue}
              attrName={setDurablesValue}
              value_update={updateMasterState}
              warn_status={false}
              InputProps={{
                startAdornment: <>₹</>,
              }}
            />
          </Grid>
        ) : null}
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={6}>
          <div className="householdItemDiv">
            <FormControlLabel
              className="proposalCheckBoxWrapper"
              control={
                <Checkbox
                  checked={selectedForBreakup.includes(ItemLabels.JEWELLERY_SI)}
                  onClick={(e: any) => {
                    if (e.target.checked) {
                      if (
                        breakupTotal ===
                        Number(state.householdDetails.householdItems.value)
                      ) {
                        return;
                      }
                    }
                    handleCheckBreakup(
                      durablesValue,
                      e.target.checked,
                      ItemLabels.JEWELLERY_SI
                    );
                  }}
                />
              }
              label={ItemLabels.JEWELLERY_SI}
            />
          </div>
        </Grid>
        {selectedForBreakup.includes(ItemLabels.JEWELLERY_SI) ? (
          <Grid xs={6}>
            <RKTextField
              class_name="inputField inrField"
              validation_type="CURRENCY"
              title={"Enter Value for Jewellery Items"}
              value={jewelleryValue}
              attrName={setJewelleryValue}
              value_update={updateMasterState}
              warn_status={false}
              InputProps={{
                startAdornment: <>₹</>,
              }}
            />
          </Grid>
        ) : null}
      </Grid>
      {showError && selectedForBreakup.length > 0 && (
        <Alert style={{ marginTop: 20 }} severity="error">
          The Sum of{" "}
          {selectedForBreakup.includes(ItemLabels.CLOTHING_SI) && "Clothing,"}{" "}
          {selectedForBreakup.includes(ItemLabels.FURNITURE_SI) && "Furniture,"}{" "}
          {selectedForBreakup.includes(ItemLabels.DURABLES_SI) && "Durables"}{" "}
          {selectedForBreakup.includes(ItemLabels.JEWELLERY_SI) &&
            "& Jewellery"}{" "}
          should be equal to Household Items Value{" "}
          <strong>
            {formatNumberWithCurrencySymbol(
              state.householdDetails.householdItems.value
            )}
          </strong>
        </Alert>
      )}

      <Footer continue_function={validate_form} />
    </Box>
  );
};

export default HouseholdItemsBreakup;
