import { Box, Button, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { HI_PATH } from "../../../../Path/HI/HIPath";
import { BuyActionDTO } from "../../../../Services/DTO/PolicyDTO";
import { POLICY_SERVICES } from "../../../../Services/PolicyService";
import { useAppDispatch, useAppSelector } from "../../../../State/Hooks";
import { proposalSlice } from "../../../../State/Slices/Proposal/ProposalSlice";
import { quoteSlice } from "../../../../State/Slices/Quotes/QuoteSlice";
import { RootState } from "../../../../State/Store";
import { TQuickQuote } from "../../../../State/Types/TQuickQuote";
import {
  extractNumbersFromString,
  formatIndianCurrency,
  formatNumberToLakhOrCrores,
  formatNumberWithCurrencySymbol,
} from "../../../../SupportingFiles/HelpingFunction";
import "../Quote.scss";
import QuoteLoading from "../QuoteLoading";
import PolicyDetailPopup from "./PolicyDetailsPopups/HouseholdDetailPopup/PolicyDetailPopup";
import { ECompanyCode } from "../../../../State/Enum/ECompanyCode";

interface Props {
  quickQuotes: TQuickQuote[];
  loading: boolean;
  companyList: Array<{
    company_code: string;
    logoUrl: string;
    productId: string;
    product_type_code: string;
    product_code: string;
    product_name: string;
    product_desc: string;
    sumInsured: string;
  }>;
}

const Quote: React.FC<Props> = ({ quickQuotes, loading, companyList }) => {
  const [openPolicyPopup, setOpenPolicyPopup] = useState<boolean>(false);
  const [policyObj, setpolicyObj] = useState<TQuickQuote>({
    CompanyDetails: {
      avg_time_to_sttle_claim: "",
      claim_ratio: "",
      company_code: "",
      company_master_id: "",
      created: "",
      customer_satisfaction: "",
      id: "",
      logo: "",
      logoUrl: "",
      net_income_ration: "",
      policy_sales_growth: "",
      product_type_code: "",
      product_type_id: "",
      status: "",
      valid_from: "",
      valid_to: "",
    },
    premiumDetails: {
      buildingSI: "",
      householdSI: "",
      sumInsured: "",
      additionalPremium: "",
      ambulanceChargesCover: "",
      antiTheftDiscount: "",
      associationDiscount: "",
      basicPremium: "",
      consumablesCover: "",
      deductibleDiscount: "",
      derivedPremium: "",
      digit_plan_type: "",
      discountedNetPremium: "",
      discountPremium: "",
      earthquakeDiscountPremium: "",
      earthQuakePremium: "",
      enquiryId: "",
      ePolicyDiscount: "",
      grossPremium: "",
      lossOfRentPremium: "",
      ncbDiscount: "",
      netPremium: "",
      odDiscount: "",
      otherDiscount: "",
      otherDiscountPremium: "",
      passengerCover: "",
      planCode: "",
      serviceTax: "",
      terrorismDiscountPremium: "",
      terrorismPremium: "",
      totalPremium: "",
      totalTax: "",
    },
    productDetails: {
      brochure: "",
      bs_ty_exp: "",
      bs_ty_new: "",
      bs_ty_rollover: "",
      buy_online_code: "",
      created: "",
      discount_calc_action: "",
      discount_calc_method: "",
      id: "",
      is_online: "",
      parent_id: "",
      policy_covers: "",
      prem_calc_action: "",
      product_code: "",
      product_desc: "",
      product_name: "",
      product_type_code: "",
      product_type_id: "",
      proposal_method: "",
      quote_action: "",
      rate_calc_method: "",
      rating: "",
      short_name: "",
      special_feature_codes: "",
      status: "",
      tax_calc_action: "",
      tax_calc_method: "",
      valid_from: "",
      valid_to: "",
    },
    featureCount: 0,
    exclusionCount: 0,
  });

  const state: RootState = useAppSelector((state: any) => state);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const updateMasterState = (attrName: any, value: any) => {
    attrName(value);
  };

  const handleBuyClicked = (policy: TQuickQuote) => {
    const data: BuyActionDTO = {
      company_code: policy.CompanyDetails.company_code,
      product_code: policy.productDetails.product_code,
      premium: policy.premiumDetails.netPremium,
      product_id: policy.productDetails.id,
      sum_insured: (
        Number(
          extractNumbersFromString(state.householdDetails.buildingValue.value)
        ) +
        Number(
          extractNumbersFromString(state.householdDetails.householdItems.value)
        )
      ).toString(),
      amountWithGst: policy.premiumDetails.netPremium,
      income: "",
      incomeOne: "",
      insurance_type: "Home Insurance",
      pptenure: "",
      quote_no: state.quote.quote_id,
      tenure: "1-" + state.householdDetails.policyTerm,
    };

    const onSuccess = (res: any) => {
      dispatch(
        proposalSlice.actions.setPolicyInfo({
          ...state.proposalInformation.policy_information,
          ...policy,
        })
      );
      dispatch(proposalSlice.actions.setDigitKycId(""));

      if (
        state.householdDetails.sumInsuredFor === "buildingAndHouseItems" ||
        state.householdDetails.sumInsuredFor === "householdItems"
      ) {
        const householdSI_threshhold = 1000000;
        let navigateToBreakup = false;

        if (
          Number(
            extractNumbersFromString(
              state.householdDetails.householdItems.value
            )
          ) > householdSI_threshhold
        ) {
          navigateToBreakup = true;
        } else if (
          Number(
            extractNumbersFromString(
              state.householdDetails.householdItems.value
            )
          ) >
          Number(
            extractNumbersFromString(state.householdDetails.buildingValue.value)
          ) *
            0.2
        ) {
          navigateToBreakup = true;
        }

        dispatch(quoteSlice.actions.setNavigateToBreakup(true));
        navigate(HI_PATH.HOUSE_HOLD_BREAKUP);
      } else {
        navigate(HI_PATH.VERIFY_CKYC);
      }
    };

    const onError = (err: any) => {
      toast.error("Error");
    };
    POLICY_SERVICES.policy_buy_action(onSuccess, onError, data);
  };
  useEffect(() => {
    getPlanDetails();
  }, []);

  const getPlanDetails = () => {
    const onSuccess = (res: any) => {
      console.log("res", res);
      // if (!res.data.data.details) {
      //   return;
      // }

      const detailObj = JSON.parse(res.data.data.details);
      console.log("detailObj", detailObj);
      // setFeatures(detailObj.features);
      // setExclusions(detailObj.exclusions);
    };

    const onError = (err: any) => {
      console.log(err);
    };

    POLICY_SERVICES.get_plan_details(
      onSuccess,
      onError,
      policyObj.productDetails.id
    );
  };
  return (
    <>
      {loading && (
        <>
          {companyList.map((item, index) => {
            return <QuoteLoading key={index} logoUrl={item.logoUrl} />;
          })}
        </>
      )}

      {!loading && quickQuotes.length == 0 && (
        <div className="noQuote_sec">
          <img src="./images/no-record-found.svg" alt="" />
          <h4>Sorry no quotes found!</h4>
          <p>
            We can't find the quotes as per your enterd details. Please try
            again.
          </p>
        </div>
      )}

      {!loading &&
        quickQuotes.length > 0 &&
        quickQuotes.map((sub_data, index) => {
          if (!sub_data) return null;

          return (
            <Box key={index} className="quoteContainer_d">
              <Grid
                container
                rowSpacing={2}
                columnSpacing={3}
                alignItems="center"
              >
                <Grid xs={2}>
                  <img
                    className="insurer"
                    src={sub_data.CompanyDetails?.logoUrl}
                  />
                </Grid>
                <Grid xs={2}>
                  <p className="mb-2">SI for Building</p>
                  <h5>
                    {formatNumberToLakhOrCrores(
                      sub_data?.premiumDetails?.buildingSI
                    )}
                  </h5>
                </Grid>
                <Grid xs={2}>
                  <p className="mb-2">SI for Household Items</p>
                  <h5>
                    {formatNumberToLakhOrCrores(
                      sub_data?.premiumDetails?.householdSI
                    )}
                  </h5>
                </Grid>
                <Grid xs={2}>
                  <p className="mb-2"> Features Count:</p>
                  <h5>{sub_data?.featureCount}</h5>
                </Grid>
                <Grid xs={2}>
                  <p className="mb-2">Premium (Inc. GST)</p>
                  <h5 className="premium">
                    {sub_data.CompanyDetails.company_code ===
                    ECompanyCode.GO_DIGIT
                      ? formatIndianCurrency(
                          Number(sub_data.premiumDetails?.grossPremium)
                        )
                      : formatIndianCurrency(
                          Number(sub_data.premiumDetails?.totalPremium)
                        )}
                  </h5>
                </Grid>
                <Grid xs={2} textAlign="right">
                  <p>{sub_data.productDetails.product_name}</p>
                  <Button
                    onClick={() => handleBuyClicked(sub_data)}
                    className="redBtn my-3"
                  >
                    Buy Now
                  </Button>
                  <br />
                  <Link
                    className="planViewLink"
                    onClick={() => {
                      setOpenPolicyPopup(true);
                      setpolicyObj(sub_data);
                    }}
                  >
                    Plan Details
                  </Link>
                </Grid>
              </Grid>
            </Box>
          );
        })}

      <PolicyDetailPopup
        open_status={openPolicyPopup}
        attrName={setOpenPolicyPopup}
        value_update={updateMasterState}
        quoteObj={policyObj}
        handleBuyClicked={handleBuyClicked}
      />
    </>
  );
};

export default Quote;
