import {
  Box,
  Button,
  Link,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { UpdateHouseholdDetailsDTO } from "../../../../../../Services/DTO/HouseholdDetailsDTO";
import { HOUSEHOLD_DETAIL_SERVICES } from "../../../../../../Services/HouseholdDetailsService";
import { useAppDispatch, useAppSelector } from "../../../../../../State/Hooks";
import { householdDetailsSlice } from "../../../../../../State/Slices/HouseholdDetails/HouseholdDetailSlice";
import { RootState } from "../../../../../../State/Store";
import { THouseholdDetails } from "../../../../../../State/Types/THouseholdDetails";
import { SI_TYPE } from "../../../../../../SupportingFiles/Enums/SI_Type";
import RKTextField from "../../../../../../SupportingFiles/FieldTypes/RKTextField/RKTextField";
import { extractNumbersFromString } from "../../../../../../SupportingFiles/HelpingFunction";
import "../../../../../../Shared/ModalDetailsPopups.scss";

interface Props {
  attrName: any;
  open_status: boolean;
  value_update: Function;
  refetch_quotes: Function;
  currentPolicyTerm: string;
}

const HouseholdModifyDetails: React.FC<Props> = ({
  attrName,
  open_status,
  value_update,
  refetch_quotes,
  currentPolicyTerm,
}) => {
  const dispatch = useAppDispatch();
  const state: RootState = useAppSelector((state) => state);

  const [householdDetails, setHouseholdDetails] = useState<THouseholdDetails>(
    state.householdDetails
  );

  const [toastError, setToastError] = useState<boolean>(false);
  // console.log("")
  useEffect(() => {
    setHouseholdDetails(state.householdDetails);
  }, [state.householdDetails]);

  // calculate construction cost
  // const constructionCost: number =
  //   Number(
  //     extractNumbersFromString(householdDetails.buildingValue.value.toString())
  //   ) /
  //   Number(
  //     extractNumbersFromString(householdDetails.carpetArea.value.toString())
  //   );

  let constructionCost: number;

  if (
    householdDetails.buildingValue.value === "" ||
    householdDetails.carpetArea.value === ""
  ) {
    constructionCost = 0;
  } else {
    constructionCost =
      Number(
        extractNumbersFromString(
          householdDetails.buildingValue.value.toString()
        )
      ) /
      Number(
        extractNumbersFromString(householdDetails.carpetArea.value.toString())
      );
  }

  const fieldsUpdateState = (attrName: any, value: any) => {
    // console.log("bisma=fieldsUpdateState==>", attrName, parseInt(value));
    if (attrName[0] === "buildingValue") {
      const minAmount = 100000;
      attrName[3]({
        ...attrName[2],
        [attrName[0]]: {
          ...attrName[2][attrName[0]],
          [attrName[1]]: value,
          warning: parseInt(`${value}`.replaceAll(",", "")) < minAmount,
        },
      });
    } else if (attrName[0] === "householdItems") {
      console.log("inside else if blockk===>");
      const minAmount = 50000;
      attrName[3]({
        ...attrName[2],
        [attrName[0]]: {
          ...attrName[2][attrName[0]],
          [attrName[1]]: value,
          warning: parseInt(`${value}`.replaceAll(",", "")) < minAmount,
        },
      });
    } else if (attrName[0] === "buildingAndHouseItems") {
      const houseMinAmount = 50000;
      const buildingMinAmount = 100000;
      attrName[3]({
        ...attrName[2],
        [attrName[0]]: {
          ...attrName[2][attrName[0]],
          [attrName[1]]: value,
          warning:
            parseInt(`${value}`.replaceAll(",", "")) < houseMinAmount ||
            buildingMinAmount,
        },
      });
    } else {
      attrName[3]({
        ...attrName[2],
        [attrName[0]]: {
          ...attrName[2][attrName[0]],
          [attrName[1]]: value,
          // warning: parseInt(value) < minAmount,
        },
      });
    }
  };

  const handleYouAre = (
    event: React.MouseEvent<HTMLElement>,
    newYouAre: string
  ) => {
    if (!newYouAre) {
      return;
    }

    if (newYouAre === "tenant") {
      setHouseholdDetails({
        ...householdDetails,
        youAre: newYouAre,
        sumInsuredFor: SI_TYPE.HOUSEHOLD_ITEMS,
      });
    } else {
      setHouseholdDetails({
        ...householdDetails,
        youAre: newYouAre,
      });
    }
  };

  const handleYouHave = (
    event: React.MouseEvent<HTMLElement>,
    newYouHave: string
  ) => {
    if (!newYouHave) {
      return;
    }

    setHouseholdDetails({ ...householdDetails, youHave: newYouHave });
  };

  const handleSumInsured = (
    event: React.MouseEvent<HTMLElement>,
    newSumInsured: string
  ) => {
    if (!newSumInsured) {
      return;
    }
    let data = { ...householdDetails };
    // if (newSumInsured === "buildingAndHouseItems") {
    //   if (householdDetails.householdItems.value === "0") {
    //     data = {
    //       ...data,
    //       householdItems: { value: "1", warning: data.householdItems.warning },
    //     };
    //   }
    // }

    setHouseholdDetails({ ...data, sumInsuredFor: newSumInsured });
  };

  const onHouseholdDetailsUpdate = () => {
    if (toastError) return;
    let householdDetailsToUse: THouseholdDetails = { ...householdDetails };

    if (householdDetailsToUse.sumInsuredFor === "building") {
      if (
        parseInt(householdDetails.buildingValue.value.replaceAll(",", "")) <
          100000 ||
        householdDetails.carpetArea.value === "0"
      ) {
        setToastError(true);
        toast.error("Invalid Value");
        return;
      }
      householdDetailsToUse = {
        ...householdDetailsToUse,
        householdItems: { value: "0", warning: false },
      };
    } else if (householdDetailsToUse.sumInsuredFor === "householdItems") {
      // console.log("kkkkk=====>", householdDetails);
      if (
        parseInt(householdDetails.householdItems.value.replaceAll(",", "")) <
        50000
      ) {
        setToastError(true);
        toast.error("Invalid Value");
        return;
      }
      householdDetailsToUse = {
        ...householdDetailsToUse,
        buildingValue: { value: "0", warning: false },
        carpetArea: { value: "0", warning: false },
        costOfconstruction: { value: "0", warning: false },
      };
    }

    if (householdDetailsToUse.sumInsuredFor === "buildingAndHouseItems") {
      const householdValue = extractNumbersFromString(
        householdDetailsToUse.householdItems.value.toString()
      );
      const carpetValue = extractNumbersFromString(
        householdDetailsToUse.carpetArea.value.toString()
      );
      console.log(
        "kkkkkk===>",
        parseInt(householdDetails.householdItems.value.replaceAll(",", ""))
      );
      if (
        parseInt(householdDetails.householdItems.value.replaceAll(",", "")) <
        50000
      ) {
        setToastError(true);
        toast.error("household Value is less than required.");
        return;
      }
      if (
        parseInt(householdDetails.buildingValue.value.replaceAll(",", "")) <
        100000
      ) {
        setToastError(true);
        toast.error("Building Value is less than required.");
        return;
      }
      if (householdValue === "0" || householdValue === "") {
        setToastError(true);
        // toast.error("Household value cannot be zero");
        toast.error("Please enter valid  Household values", {
          id: "Enter Values Error",
        });
        return;
      }

      if (carpetValue === "0" || carpetValue === "") {
        setToastError(true);
        // toast.error("Carpet Value cannot be zero.");
        toast.error("Please enter valid Carpet values", {
          id: "Enter Values Error",
        });
        return;
      }
    }

    const onSuccess = (res: any) => {
      toast.success("Updated Requirements", res.data);
      refetch_quotes(state.quote.quote_id);
      setHouseholdDetails(householdDetailsToUse);
      dispatch(
        householdDetailsSlice.actions.setHouseholdDetails(householdDetailsToUse)
      );
      value_update(attrName, false);
    };

    const onError = (err: any) => {
      // setToastError(true);
      toast.error("Failed to update details");
      // alert("failed to update details.");
    };

    const data: UpdateHouseholdDetailsDTO = {
      quote_no: state.quote.quote_id,
      building_type: householdDetailsToUse.youHave.toLowerCase(),
      owner_type: householdDetailsToUse.youAre.toLowerCase(),
      building_value: extractNumbersFromString(
        householdDetailsToUse.buildingValue.value.toString()
      ),
      carpet_area: extractNumbersFromString(
        householdDetailsToUse.carpetArea.value.toString()
      ),
      cost_of_construction:
        householdDetailsToUse.sumInsuredFor !== "householdItems"
          ? constructionCost.toString()
          : "0",
      household_value:
        householdDetailsToUse.householdItems.value === "0"
          ? ""
          : extractNumbersFromString(
              householdDetailsToUse.householdItems.value.toString()
            ),

      insured_for: householdDetailsToUse.sumInsuredFor,
      tenor: "1-" + householdDetailsToUse.policyTerm,
      sum_insured: (
        Number(
          extractNumbersFromString(
            householdDetailsToUse.buildingValue.value
              ? householdDetailsToUse.buildingValue.value.toString()
              : "0"
          )
        ) +
        Number(
          extractNumbersFromString(
            householdDetailsToUse.householdItems.value
              ? householdDetailsToUse.householdItems.value.toString()
              : "0"
          )
        )
      ).toString(),
    };
    // debugger;
    HOUSEHOLD_DETAIL_SERVICES.updateHouseholdDetails(onSuccess, onError, data);
  };

  const handleModalClose = () => {
    value_update(attrName, false);
    setHouseholdDetails(state.householdDetails);
  };
  useEffect(() => {
    if (toastError) {
      const timer = setTimeout(() => {
        setToastError(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [toastError]);
  return (
    <Box>
      <Modal open={open_status} onClose={handleModalClose}>
        <Box className="modalDetailPopup_d">
          <Box className="modalDetailPopup_d-inner">
            <Box className="header">
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <h4>Update Household Details</h4>
                  <Link className="close" onClick={handleModalClose}></Link>
                </Grid>
              </Grid>
            </Box>
            <Box className="modalContent px-6">
              <Grid container spacing={3} justifyContent="center">
                <Grid xs={12} className="toggleButtons" textAlign="center">
                  <h5>You Are?</h5>
                  <ToggleButtonGroup
                    value={householdDetails.youAre.toLowerCase()}
                    exclusive
                    onChange={handleYouAre}
                  >
                    <ToggleButton value="tenant">Tenant</ToggleButton>
                    <ToggleButton value="owner">Owner</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid xs={12} className="toggleButtons" textAlign="center">
                  <h5>You Have?</h5>
                  <ToggleButtonGroup
                    value={householdDetails.youHave.toLowerCase()}
                    exclusive
                    onChange={handleYouHave}
                  >
                    <ToggleButton value="flat">Flat</ToggleButton>
                    <ToggleButton value="house">Independent House</ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid xs={12} className="toggleButtons" textAlign="center">
                  <h5>Sum Insured Values</h5>
                  <ToggleButtonGroup
                    value={householdDetails.sumInsuredFor}
                    exclusive
                    onChange={handleSumInsured}
                  >
                    {householdDetails.youAre.toLowerCase() === "owner" && (
                      <ToggleButton value="building">Building</ToggleButton>
                    )}
                    <ToggleButton value="householdItems">
                      Household Items
                    </ToggleButton>
                    {householdDetails.youAre.toLowerCase() === "owner" && (
                      <ToggleButton value="buildingAndHouseItems">
                        Building + Household Items
                      </ToggleButton>
                    )}
                  </ToggleButtonGroup>
                </Grid>

                {/* Building Fields */}
                {householdDetails.sumInsuredFor === SI_TYPE.BUILDING ? (
                  <Grid container spacing={3} justifyContent="center">
                    <Grid xs={12} textAlign="center">
                      <h5>Enter Value of Building & Carpet Area</h5>
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField inrField"
                        title="Value of Building (in Rs.)"
                        validation_type="CURRENCY"
                        value={householdDetails.buildingValue.value}
                        attrName={[
                          "buildingValue",
                          "value",
                          householdDetails,
                          setHouseholdDetails,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={householdDetails.buildingValue.warning}
                        InputProps={{
                          startAdornment: <>₹</>,
                        }}
                        max_length={15}
                        error_message="Please Enter Amount b/w ₹ 1,00,000 - ₹ 100 Cr"
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title="Carpet Area (Sq. ft)"
                        validation_type="CURRENCY"
                        value={householdDetails.carpetArea.value}
                        attrName={[
                          "carpetArea",
                          "value",
                          householdDetails,
                          setHouseholdDetails,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={householdDetails.carpetArea.warning}
                        max_length={10}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField inrField"
                        title="Cost of Construction Area (per Sq. ft)"
                        validation_type="CURRENCY"
                        value={constructionCost.toLocaleString()}
                        attrName={[
                          "constructionCost",
                          "value",
                          householdDetails,
                          setHouseholdDetails,
                        ]}
                        value_update={() => {}}
                        warn_status={false}
                        InputProps={{
                          startAdornment: <>₹</>,
                        }}
                      />
                    </Grid>
                  </Grid>
                ) : null}

                {/* Household Item Fields */}
                {householdDetails.sumInsuredFor === SI_TYPE.HOUSEHOLD_ITEMS ? (
                  <Grid container spacing={3} justifyContent="center">
                    <Grid xs={12} textAlign="center">
                      <h5>Enter Value of Household Items</h5>
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField inrField"
                        title="Value of Household Items (in Rs.)"
                        validation_type="CURRENCY"
                        value={householdDetails.householdItems.value}
                        attrName={[
                          "householdItems",
                          "value",
                          householdDetails,
                          setHouseholdDetails,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={householdDetails.householdItems.warning}
                        InputProps={{
                          startAdornment: <>₹</>,
                        }}
                        max_length={15}
                        error_message="Please Enter Amount b/w ₹ 50,000 - 10 Cr."
                      />
                    </Grid>
                  </Grid>
                ) : null}

                {/* Building + Household Fields */}
                {householdDetails.sumInsuredFor ===
                SI_TYPE.BUILDING_HOUSEHOLD_ITEMS ? (
                  <Grid container spacing={3} justifyContent="center">
                    <Grid xs={12} textAlign="center">
                      <h5>
                        Enter Value of Building, Household Items & Carpet Area
                      </h5>
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField inrField"
                        title="Value of Building (in Rs.)"
                        validation_type="CURRENCY"
                        value={householdDetails.buildingValue.value}
                        attrName={[
                          "buildingValue",
                          "value",
                          householdDetails,
                          setHouseholdDetails,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={householdDetails.buildingValue.warning}
                        InputProps={{
                          startAdornment: <>₹</>,
                        }}
                        max_length={15}
                        error_message="Please Enter Amount b/w ₹ 1,00,000 - ₹ 100 Cr."
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField"
                        title="Carpet Area (Sq. ft)"
                        validation_type="CURRENCY"
                        value={householdDetails.carpetArea.value}
                        attrName={[
                          "carpetArea",
                          "value",
                          householdDetails,
                          setHouseholdDetails,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={householdDetails.carpetArea.warning}
                        max_length={9}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField inrField"
                        title="Cost of Construction Area (per Sq. ft)"
                        validation_type="CURRENCY"
                        value={constructionCost.toLocaleString()}
                        attrName={[
                          "constructionCost",
                          "value",
                          householdDetails,
                          setHouseholdDetails,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={false}
                        InputProps={{
                          startAdornment: <>₹</>,
                        }}
                        max_length={15}
                      />
                    </Grid>
                    <Grid xs={6}>
                      <RKTextField
                        class_name="inputField inrField"
                        title="Value of Household Items (in Rs.)"
                        validation_type="CURRENCY"
                        value={householdDetails.householdItems.value}
                        attrName={[
                          "householdItems",
                          "value",
                          householdDetails,
                          setHouseholdDetails,
                        ]}
                        value_update={fieldsUpdateState}
                        warn_status={householdDetails.householdItems.warning}
                        InputProps={{
                          startAdornment: <>₹</>,
                        }}
                        max_length={15}
                        error_message="Please Enter Amount b/w ₹ 50,000 - ₹ 10Cr."
                      />
                    </Grid>
                  </Grid>
                ) : null}

                <Grid xs={12} textAlign="center">
                  {/* <Button
                    onClick={onHouseholdDetailsUpdate}
                    className="redBtn"
                    disabled={toastError}
                  >
                    Update Details
                  </Button> */}
                  {toastError ? (
                    <Button
                      onClick={onHouseholdDetailsUpdate}
                      className="redBtn"
                      disabled={toastError}
                      style={{
                        backgroundColor: "white",
                        color: "red",
                        border: "1px solid red",
                      }}
                    >
                      Update Details
                    </Button>
                  ) : (
                    <Button
                      onClick={onHouseholdDetailsUpdate}
                      className="redBtn"
                      disabled={toastError}
                    >
                      Update Details
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default HouseholdModifyDetails;
